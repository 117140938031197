import React from "react"
import { Link } from "gatsby"

const Header = () => {
  return (
    <>
      <a href="#content" className="visuallyhidden skip-to-content">
        Skip to main content
      </a>

      <header>
        <div className="inner">
          <h1>
            <Link to="/">
              Accessibility <span>Test</span>
            </Link>
          </h1>

          <nav>
            <Link to="/how-it-works">How it Works</Link>
          </nav>
        </div>
      </header>
    </>
  )
}

export default Header

import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

const HowItWorks = () => (
  <Layout>
    <SEO title="How it works" />

    <section className="page">
      <div className="inner">
        <h2>How it Works</h2>
      </div>
    </section>
  </Layout>
)

export default HowItWorks
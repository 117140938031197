import { PureComponent } from 'react'

import { scroll } from './helpers'

const handleHashChange = () => {
  if (window.location.hash) {
    scroll.toHref(window.location.hash)
  } else {
    // no hash, meaning window has moved from path#hash to path, scroll to the very top
    scroll.toCoord(0)
  }
}

class HashChangeHandler extends PureComponent {
  componentDidMount = () => {
    window.addEventListener('hashchange', handleHashChange)
  }

  componentWillUnmount = () => {
    window.removeEventListener('hashchange', handleHashChange)
  }

  render() {
    return null
  }
}

export default HashChangeHandler
import { useState, useEffect } from "react"
import queryString from "query-string"

function useQueryOrStateUrl(location) {
  const [url, setUrl] = useState(null)
  const [type, setType] = useState(null)
  const [count, setCount] = useState(null)

  useEffect(() => {
    // location.search && queryString.parse(location.search).url
    //   ? queryString.parse(location.search).url
    //   : location.state.url
    // console.log(location)

    if (location.search && location.search !== "") {
      try {
        let params = queryString.parse(location.search)
        setUrl(params.url)
      } catch {}
    } else if (location.state && location.state.url) {
      setUrl(location.state.url)
    }

    if (location.search && location.search !== "") {
      try {
        let params = queryString.parse(location.search)
        setType(params.type)
      } catch {}
    } else if (location.state && location.state.type) {
      setType(location.state.type)
    }

    if (location.search && location.search !== "") {
      try {
        let params = queryString.parse(location.search)
        setCount(params.count)
      } catch {}
    } else if (location.state && location.state.count) {
      setCount(location.state.count)
    }
  }, [location.search, location.state])

  return { url: url, type: type, count: count }
}

export default useQueryOrStateUrl

export default { rules: [
  {
    "id": "definition-list",
    "url": "/rules/axe/4.0/definition-list",
    "details": {
      "ruleId": "definition-list",
      "title": "<dl> elements must only directly contain properly-ordered <dt> and <dd> groups, <script>, or <template> elements",
      "description": "<p>Definition lists (<code>dl</code>) must contain only properly-ordered <code>dt</code> and <code>dd</code> groups, <code>script</code> or <code>template</code> elements.</p>",
      "wcagPrinciples": [
        "1.3.1"
      ],
      "userImpact": "serious",
      "requirements": [
        "WCAG 2.0 (A): MUST"
      ],
      "disabilitiesAffected": [
        "blind",
        "deafblind"
      ],
      "otherResources": [
        [
          {
            "url": "http://www.w3.org/TR/WCAG20-TECHS/H40.html",
            "text": "H40: Using Definition Lists"
          },
          {
            "url": "http://www.w3.org/TR/WCAG20-TECHS/H48.html",
            "text": "H48: Using ol, ul and dl for lists or groups of links"
          }
        ]
      ]
    }
  },
  {
    "id": "html-has-lang",
    "url": "/rules/axe/4.0/html-has-lang",
    "details": {
      "ruleId": "html-has-lang",
      "title": "<html> element must have a lang attribute",
      "description": "<p>The HTML document element must contain a valid <code>lang</code> attribute or must correspond to a valid <code>lang</code> code for multilingual screen reader users who may prefer a language other than the default.</p>",
      "wcagPrinciples": [
        "3.1.1"
      ],
      "userImpact": "serious",
      "requirements": [
        "WCAG 2.0 (A): MUST"
      ],
      "disabilitiesAffected": [
        "blind",
        "deafblind",
        "cognitive"
      ],
      "otherResources": [
        [
          {
            "url": "http://www.w3.org/TR/WCAG20-TECHS/H57.html",
            "text": "H57: Using language attributes on the html element"
          }
        ]
      ]
    }
  },
  {
    "id": "listitem",
    "url": "/rules/axe/4.0/listitem",
    "details": {
      "ruleId": "listitem",
      "title": "<li> elements must be contained in a <ul> or <ol>",
      "description": "<p>All list items (<code>li</code>) must be contained within  <code>ul</code> or <code>ol</code> parent elements.</p>",
      "wcagPrinciples": [
        "1.3.1"
      ],
      "userImpact": "serious",
      "requirements": [
        "WCAG 2.0 (A): MUST"
      ],
      "disabilitiesAffected": [
        "blind",
        "deaf",
        "mobility"
      ],
      "otherResources": [
        [
          {
            "url": "http://www.w3.org/TR/2014/NOTE-WCAG20-TECHS-20140408/H48",
            "text": "H48: Using ol, ul and dl for lists or groups of links"
          }
        ]
      ]
    }
  },
  {
    "id": "blink",
    "url": "/rules/axe/4.0/blink",
    "details": {
      "ruleId": "blink",
      "title": "<blink> elements are deprecated and must not be used",
      "description": "<p>This rule requires that no <code>blink</code> elements are present.  Flashing text can be difficult to read and blinking objects can be difficult to activate.  The associated automated check finds the presence of all <code>blink</code> elements so that they can be removed.</p>",
      "wcagPrinciples": [
        "2.2.2"
      ],
      "userImpact": "serious",
      "requirements": [
        "Section 508: MUST"
      ],
      "disabilitiesAffected": [
        "low vision",
        "mobility",
        "cognitive"
      ],
      "otherResources": [
        [
          {
            "url": "http://www.w3.org/TR/WCAG20-TECHS/F47.html",
            "text": "F47: Failure of Success Criterion 2.2.2 due to using the blink element"
          }
        ]
      ]
    }
  },
  {
    "id": "accesskeys",
    "url": "/rules/axe/4.0/accesskeys",
    "details": {
      "ruleId": "accesskeys",
      "title": "accesskey attribute value must be unique",
      "description": "<p>All <code>accesskey</code> attribute values in a document must be unique. Put another way, <code>accesskey</code>s must not be repeated to prevent unexpected effects for keyboard users.</p>",
      "wcagPrinciples": [
        "Best Practice"
      ],
      "userImpact": "serious",
      "requirements": [
        "Best Practice"
      ],
      "disabilitiesAffected": [
        "blind",
        "low vision",
        "mobility"
      ],
      "otherResources": []
    }
  },
  {
    "id": "html-lang-valid",
    "url": "/rules/axe/4.0/html-lang-valid",
    "details": {
      "ruleId": "html-lang-valid",
      "title": "<html> element must have a valid value for the lang attribute",
      "description": "<p>The HTML document must contain a valid <code>lang</code> attribute or must correspond to a valid <code>lang</code> code for multilingual screen reader users who may prefer a language other than the default.</p>",
      "wcagPrinciples": [
        "3.1.1"
      ],
      "userImpact": "serious",
      "requirements": [
        "WCAG 2.0 (A): MUST"
      ],
      "disabilitiesAffected": [
        "blind",
        "deafblind",
        "cognitive"
      ],
      "otherResources": [
        [
          {
            "url": "http://www.w3.org/TR/WCAG20-TECHS/H57.html",
            "text": "H57: Using language attributes on the html element"
          },
          {
            "url": "https://www.w3.org/2005/05/font-size-test/starhtml-test.html",
            "text": "Language tags in HTML and XML"
          },
          {
            "url": "https://www.w3.org/International/questions/qa-html-language-declarations",
            "text": "Declaring language in HTML"
          }
        ]
      ]
    }
  },
  {
    "id": "html-xml-lang-mismatch",
    "url": "/rules/axe/4.0/html-xml-lang-mismatch",
    "details": {
      "ruleId": "html-xml-lang-mismatch",
      "title": "<html> elements with lang and xml:lang must have the same base language",
      "description": "<p>The HTML document must contain a valid <code>lang</code> attribute or must correspond to a valid <code>lang</code> code for multilingual screen reader users who may prefer a language other than the default. The <code>xml:lang</code> attribute value, if included on the <code>html</code> element, must duplicate the value of the <code>lang</code> exactly.</p>",
      "wcagPrinciples": [
        "3.1.1"
      ],
      "userImpact": "moderate",
      "requirements": [
        "WCAG 2.0 (A): MUST"
      ],
      "disabilitiesAffected": [
        "blind",
        "deafblind",
        "cognitive"
      ],
      "otherResources": [
        [
          {
            "url": "http://www.w3.org/TR/WCAG20-TECHS/H57.html",
            "text": "H57: Using language attributes on the html element"
          }
        ]
      ]
    }
  },
  {
    "id": "area-alt",
    "url": "/rules/axe/4.0/area-alt",
    "details": {
      "ruleId": "area-alt",
      "title": "Active <area> elements must have alternate text",
      "description": "<p>An image map is a single image with many clickable areas. Like all images, an image map must have alternate text for <em>each</em> of the different clickable areas, as well as for the larger image itself, since screen readers have no way of translating graphics into text.</p>",
      "wcagPrinciples": [
        "1.1.1",
        "2.4.4",
        "4.1.2"
      ],
      "userImpact": "critical",
      "requirements": [
        "Section 508: MUST"
      ],
      "disabilitiesAffected": [
        "blind",
        "deafblind",
        "mobility"
      ],
      "otherResources": [
        [
          {
            "url": "http://www.w3.org/TR/WCAG20-TECHS/F65.html",
            "text": "F65: Failure of Success Criterion 1.1.1 due to omitting the alt attribute or text alternative on img elements, area elements, and input elements of type \"image\""
          },
          {
            "url": "http://www.w3.org/TR/WCAG20-TECHS/H24.html",
            "text": "H24: Providing text alternatives for the area elements of image maps"
          }
        ]
      ]
    }
  },
  {
    "id": "dlitem",
    "url": "/rules/axe/4.0/dlitem",
    "details": {
      "ruleId": "dlitem",
      "title": "<dt> and <dd> elements must be contained by a <dl>",
      "description": "<p>Definition list items (<code>dt</code> and/or <code>dd</code>) must be wrapped in parent <code>dl</code> elements to be valid. This enables screen reader users to understand the proper hierarchy of information in the list.</p>",
      "wcagPrinciples": [
        "1.3.1"
      ],
      "userImpact": "serious",
      "requirements": [
        "WCAG 2.0 (A): MUST"
      ],
      "disabilitiesAffected": [
        "blind",
        "deafblind",
        "mobility"
      ],
      "otherResources": [
        [
          {
            "url": "http://www.w3.org/TR/WCAG20-TECHS/H40.html",
            "text": "H40: Using Definition Lists"
          },
          {
            "url": "http://www.w3.org/TR/WCAG20-TECHS/H48.html",
            "text": "H48: Using ol, ul and dl for lists or groups of links"
          }
        ]
      ]
    }
  },
  {
    "id": "list",
    "url": "/rules/axe/4.0/list",
    "details": {
      "ruleId": "list",
      "title": "<ul> and <ol> must only directly contain <li>, <script> or <template> elements",
      "description": "<p>Lists must be marked up correctly, meaning they must not contain <strong>content</strong> elements other than <code>li</code> elements.</p>",
      "wcagPrinciples": [
        "1.3.1"
      ],
      "userImpact": "serious",
      "requirements": [
        "WCAG 2.0 (A): MUST"
      ],
      "disabilitiesAffected": [
        "blind",
        "deafblind"
      ],
      "otherResources": [
        [
          {
            "url": "http://www.w3.org/TR/WCAG20-TECHS/H48.html",
            "text": "H48: Using ol, ul and dl for lists or groups of links"
          }
        ]
      ]
    }
  },
  {
    "id": "aria-valid-attr",
    "url": "/rules/axe/4.0/aria-valid-attr",
    "details": {
      "ruleId": "aria-valid-attr",
      "title": "ARIA attributes must conform to valid names",
      "description": "<p>ARIA attributes starting with <code>aria-</code> must have valid names. Referring to a misspelled attribute or to one that does not exist will result in an invalid attribute and thus failure of this rule.</p>",
      "wcagPrinciples": [
        "4.1.2"
      ],
      "userImpact": "critical",
      "requirements": [
        "WCAG 2.0 (A): MUST"
      ],
      "disabilitiesAffected": [
        "blind",
        "deafblind",
        "mobility"
      ],
      "otherResources": [
        [
          {
            "url": "https://www.w3.org/TR/wai-aria-1.1/#states_and_properties",
            "text": "Accessible Rich Internet Applications (WAI-ARIA) 1.1 - Supported States and Properties"
          }
        ]
      ]
    }
  },
  {
    "id": "td-headers-attr",
    "url": "/rules/axe/4.0/td-headers-attr",
    "details": {
      "ruleId": "td-headers-attr",
      "title": "All cells in a <table> element that use the headers attribute must only refer to other cells of that same <table>",
      "description": "<p>Data table markup can be tedious and confusing. Markup tables semantically and with the correct header structure. Screen readers have features to ease table navigation, but tables must be marked up accurately for these features to work correctly.</p>",
      "wcagPrinciples": [
        "1.3.1"
      ],
      "userImpact": "serious",
      "requirements": [
        "Section 508: MUST"
      ],
      "disabilitiesAffected": [
        "blind",
        "deafblind"
      ],
      "otherResources": [
        [
          {
            "url": "https://www.w3.org/WAI/tutorials/tables/",
            "text": "Tables Concepts"
          },
          {
            "url": "https://www.w3.org/WAI/WCAG21/Techniques/html/H43",
            "text": "H43: Using id and headers attributes to associate data cells with header cells in data tables"
          },
          {
            "url": "https://www.w3.org/WAI/WCAG21/Techniques/html/H51",
            "text": "H51: Using table markup to present tabular information"
          },
          {
            "url": "https://www.w3.org/WAI/WCAG21/Techniques/html/H63.html",
            "text": "H63: Using the scope attribute to associate header cells and data cells in data tables"
          },
          {
            "url": "https://www.w3.org/WAI/WCAG21/Techniques/html/H73.html",
            "text": "H73: Using the summary attribute of the table element to give an overview of data tables"
          }
        ]
      ]
    }
  },
  {
    "id": "object-alt",
    "url": "/rules/axe/4.0/object-alt",
    "details": {
      "ruleId": "object-alt",
      "title": "<object> elements must have alternate text",
      "description": "<p>All embedded objects must have text alternatives to be read out to screen reader users.</p>",
      "wcagPrinciples": [
        "1.1.1"
      ],
      "userImpact": "serious",
      "requirements": [
        "Section 508: MUST"
      ],
      "disabilitiesAffected": [
        "blind",
        "deafblind"
      ],
      "otherResources": [
        [
          {
            "url": "http://www.w3.org/TR/WCAG20-TECHS/H53.html",
            "text": "H53: Using the body of the object element"
          }
        ]
      ]
    }
  },
  {
    "id": "th-has-data-cells",
    "url": "/rules/axe/4.0/th-has-data-cells",
    "details": {
      "ruleId": "th-has-data-cells",
      "title": "All <th> elements and elements with role=\"columnheader\" or role=\"rowheader\" must have data cells they describe",
      "description": "<p>Data table markup can be tedious and confusing. Markup tables semantically and with the correct header structure. Screen readers have features to ease table navigation, but tables must be marked up accurately for these features to work correctly.</p>",
      "wcagPrinciples": [
        "1.3.1"
      ],
      "userImpact": "serious",
      "requirements": [
        "Section 508: MUST"
      ],
      "disabilitiesAffected": [
        "blind",
        "deafblind"
      ],
      "otherResources": [
        [
          {
            "url": "https://www.w3.org/WAI/tutorials/tables/",
            "text": "Tables Concepts"
          },
          {
            "url": "http://www.w3.org/TR/WCAG20-TECHS/H43.html",
            "text": "H43: Using id and headers attributes to associate data cells with header cells in data tables"
          },
          {
            "url": "http://www.w3.org/TR/WCAG20-TECHS/H51.html",
            "text": "H51: Using table markup to present tabular information"
          },
          {
            "url": "http://www.w3.org/TR/WCAG20-TECHS/H63.html",
            "text": "H63: Using the scope attribute to associate header cells and data cells in data tables"
          },
          {
            "url": "http://www.w3.org/TR/WCAG20-TECHS/H73.html",
            "text": "H73: Using the summary attribute of the table element to give an overview of data tables"
          }
        ]
      ]
    }
  },
  {
    "id": "marquee",
    "url": "/rules/axe/4.0/marquee",
    "details": {
      "ruleId": "marquee",
      "title": "<marquee> elements are deprecated and must not be used",
      "description": "<p><code>&lt;marquee&gt;</code> elements must not be present because they are deprecated, increase difficulty for users with limited dexterity, and are distracting for users with cognitive or attention deficits.</p>",
      "wcagPrinciples": [
        "2.2.2"
      ],
      "userImpact": "serious",
      "requirements": [
        "WCAG 2.0 (A): MUST"
      ],
      "disabilitiesAffected": [
        "low vision",
        "mobility",
        "cognitive"
      ],
      "otherResources": [
        [
          {
            "url": "http://www.w3.org/TR/WCAG20-TECHS/F16.html",
            "text": "F16: Failure of Success Criterion 2.2.2 due to including scrolling content where movement is not essential to the activity without also including a mechanism to pause and restart the content"
          }
        ]
      ]
    }
  },
  {
    "id": "td-has-header",
    "url": "/rules/axe/4.0/td-has-header",
    "details": {
      "ruleId": "td-has-header",
      "title": "All non-empty <td> elements in tables larger than 3 by 3 must have an associated table header",
      "description": "<p>Data table markup can be tedious and confusing. Tables must be marked up done semantically and with the correct header structure. Screen readers have features to ease table navigation, but tables must be marked up accurately for these features to work correctly.</p>",
      "wcagPrinciples": [
        "1.3.1"
      ],
      "userImpact": "critical",
      "requirements": [
        "Section 508: MUST"
      ],
      "disabilitiesAffected": [
        "blind",
        "deafblind"
      ],
      "otherResources": [
        [
          {
            "url": "https://www.w3.org/WAI/tutorials/tables/",
            "text": "Tables Concepts"
          },
          {
            "url": "http://www.w3.org/TR/WCAG20-TECHS/H43.html",
            "text": "H43: Using id and headers attributes to associate data cells with header cells in data tables"
          },
          {
            "url": "http://www.w3.org/TR/WCAG20-TECHS/H51.html",
            "text": "H51: Using table markup to present tabular information"
          },
          {
            "url": "http://www.w3.org/TR/WCAG20-TECHS/H63.html",
            "text": "H63: Using the scope attribute to associate header cells and data cells in data tables"
          },
          {
            "url": "http://www.w3.org/TR/WCAG20-TECHS/H73.html",
            "text": "H73: Using the summary attribute of the table element to give an overview of data tables"
          }
        ]
      ]
    }
  },
  {
    "id": "aria-hidden-focus",
    "url": "/rules/axe/4.0/aria-hidden-focus",
    "details": {
      "ruleId": "aria-hidden-focus",
      "title": "aria-hidden elements do not contain focusable elements",
      "description": "<p>This rule checks <code>aria-hidden</code> elements do not contain focusable elements.</p>",
      "wcagPrinciples": [
        "4.1.2"
      ],
      "userImpact": "serious",
      "requirements": [
        "WCAG 2.0 (AA): MUST"
      ],
      "disabilitiesAffected": [
        "blind",
        "low vision",
        "deafblind",
        "mobility"
      ],
      "otherResources": [
        [
          {
            "url": "https://www.w3.org/TR/wai-aria-1.1/#aria-hidden",
            "text": "W3C WAI-ARIA 1.1 States and Properties - aria-hidden"
          }
        ]
      ]
    }
  },
  {
    "id": "aria-allowed-role",
    "url": "/rules/axe/4.0/aria-allowed-role",
    "details": {
      "ruleId": "aria-allowed-role",
      "title": "ARIA role must be appropriate for the element",
      "description": "<p>Values assigned to WAI-ARIA role attributes must be valid. This means values must be spelled correctly, correspond to existing ARIA <code>role</code> values, and <strong>must not be abstract roles in order to correctly expose the purpose of the element.</strong></p>",
      "wcagPrinciples": [
        "Best Practice"
      ],
      "userImpact": "minor",
      "requirements": [
        "Best Practice"
      ],
      "disabilitiesAffected": [
        "blind",
        "deafblind",
        "mobility"
      ],
      "otherResources": [
        [
          {
            "url": "https://www.w3.org/TR/wai-aria-1.1/#roles",
            "text": "WAI-ARIA Roles"
          }
        ]
      ]
    }
  },
  {
    "id": "aria-valid-attr-value",
    "url": "/rules/axe/4.0/aria-valid-attr-value",
    "details": {
      "ruleId": "aria-valid-attr-value",
      "title": "ARIA attributes must conform to valid values",
      "description": "<p>ARIA attributes starting with <code>aria-</code> must contain valid values. These values must be spelled correctly and correspond to values that make sense for a particular attribute in order to perform the intended accessibility function.</p>",
      "wcagPrinciples": [
        "4.1.2"
      ],
      "userImpact": "critical",
      "requirements": [
        "WCAG 2.0 (A): MUST"
      ],
      "disabilitiesAffected": [
        "blind",
        "deafblind",
        "mobility"
      ],
      "otherResources": [
        [
          {
            "url": "https://www.w3.org/TR/wai-aria-1.1/#states_and_properties",
            "text": "Accessible Rich Internet Applications (WAI-ARIA) 1.1 - Supported States and Properties"
          }
        ]
      ]
    }
  },
  {
    "id": "aria-roles",
    "url": "/rules/axe/4.0/aria-roles",
    "details": {
      "ruleId": "aria-roles",
      "title": "ARIA roles used must conform to valid values",
      "description": "<p>Values assigned to ARIA role values must be valid. Role values must be spelled correctly, correspond to existing ARIA <code>role</code> values, and must not be abstract roles to correctly expose the purpose of the element.</p>",
      "wcagPrinciples": [
        "4.1.2"
      ],
      "userImpact": "serious",
      "requirements": [
        "WCAG 2.0 (A): MUST"
      ],
      "disabilitiesAffected": [
        "blind",
        "deafblind",
        "mobility"
      ],
      "otherResources": [
        [
          {
            "url": "https://www.w3.org/TR/wai-aria-1.1/#roles",
            "text": "WAI-ARIA Roles"
          },
          {
            "url": "https://a11ysupport.io/tests/tech__html__role-attribute",
            "text": "Accessibility Support: HTML role attribute test suite"
          }
        ]
      ]
    }
  },
  {
    "id": "region",
    "url": "/rules/axe/4.0/region",
    "details": {
      "ruleId": "region",
      "title": "All page content must be contained by landmarks",
      "description": "<p>It is best practice to contain all content excepting skip links, within distinct regions such as the header, nav, main, and footer.</p>",
      "wcagPrinciples": [
        "Best Practice"
      ],
      "userImpact": "moderate",
      "requirements": [
        "Best Practice"
      ],
      "disabilitiesAffected": [
        "blind",
        "deafblind",
        "mobility"
      ],
      "otherResources": [
        [
          {
            "url": "http://www.w3.org/TR/WCAG20-TECHS/ARIA11.html",
            "text": "ARIA11: Using ARIA landmarks to identify regions of a page"
          }
        ]
      ]
    }
  },
  {
    "id": "aria-input-field-name",
    "url": "/rules/axe/4.0/aria-input-field-name",
    "details": {
      "ruleId": "aria-input-field-name",
      "title": "ARIA input fields must have an accessible name",
      "description": "<p>Ensures every ARIA input field has an accessible name.</p>",
      "wcagPrinciples": [
        "4.1.2"
      ],
      "userImpact": "serious",
      "requirements": [
        "Not specified, or not applicable"
      ],
      "disabilitiesAffected": [
        "blind",
        "deafblind",
        "mobility"
      ],
      "otherResources": []
    }
  },
  {
    "id": "aria-toggle-field-name",
    "url": "/rules/axe/4.0/aria-toggle-field-name",
    "details": {
      "ruleId": "aria-toggle-field-name",
      "title": "ARIA toggle fields have an accessible name",
      "description": "<p>Ensures every ARIA toggle field has an accessible name.</p>",
      "wcagPrinciples": [
        "4.1.2"
      ],
      "userImpact": "serious",
      "requirements": [
        "Not specified, or not applicable"
      ],
      "disabilitiesAffected": [
        "blind",
        "deafblind",
        "mobility"
      ],
      "otherResources": []
    }
  },
  {
    "id": "aria-hidden-body",
    "url": "/rules/axe/4.0/aria-hidden-body",
    "details": {
      "ruleId": "aria-hidden-body",
      "title": "aria-hidden=\"true\" must not be present on the document <body>",
      "description": "<p>Document content is not accessible to assistive technology if <code>&lt;body aria-hidden=&quot;true&quot;&gt;</code>.</p>",
      "wcagPrinciples": [
        "4.1.2"
      ],
      "userImpact": "critical",
      "requirements": [
        "WCAG 2.0 (A): MUST"
      ],
      "disabilitiesAffected": [
        "blind"
      ],
      "otherResources": [
        [
          {
            "url": "https://www.w3.org/TR/wai-aria-1.1/#aria-hidden",
            "text": "W3C WAI-ARIA 1.1 States and Properties - aria-hidden"
          }
        ]
      ]
    }
  },
  {
    "id": "aria-required-parent",
    "url": "/rules/axe/4.0/aria-required-parent",
    "details": {
      "ruleId": "aria-required-parent",
      "title": "Certain ARIA roles must be contained by particular parent elements",
      "description": "<p>Certain ARIA roles must be contained by particular parent <code>role</code>s in order to perform the intended accessibility functions.</p>",
      "wcagPrinciples": [
        "1.3.1"
      ],
      "userImpact": "critical",
      "requirements": [
        "WCAG 2.0 (A): MUST"
      ],
      "disabilitiesAffected": [
        "blind",
        "deafblind",
        "mobility"
      ],
      "otherResources": [
        [
          {
            "url": "https://www.w3.org/TR/wai-aria-1.1/#roles",
            "text": "WAI-ARIA Roles"
          }
        ]
      ]
    }
  },
  {
    "id": "button-name",
    "url": "/rules/axe/4.0/button-name",
    "details": {
      "ruleId": "button-name",
      "title": "Buttons must have discernible text",
      "description": "<p>Buttons must have discernible text that clearly describes the destination, purpose, function, or action for screen reader users.</p>",
      "wcagPrinciples": [
        "4.1.2"
      ],
      "userImpact": "critical",
      "requirements": [
        "Section 508: MUST"
      ],
      "disabilitiesAffected": [
        "blind",
        "deafblind"
      ],
      "otherResources": []
    }
  },
  {
    "id": "landmark-banner-is-top-level",
    "url": "/rules/axe/4.0/landmark-banner-is-top-level",
    "details": {
      "ruleId": "landmark-banner-is-top-level",
      "title": "Banner landmark must not be contained in another landmark",
      "description": "<p>Banner landmark must not be contained in another landmark.</p>",
      "wcagPrinciples": [
        "Best Practice"
      ],
      "userImpact": "moderate",
      "requirements": [
        "Best Practice"
      ],
      "disabilitiesAffected": [
        "blind",
        "deafblind"
      ],
      "otherResources": []
    }
  },
  {
    "id": "p-as-heading",
    "url": "/rules/axe/4.0/p-as-heading",
    "details": {
      "ruleId": "p-as-heading",
      "title": "Bold, italic text and font-size are not used to style <p> elements as a heading",
      "description": "<p>Styled <code>p</code> elements must not be used to represent headings because the structure of the document cannot otherwise be determined by screen reader users.</p>",
      "wcagPrinciples": [
        "1.3.1"
      ],
      "userImpact": "serious",
      "requirements": [
        "WCAG 2.0 (A): MUST"
      ],
      "disabilitiesAffected": [
        "blind",
        "deafblind",
        "mobility"
      ],
      "otherResources": [
        [
          {
            "url": "http://accessibility.psu.edu/headingshtml",
            "text": "Heading Tags"
          }
        ]
      ]
    }
  },
  {
    "id": "no-autoplay-audio",
    "url": "/rules/axe/4.0/no-autoplay-audio",
    "details": {
      "ruleId": "no-autoplay-audio",
      "title": "Audio must have controls that provide users with the ability to stop automatically played audio after three seconds",
      "description": "<p>Ensures &lt;video&gt; or &lt;audio&gt; elements do not autoplay audio for more than three seconds without a control mechanism to stop or mute the audio.</p>",
      "wcagPrinciples": [
        "1.4.2"
      ],
      "userImpact": "moderate",
      "requirements": [
        "Note: Experimental rule"
      ],
      "disabilitiesAffected": [
        "blind",
        "deafblind",
        "cognitive"
      ],
      "otherResources": []
    }
  },
  {
    "id": "autocomplete-valid",
    "url": "/rules/axe/4.0/autocomplete-valid",
    "details": {
      "ruleId": "autocomplete-valid",
      "title": "Autocomplete attribute must be used correctly",
      "description": "<p>The purpose for each common input field that collects an individual&apos;s personal data is programmatically defined based on the list of 53 Input Purposes for User Interface Components. The autocomplete attribute values must be valid and correctly applied for screen readers to function correctly.</p>",
      "wcagPrinciples": [
        "1.3.5"
      ],
      "userImpact": "serious",
      "requirements": [
        "WCAG 2.1 (AA): MUST"
      ],
      "disabilitiesAffected": [
        "attention deficit",
        "blind",
        "low vision",
        "deafblind",
        "mobility",
        "cognitive"
      ],
      "otherResources": [
        [
          {
            "url": "https://www.w3.org/TR/html52/sec-forms.html#autofilling-form-controls-the-autocomplete-attribute",
            "text": "4.10.18.7.1. Autofilling form controls: the autocomplete attribute"
          },
          {
            "url": "https://www.w3.org/WAI/WCAG21/Understanding/identify-input-purpose.html",
            "text": "WCAG 2.1 - 1.3.5 Identify Input Purpose Level AA"
          }
        ]
      ]
    }
  },
  {
    "id": "landmark-complementary-is-top-level",
    "url": "/rules/axe/4.0/landmark-complementary-is-top-level",
    "details": {
      "ruleId": "landmark-complementary-is-top-level",
      "title": "Complementary landmarks and/or asides are top level",
      "description": "<p>Ensures the complementary landmark or aside is at top level</p>",
      "wcagPrinciples": [
        "Best Practice"
      ],
      "userImpact": "moderate",
      "requirements": [
        "Best Practice"
      ],
      "disabilitiesAffected": [
        "sighted keyboard users",
        "blind",
        "deafblind",
        "mobility"
      ],
      "otherResources": [
        [
          {
            "url": "https://developer.mozilla.org/en-US/docs/Web/Accessibility/ARIA/Roles/Complementary_role",
            "text": "MDN ARIA Complementary Role"
          },
          {
            "url": "https://www.w3.org/TR/wai-aria-practices/examples/landmarks/complementary.html",
            "text": "ARIA landmarks examples"
          }
        ]
      ]
    }
  },
  {
    "id": "table-fake-caption",
    "url": "/rules/axe/4.0/table-fake-caption",
    "details": {
      "ruleId": "table-fake-caption",
      "title": "Data or header cells should not be used to give caption to a data table",
      "description": "<p>Data table markup can be tedious and confusing. Screen readers have some features to ease table navigation, but tables must be marked up accurately for these features to work correctly. Some tables use cells with a <code>colspan</code> element to visually indicate a caption, instead of using an actual caption element.</p>",
      "wcagPrinciples": [
        "1.3.1"
      ],
      "userImpact": "serious",
      "requirements": [
        "Section 508: MUST"
      ],
      "disabilitiesAffected": [
        "blind",
        "deafblind"
      ],
      "otherResources": [
        [
          {
            "url": "http://www.w3.org/TR/WCAG20-TECHS/H39.html",
            "text": "H39: Using caption elements to associate data table captions with data tables"
          },
          {
            "url": "http://www.w3.org/TR/WCAG20-TECHS/H43.html",
            "text": "H43: Using id and headers attributes to associate data cells with header cells in data tables"
          },
          {
            "url": "http://www.w3.org/TR/WCAG20-TECHS/H51.html",
            "text": "H51: Using table markup to present tabular information"
          },
          {
            "url": "http://www.w3.org/TR/WCAG20-TECHS/H63.html",
            "text": "H63: Using the scope attribute to associate header cells and data cells in data tables"
          },
          {
            "url": "http://www.w3.org/TR/WCAG20-TECHS/H73.html",
            "text": "H73: Using the summary attribute of the table element to give an overview of data tables"
          }
        ]
      ]
    }
  },
  {
    "id": "aria-required-children",
    "url": "/rules/axe/4.0/aria-required-children",
    "details": {
      "ruleId": "aria-required-children",
      "title": "Certain ARIA roles must contain particular children",
      "description": "<p>Some ARIA parent <code>role</code> values applied to elements must contain specific child elements and <code>role</code> values to perform intended accessibility function.</p>",
      "wcagPrinciples": [
        "1.3.1"
      ],
      "userImpact": "critical",
      "requirements": [
        "WCAG 2.0 (A): MUST"
      ],
      "disabilitiesAffected": [
        "blind",
        "deafblind",
        "mobility"
      ],
      "otherResources": [
        [
          {
            "url": "https://www.w3.org/TR/wai-aria-1.1/#roles",
            "text": "Accessible Rich Internet Applications (WAI-ARIA) 1.1 - The Roles Model"
          }
        ]
      ]
    }
  },
  {
    "id": "landmark-contentinfo-is-top-level",
    "url": "/rules/axe/4.0/landmark-contentinfo-is-top-level",
    "details": {
      "ruleId": "landmark-contentinfo-is-top-level",
      "title": "Contentinfo landmark must not be contained in another landmark",
      "description": "<p>Contentinfo landmark must be at top level.</p>",
      "wcagPrinciples": [
        "Best Practice"
      ],
      "userImpact": "moderate",
      "requirements": [
        "Best Practice"
      ],
      "disabilitiesAffected": [
        "sighted keyboard users",
        "blind",
        "deafblind"
      ],
      "otherResources": [
        [
          {
            "url": "https://www.w3.org/TR/2017/NOTE-wai-aria-practices-1.1-20171214/examples/landmarks/contentinfo.html",
            "text": "W3C ARIA Landmark Examples - Contentinfo"
          }
        ]
      ]
    }
  },
  {
    "id": "document-title",
    "url": "/rules/axe/4.0/document-title",
    "details": {
      "ruleId": "document-title",
      "title": "Documents must contain a title element to aid in navigation",
      "description": "<p>The HTML document must have a <code>title</code> element to provide users with an overview of its content, and when present, it must not be empty.</p>",
      "wcagPrinciples": [
        "2.4.2"
      ],
      "userImpact": "serious",
      "requirements": [
        "WCAG 2.0 (A): MUST"
      ],
      "disabilitiesAffected": [
        "blind",
        "deafblind",
        "mobility"
      ],
      "otherResources": [
        [
          {
            "url": "http://www.w3.org/TR/WCAG20-TECHS/H25.html",
            "text": "H25: Providing a title using the title element"
          }
        ]
      ]
    }
  },
  {
    "id": "scrollable-region-focusable",
    "url": "/rules/axe/4.0/scrollable-region-focusable",
    "details": {
      "ruleId": "scrollable-region-focusable",
      "title": "Ensure that scrollable region has keyboard access",
      "description": "<p>null</p>",
      "wcagPrinciples": [
        "2.4.7"
      ],
      "userImpact": "moderate",
      "requirements": [
        "Not specified, or not applicable"
      ],
      "disabilitiesAffected": [
        "blind",
        "deafblind",
        "mobility"
      ],
      "otherResources": [
        [
          {
            "url": "https://www.w3.org/WAI/WCAG21/quickref/?currentsidebar=%23col_overview&technologies=pdf%2Cflash%2Csl&showtechniques=247#focus-visible",
            "text": "2.4.7 Focus Visible Level AA"
          },
          {
            "url": "https://www.w3.org/WAI/WCAG21/Understanding/focus-visible.html",
            "text": "Understanding Success Criterion 2.4.7: Focus Visible"
          },
          {
            "url": "https://www.w3.org/WAI/WCAG21/Techniques/failures/F55",
            "text": "F55: Failure of Success Criteria 2.1.1, 2.4.7, and 3.2.1 due to using script to remove focus when focus is received"
          }
        ]
      ]
    }
  },
  {
    "id": "tabindex",
    "url": "/rules/axe/4.0/tabindex",
    "details": {
      "ruleId": "tabindex",
      "title": "Elements should not have tabindex greater than zero",
      "description": "<p>A <code>tabindex</code> attribute must never have a value greater than 0 to prevent an unexpected tab order that can give the appearance of skipping some elements entirely.</p>",
      "wcagPrinciples": [
        "Best Practice"
      ],
      "userImpact": "serious",
      "requirements": [
        "Best Practice"
      ],
      "disabilitiesAffected": [
        "blind",
        "deafblind",
        "mobility"
      ],
      "otherResources": [
        [
          {
            "url": "http://www.w3.org/TR/WCAG20-TECHS/F44.html",
            "text": "F44: Failure of Success Criterion 2.4.3 due to using tabindex to create a tab order that does not preserve meaning and operability"
          }
        ]
      ]
    }
  },
  {
    "id": "focus-order-semantics",
    "url": "/rules/axe/4.0/focus-order-semantics",
    "details": {
      "ruleId": "focus-order-semantics",
      "title": "Elements in the focus order need a role appropriate for interactive content",
      "description": "<p>User input elements must have appropriate roles, whether native HTML or a custom widget, to convey to screen reader users their meaning when landed on and given focus.  If a custom widget, appropriate ARIA <code>role</code> values must be used instead of abstract roles to correctly expose the purpose of the element.</p>",
      "wcagPrinciples": [
        "Best Practice"
      ],
      "userImpact": "minor",
      "requirements": [
        "Note: Experimental rule"
      ],
      "disabilitiesAffected": [
        "blind",
        "deafblind",
        "mobility"
      ],
      "otherResources": [
        [
          {
            "url": "https://www.w3.org/TR/wai-aria-1.1/#roles",
            "text": "Accessible Rich Internet Applications (WAI-ARIA) 1.1 - The Roles Model"
          }
        ]
      ]
    }
  },
  {
    "id": "frame-title-unique",
    "url": "/rules/axe/4.0/frame-title-unique",
    "details": {
      "ruleId": "frame-title-unique",
      "title": "Frames must have a unique title attribute",
      "description": "<p>All <code>frame</code> or <code>iframe</code> elements in the document must have a unique title to describe their contents to screen reader users.</p>",
      "wcagPrinciples": [
        "Best Practice"
      ],
      "userImpact": "serious",
      "requirements": [
        "Best Practice"
      ],
      "disabilitiesAffected": [
        "blind",
        "deafblind",
        "mobility"
      ],
      "otherResources": [
        [
          {
            "url": "http://www.w3.org/TR/2014/NOTE-WCAG20-TECHS-20140408/H64",
            "text": "H64: Using the title attribute of the frame and iframe elements"
          }
        ]
      ]
    }
  },
  {
    "id": "landmark-no-duplicate-main",
    "url": "/rules/axe/4.0/landmark-no-duplicate-main",
    "details": {
      "ruleId": "landmark-no-duplicate-main",
      "title": "Ensures the document has at most one main landmark",
      "description": "<p>It is a best practice to ensure that there is only one main landmark to navigate to the primary content of the page and that if the page contains <code>iframe</code> elements, each should either contain no landmarks, or just a single landmark.</p>",
      "wcagPrinciples": [
        "Best Practice"
      ],
      "userImpact": "moderate",
      "requirements": [
        "Best Practice"
      ],
      "disabilitiesAffected": [
        "blind",
        "deafblind",
        "mobility"
      ],
      "otherResources": [
        [
          {
            "url": "http://www.w3.org/TR/WCAG20-TECHS/ARIA11.html",
            "text": "ARIA11: Using ARIA landmarks to identify regions of a page"
          }
        ]
      ]
    }
  },
  {
    "id": "hidden-content",
    "url": "/rules/axe/4.0/hidden-content",
    "details": {
      "ruleId": "hidden-content",
      "title": "Hidden content on the page cannot be analyzed",
      "description": "<p>Informs users about hidden content that cannot be analyzed for accessibility violations.</p>",
      "wcagPrinciples": [
        "Best Practice"
      ],
      "userImpact": "minor",
      "requirements": [
        "Note: Experimental rule"
      ],
      "disabilitiesAffected": [
        "sighted keyboard users",
        "blind",
        "colorblindness"
      ],
      "otherResources": [
        [
          {
            "url": "https://www.w3schools.com/css/css_display_visibility.asp",
            "text": "W3Schools - Display Visibility"
          },
          {
            "url": "https://www.w3schools.com/cssref/pr_class_visibility.asp",
            "text": "W3Schools - Visibility Hidden"
          }
        ]
      ]
    }
  },
  {
    "id": "role-img-alt",
    "url": "/rules/axe/4.0/role-img-alt",
    "details": {
      "ruleId": "role-img-alt",
      "title": "Elements containing role=\"img\" have an alternative text",
      "description": "<p>Ensures elements marked <code>role=&quot;img&quot;</code> elements have alternate text.</p>",
      "wcagPrinciples": [
        "1.1.1"
      ],
      "userImpact": "serious",
      "requirements": [
        "Section 508: MUST"
      ],
      "disabilitiesAffected": [
        "blind",
        "deafblind"
      ],
      "otherResources": [
        [
          {
            "url": "http://dev.w3.org/html5/alt-techniques/#tree",
            "text": "A Simple Alt Text Decision Tree (HTML5 Specification)"
          },
          {
            "url": "http://www.w3.org/TR/WCAG20-TECHS/F65.html",
            "text": "F65: Failure of Success Criterion 1.1.1 due to omitting the alt attribute or text alternative on img elements, area elements, and input elements of type \"image\""
          },
          {
            "url": "http://www.w3.org/TR/WCAG20-TECHS/H37.html",
            "text": "H37: Using alt attributes on img elements"
          },
          {
            "url": "http://www.w3.org/TR/WCAG20-TECHS/H67.html",
            "text": "H67: Using null alt text and no title attribute on img elements for images that AT should ignore"
          },
          {
            "url": "http://www.w3.org/TR/WCAG20-TECHS/ARIA10.html",
            "text": "ARIA10: Using aria-labelledby to provide a text alternative for non-text content"
          }
        ]
      ]
    }
  },
  {
    "id": "label-title-only",
    "url": "/rules/axe/4.0/label-title-only",
    "details": {
      "ruleId": "label-title-only",
      "title": "Form <input> elements should have a visible label",
      "description": "<p>Form <code>&lt;input&gt;</code> elements may be given a title using the <code>title</code> or <code>aria-describedby</code> attributes (but not both). These attributes are used to provide additional information such as a hint.</p>",
      "wcagPrinciples": [
        "Best Practice"
      ],
      "userImpact": "serious",
      "requirements": [
        "Best Practice"
      ],
      "disabilitiesAffected": [
        "blind",
        "deafblind",
        "mobility"
      ],
      "otherResources": []
    }
  },
  {
    "id": "form-field-multiple-labels",
    "url": "/rules/axe/4.0/form-field-multiple-labels",
    "details": {
      "ruleId": "form-field-multiple-labels",
      "title": "Form fields do not have duplicate labels",
      "description": "<p>Ensures form field does not have multiple labels.</p>",
      "wcagPrinciples": [
        "3.3.2"
      ],
      "userImpact": "moderate",
      "requirements": [
        "WCAG 2.0 (A): SHOULD"
      ],
      "disabilitiesAffected": [
        "blind",
        "low vision",
        "deafblind",
        "mobility"
      ],
      "otherResources": [
        [
          {
            "url": "https://www.w3.org/WAI/WCAG21/quickref/?currentsidebar=%23col_overview&technologies=pdf%2Cflash%2Csl&showtechniques=247#labels-or-instructions",
            "text": "3.3.2 Labels or Instructions Level A"
          },
          {
            "url": "https://www.w3.org/WAI/WCAG21/Understanding/labels-or-instructions.html",
            "text": "Understanding Success Criterion 3.3.2: Labels or Instructions"
          },
          {
            "url": "http://www.w3.org/TR/2014/NOTE-WCAG20-TECHS-20140408/F68.html",
            "text": "F68: Failure of Success Criterion 1.3.1 and 4.1.2 due to the association of label and user interface controls not being programmatically determined"
          },
          {
            "url": "http://www.w3.org/TR/2014/NOTE-WCAG20-TECHS-20140408/H44",
            "text": "H44: Using label elements to associate text labels with form controls"
          },
          {
            "url": "http://www.w3.org/TR/2014/NOTE-WCAG20-TECHS-20140408/ARIA16.html",
            "text": "ARIA16: Using aria-labelledby to provide a name for user interface controls"
          },
          {
            "url": "http://www.w3.org/TR/2014/NOTE-WCAG20-TECHS-20140408/ARIA14.html",
            "text": "ARIA14: Using aria-label to provide an invisible label where a visible label cannot be used"
          },
          {
            "url": "http://www.w3.org/TR/2014/NOTE-WCAG20-TECHS-20140408/H65",
            "text": "H65: Using the title attribute to identify form controls when the label element cannot be used"
          }
        ]
      ]
    }
  },
  {
    "id": "frame-title",
    "url": "/rules/axe/4.0/frame-title",
    "details": {
      "ruleId": "frame-title",
      "title": "Frames must have title attribute",
      "description": "<p>All <code>frame</code> or <code>iframe</code> elements in the document must have a title that is not empty to describe their contents to screen reader users.</p>",
      "wcagPrinciples": [
        "2.4.1",
        "4.1.2"
      ],
      "userImpact": "serious",
      "requirements": [
        "Section 508: MUST"
      ],
      "disabilitiesAffected": [
        "blind",
        "deafblind",
        "mobility"
      ],
      "otherResources": [
        [
          {
            "url": "http://www.w3.org/TR/2014/NOTE-WCAG20-TECHS-20140408/H64",
            "text": "H64: Using the title attribute of the frame and iframe elements"
          }
        ]
      ]
    }
  },
  {
    "id": "frame-tested",
    "url": "/rules/axe/4.0/frame-tested",
    "details": {
      "ruleId": "frame-tested",
      "title": "Frames must be tested with axe-core",
      "description": "<p>Frames must be tested with axe-core.</p>",
      "wcagPrinciples": [
        "Best Practice"
      ],
      "userImpact": "critical",
      "requirements": [
        "Needs Review"
      ],
      "disabilitiesAffected": [
        "sighted keyboard users",
        "blind",
        "deafblind"
      ],
      "otherResources": [
        [
          {
            "url": "https://axe-core.org/",
            "text": "https://axe-core.org/"
          },
          {
            "url": "https://chrome.google.com/webstore/detail/axe/lhdoppojpmngadmnindnejefpokejbdd?hl=en-US",
            "text": "aXe - Chrome Web Store"
          }
        ]
      ]
    }
  },
  {
    "id": "aria-allowed-attr",
    "url": "/rules/axe/4.0/aria-allowed-attr",
    "details": {
      "ruleId": "aria-allowed-attr",
      "title": "Elements must only use allowed ARIA attributes",
      "description": "<p>Not all ARIA role-attribute combinations are valid. This Rule checks that each role is supplied with allowed attributes.</p>",
      "wcagPrinciples": [
        "4.1.2"
      ],
      "userImpact": "critical",
      "requirements": [
        "WCAG 2.0 (A): MUST"
      ],
      "disabilitiesAffected": [
        "blind",
        "deafblind",
        "mobility"
      ],
      "otherResources": [
        [
          {
            "url": "https://www.w3.org/TR/wai-aria-1.1/#state_prop_taxonomy",
            "text": "Accessible Rich Internet Applications (WAI-ARIA) 1.1 - Taxonomy of States and Properties"
          },
          {
            "url": "https://www.w3.org/TR/wai-aria-1.1/#roles",
            "text": "Accessible Rich Internet Applications (WAI-ARIA) 1.1 - The Roles Model"
          }
        ]
      ]
    }
  },
  {
    "id": "css-orientation-lock",
    "url": "/rules/axe/4.0/css-orientation-lock",
    "details": {
      "ruleId": "css-orientation-lock",
      "title": "CSS Media queries are not used to lock display orientation",
      "description": "<p>The screen orientation (e.g. portrait or landscape) of mobile applications should not be locked in one mode. Users should be able to change the orientation of their device between portrait and landscape with the page adjusting accordingly in order to remain understandable. Also, when opening a page, it should display in the user&#x2019;s current orientation.</p>",
      "wcagPrinciples": [
        "1.3.4"
      ],
      "userImpact": "serious",
      "requirements": [
        "WCAG 2.0 (AA): SHOULD"
      ],
      "disabilitiesAffected": [
        "attention deficit",
        "dyslexia",
        "sighted keyboard users",
        "low vision",
        "mobility",
        "cognitive"
      ],
      "otherResources": []
    }
  },
  {
    "id": "label",
    "url": "/rules/axe/4.0/label",
    "details": {
      "ruleId": "label",
      "title": "Form <input> elements must have labels",
      "description": "<p>Each form element must have a programmatically associated label element.</p>",
      "wcagPrinciples": [
        "1.3.1",
        "4.1.2"
      ],
      "userImpact": "critical",
      "requirements": [
        "Section 508: MUST"
      ],
      "disabilitiesAffected": [
        "blind",
        "low vision",
        "deafblind",
        "mobility"
      ],
      "otherResources": [
        [
          {
            "url": "https://www.w3.org/WAI/tutorials/forms/labels/",
            "text": "Labeling Controls"
          },
          {
            "url": "http://www.w3.org/TR/2014/NOTE-WCAG20-TECHS-20140408/F68.html",
            "text": "F68: Failure of Success Criterion 1.3.1 and 4.1.2 due to the association of label and user interface controls not being programmatically determined"
          },
          {
            "url": "http://www.w3.org/TR/2014/NOTE-WCAG20-TECHS-20140408/H44",
            "text": "H44: Using label elements to associate text labels with form controls"
          },
          {
            "url": "http://www.w3.org/TR/2014/NOTE-WCAG20-TECHS-20140408/ARIA16.html",
            "text": "ARIA16: Using aria-labelledby to provide a name for user interface controls"
          },
          {
            "url": "http://www.w3.org/TR/2014/NOTE-WCAG20-TECHS-20140408/ARIA14.html",
            "text": "ARIA14: Using aria-label to provide an invisible label where a visible label cannot be used"
          },
          {
            "url": "http://www.w3.org/TR/2014/NOTE-WCAG20-TECHS-20140408/H65",
            "text": "H65: Using the title attribute to identify form controls when the label element cannot be used"
          }
        ]
      ]
    }
  },
  {
    "id": "identical-links-same-purpose",
    "url": "/rules/axe/4.0/identical-links-same-purpose",
    "details": {
      "ruleId": "identical-links-same-purpose",
      "title": "Ensure that links with the same accessible name serve a similar purpose",
      "description": "<p>Ensure that links with the same accessible name serve a similar purpose.</p>",
      "wcagPrinciples": [
        "2.4.9"
      ],
      "userImpact": "minor",
      "requirements": [
        "WCAG 2.0 (AAA): MUST"
      ],
      "disabilitiesAffected": [
        "blind",
        "deafblind"
      ],
      "otherResources": [
        [
          {
            "url": "https://www.w3.org/WAI/WCAG21/Understanding/link-purpose-link-only",
            "text": "Understanding Success Criterion 2.4.9: Link Purpose (Link Only)"
          },
          {
            "url": "https://www.w3.org/WAI/WCAG21/Techniques/failures/F84.html",
            "text": "Failure of Success Criterion 2.4.9 due to using a non-specific link such as \"click here\" or \"more\" without a mechanism to change the link text to specific text"
          }
        ]
      ]
    }
  },
  {
    "id": "aria-required-attr",
    "url": "/rules/axe/4.0/aria-required-attr",
    "details": {
      "ruleId": "aria-required-attr",
      "title": "Required ARIA attributes must be provided",
      "description": "<p>ARIA widget roles must have appropriate attributes describing the state or properties of the widget.</p>",
      "wcagPrinciples": [
        "4.1.2"
      ],
      "userImpact": "critical",
      "requirements": [
        "WCAG 2.0 (A): MUST"
      ],
      "disabilitiesAffected": [
        "blind",
        "deafblind",
        "mobility"
      ],
      "otherResources": [
        [
          {
            "url": "https://www.w3.org/TR/wai-aria-1.1/#state_prop_taxonomy",
            "text": "WAI-ARIA States and Properties Taxonomy"
          }
        ]
      ]
    }
  },
  {
    "id": "landmark-no-duplicate-banner",
    "url": "/rules/axe/4.0/landmark-no-duplicate-banner",
    "details": {
      "ruleId": "landmark-no-duplicate-banner",
      "title": "Page must not have more than one banner landmark",
      "description": "<p>Ensures the page has at most one banner landmark.</p>",
      "wcagPrinciples": [
        "Best Practice"
      ],
      "userImpact": "moderate",
      "requirements": [
        "Best Practice"
      ],
      "disabilitiesAffected": [
        "sighted keyboard users",
        "blind",
        "deafblind"
      ],
      "otherResources": [
        [
          {
            "url": "https://webaim.org/techniques/aria/#landmarks",
            "text": "WebAIM - ARIA Landmark Roles"
          },
          {
            "url": "https://www.w3.org/TR/2017/NOTE-wai-aria-practices-1.1-20171214/examples/landmarks/banner.html",
            "text": "WC3 Banner Landmark: ARIA Landmarks Example"
          },
          {
            "url": "https://www.w3.org/TR/wai-aria-1.1/#banner",
            "text": "W3 ARIA - Banner Role"
          }
        ]
      ]
    }
  },
  {
    "id": "link-name",
    "url": "/rules/axe/4.0/link-name",
    "details": {
      "ruleId": "link-name",
      "title": "Links must have discernible text",
      "description": "<p>Link text and alternate text for images, when used as links, must be discernible by a screen reader, must not have a duplicate label, and must be focusable.</p>",
      "wcagPrinciples": [
        "2.4.4",
        "4.1.2"
      ],
      "userImpact": "serious",
      "requirements": [
        "Section 508: MUST"
      ],
      "disabilitiesAffected": [
        "blind",
        "deafblind",
        "mobility"
      ],
      "otherResources": [
        [
          {
            "url": "http://www.w3.org/WAI/GL/wiki/Using_aria-label",
            "text": "Using aria-label to provide labels for objects"
          },
          {
            "url": "http://www.w3.org/WAI/GL/wiki/Using_aria-label_to_provide_an_invisible_label",
            "text": "Using aria-label to provide an invisible label"
          },
          {
            "url": "http://www.w3.org/TR/WCAG20-TECHS/ARIA7.html",
            "text": "ARIA7: Using aria-labelledby for link purpose"
          },
          {
            "url": "http://www.w3.org/TR/2015/NOTE-WCAG20-TECHS-20150226/F89",
            "text": "F89: Failure of Success Criteria 2.4.4, 2.4.9 and 4.1.2 due to not providing an accessible name for an image which is the only content in a link"
          },
          {
            "url": "https://www.w3.org/TR/2012/NOTE-WCAG20-TECHS-20120103/G91",
            "text": "G91: Providing link text that describes the purpose of a link"
          },
          {
            "url": "http://www.w3.org/TR/WCAG20-TECHS/H30.html",
            "text": "H30: Providing link text that describes the purpose of a link for anchor elements"
          }
        ]
      ]
    }
  },
  {
    "id": "input-image-alt",
    "url": "/rules/axe/4.0/input-image-alt",
    "details": {
      "ruleId": "input-image-alt",
      "title": "Image buttons must have alternate text",
      "description": "<p>Ensures <code>&lt;input type=&quot;image&quot;&gt;</code> elements have alternate text.</p>",
      "wcagPrinciples": [
        "1.1.1"
      ],
      "userImpact": "critical",
      "requirements": [
        "Section 508: MUST"
      ],
      "disabilitiesAffected": [
        "blind",
        "deafblind",
        "mobility"
      ],
      "otherResources": [
        [
          {
            "url": "http://www.w3.org/TR/WCAG20-TECHS/F65.html",
            "text": "F65: Failure of Success Criterion 1.1.1 due to omitting the alt attribute or text alternative on img elements, area elements, and input elements of type \"image\""
          },
          {
            "url": "http://www.w3.org/TR/WCAG20-TECHS/H36.html",
            "text": "H36: Using alt attributes on images used as submit buttons"
          }
        ]
      ]
    }
  },
  {
    "id": "landmark-one-main",
    "url": "/rules/axe/4.0/landmark-one-main",
    "details": {
      "ruleId": "landmark-one-main",
      "title": "Page must have one main landmark",
      "description": "<p>It is a best practice to ensure that there is only one main landmark to navigate to the primary content of the page and that if the page contains <code>iframe</code> elements, each should either contain no landmarks, or just a single landmark.</p>",
      "wcagPrinciples": [
        "Best Practice"
      ],
      "userImpact": "moderate",
      "requirements": [
        "Best Practice"
      ],
      "disabilitiesAffected": [
        "blind",
        "deafblind",
        "mobility"
      ],
      "otherResources": [
        [
          {
            "url": "http://www.w3.org/TR/WCAG20-TECHS/ARIA11.html",
            "text": "ARIA11: Using ARIA landmarks to identify regions of a page"
          }
        ]
      ]
    }
  },
  {
    "id": "link-in-text-block",
    "url": "/rules/axe/4.0/link-in-text-block",
    "details": {
      "ruleId": "link-in-text-block",
      "title": "Links must be distinguished from surrounding text in a way that does not rely on color",
      "description": "<p>Ensures users who cannot distinguish between colors can tell when text is a link by verifying the link has either a distinct style that does not rely on color or has a contrast difference of greater than 3:1 (which alerts you that manual testing is required).</p>",
      "wcagPrinciples": [
        "1.4.1"
      ],
      "userImpact": "serious",
      "requirements": [
        "WCAG 2.0 (A): SHOULD"
      ],
      "disabilitiesAffected": [
        "low vision",
        "colorblindness"
      ],
      "otherResources": [
        [
          {
            "url": "http://www.w3.org/TR/2014/NOTE-WCAG20-TECHS-20140916/G18",
            "text": "G18: Ensuring that a contrast ratio of at least 4.5:1 exists between text (and images of text) and background behind the text"
          },
          {
            "url": "http://www.w3.org/TR/UNDERSTANDING-WCAG20/visual-audio-contrast-contrast.html",
            "text": "W3C Understanding WCAG 2.0 Contrast (Minimum) Understanding SC 1.4.3"
          },
          {
            "url": "http://juicystudio.com/services/luminositycontrastratio.php",
            "text": "Luminosity Colour Contrast Ratio Analyser"
          }
        ]
      ]
    }
  },
  {
    "id": "bypass",
    "url": "/rules/axe/4.0/bypass",
    "details": {
      "ruleId": "bypass",
      "title": "Page must have means to bypass repeated blocks",
      "description": "<p>Each page must have a <code>main</code> landmark to provide a mechanism to bypass repeated blocks of content or interface elements (like header and navigation) and quickly arrive at the main content.</p>",
      "wcagPrinciples": [
        "2.4.1"
      ],
      "userImpact": "serious",
      "requirements": [
        "Section 508: MUST"
      ],
      "disabilitiesAffected": [
        "blind",
        "deafblind"
      ],
      "otherResources": [
        [
          {
            "url": "http://www.w3.org/TR/WCAG20-TECHS/G1.html",
            "text": "G1: Adding a link at the top of each page that goes directly to the main content area"
          },
          {
            "url": "http://www.w3.org/TR/WCAG20-TECHS/ARIA11.html",
            "text": "WCAG ARIA11: Using ARIA landmarks to identify regions of a page"
          },
          {
            "url": "http://www.w3.org/TR/WCAG20-TECHS/H69.html",
            "text": "H69: Providing heading elements at the beginning of each section of content"
          }
        ]
      ]
    }
  },
  {
    "id": "landmark-unique",
    "url": "/rules/axe/4.0/landmark-unique",
    "details": {
      "ruleId": "landmark-unique",
      "title": "Landmarks must have a unique role or role/label/title (i.e. accessible name) combination",
      "description": "<p>null</p>",
      "wcagPrinciples": [
        "Best Practice"
      ],
      "userImpact": "moderate",
      "requirements": [
        "Best Practice"
      ],
      "disabilitiesAffected": [
        "sighted keyboard users",
        "blind",
        "deafblind"
      ],
      "otherResources": [
        [
          {
            "url": "https://www.w3.org/TR/2017/NOTE-wai-aria-practices-1.1-20171214/examples/landmarks/contentinfo.html",
            "text": "Contentinfo Landmark: ARIA Landmark Examples (W3)"
          },
          {
            "url": "https://www.w3.org/TR/wai-aria-1.1/#contentinfo",
            "text": "W3C Recommendation - Contentinfo role"
          }
        ]
      ]
    }
  },
  {
    "id": "empty-heading",
    "url": "/rules/axe/4.0/empty-heading",
    "details": {
      "ruleId": "empty-heading",
      "title": "Headings must not be empty",
      "description": "<p>When at least one heading element (marked by <code>&lt;h1&gt;</code> through <code>&lt;h6&gt;</code>) is present, it is a best practice to ensure it contains content.</p>",
      "wcagPrinciples": [
        "Best Practice"
      ],
      "userImpact": "minor",
      "requirements": [
        "Best Practice"
      ],
      "disabilitiesAffected": [
        "blind",
        "deafblind",
        "mobility"
      ],
      "otherResources": [
        [
          {
            "url": "http://www.w3.org/TR/WCAG20-TECHS/G130.html",
            "text": "G130: Providing descriptive headings"
          },
          {
            "url": "http://www.w3.org/TR/WCAG20-TECHS/ARIA12.html",
            "text": "ARIA12: Using role=heading to identify headings"
          }
        ]
      ]
    }
  },
  {
    "id": "page-has-heading-one",
    "url": "/rules/axe/4.0/page-has-heading-one",
    "details": {
      "ruleId": "page-has-heading-one",
      "title": "Page must contain a level-one heading",
      "description": "<p>Ensures that the page, or at least one of its frames, contains an <code>h1</code> element that appears before the start of the main content to allow screen reader users to use keyboard shortcuts to navigate the heading structure instead of wasting time listening to more of the web page to understand its structure.</p>",
      "wcagPrinciples": [
        "Best Practice"
      ],
      "userImpact": "moderate",
      "requirements": [
        "Best Practice"
      ],
      "disabilitiesAffected": [
        "sighted keyboard users",
        "blind",
        "low vision",
        "deafblind"
      ],
      "otherResources": [
        [
          {
            "url": "https://www.w3.org/WAI/tutorials/page-structure/headings/",
            "text": "Headings - Page Structure Tutorial - Web Accessibility Initiative"
          }
        ]
      ]
    }
  },
  {
    "id": "skip-link",
    "url": "/rules/axe/4.0/skip-link",
    "details": {
      "ruleId": "skip-link",
      "title": "The skip-link target should exist and be focusable",
      "description": "<p>The page must have a link at the top before the navigation that allows users to skip lengthy navigation and proceed to a page&apos;s main content to save time.</p>",
      "wcagPrinciples": [
        "Best Practice"
      ],
      "userImpact": "moderate",
      "requirements": [
        "Best Practice"
      ],
      "disabilitiesAffected": [
        "blind",
        "deafblind",
        "mobility"
      ],
      "otherResources": [
        [
          {
            "url": "http://www.w3.org/TR/WCAG20-TECHS/G1.html",
            "text": "G1: Adding a link at the top of each page that goes directly to the main content area"
          }
        ]
      ]
    }
  },
  {
    "id": "valid-lang",
    "url": "/rules/axe/4.0/valid-lang",
    "details": {
      "ruleId": "valid-lang",
      "title": "lang attribute must have a valid value",
      "description": "<p>The language specified in the HTML document must be one of the valid languages to ensure text is pronounced correctly for screen reader users.</p>",
      "wcagPrinciples": [
        "3.1.2"
      ],
      "userImpact": "serious",
      "requirements": [
        "WCAG 2.0 (AA): MUST"
      ],
      "disabilitiesAffected": [
        "blind",
        "deafblind",
        "cognitive"
      ],
      "otherResources": [
        [
          {
            "url": "http://www.w3.org/TR/WCAG20-TECHS/H58.html",
            "text": "H58: Using language attributes to identify changes in the human language"
          }
        ]
      ]
    }
  },
  {
    "id": "landmark-main-is-top-level",
    "url": "/rules/axe/4.0/landmark-main-is-top-level",
    "details": {
      "ruleId": "landmark-main-is-top-level",
      "title": "Main landmark must not be contained in another landmark",
      "description": "<p>It is a best practice to ensure the main landmark is not contained within another landmark.  All content should be contained within distinct regions such as the header (<code>role=&quot;banner&quot;</code>), content (<code>role=&quot;main&quot;</code>), and footer  (<code>role=&quot;contentinfo&quot;</code>).</p>",
      "wcagPrinciples": [
        "Best Practice"
      ],
      "userImpact": "moderate",
      "requirements": [
        "Best Practice"
      ],
      "disabilitiesAffected": [
        "blind",
        "deafblind",
        "mobility"
      ],
      "otherResources": [
        [
          {
            "url": "http://www.w3.org/TR/WCAG20-TECHS/ARIA11.html",
            "text": "ARIA11: Using ARIA landmarks to identify regions of a page"
          },
          {
            "url": "https://html.spec.whatwg.org/multipage/grouping-content.html#the-main-element%3Athe-main-element",
            "text": "The main element"
          }
        ]
      ]
    }
  },
  {
    "id": "color-contrast",
    "url": "/rules/axe/4.0/color-contrast",
    "details": {
      "ruleId": "color-contrast",
      "title": "Text elements must have sufficient color contrast against the background",
      "description": "<p>All text elements must have sufficient contrast between text in the foreground and background colors behind it in accordance with WCAG 2 AA contrast ratio thresholds.</p>",
      "wcagPrinciples": [
        "1.4.3"
      ],
      "userImpact": "serious",
      "requirements": [
        "WCAG 2.0 (AA): MUST"
      ],
      "disabilitiesAffected": [
        "low vision",
        "colorblindness"
      ],
      "otherResources": [
        [
          {
            "url": "http://www.w3.org/TR/2014/NOTE-WCAG20-TECHS-20140916/G18",
            "text": "G18: Ensuring that a contrast ratio of at least 4.5:1 exists between text (and images of text) and background behind the text"
          },
          {
            "url": "http://www.w3.org/TR/UNDERSTANDING-WCAG20/visual-audio-contrast-contrast.html",
            "text": "W3C Understanding WCAG 2.0 Contrast (Minimum) Understanding SC 1.4.3"
          }
        ]
      ]
    }
  },
  {
    "id": "landmark-no-duplicate-contentinfo",
    "url": "/rules/axe/4.0/landmark-no-duplicate-contentinfo",
    "details": {
      "ruleId": "landmark-no-duplicate-contentinfo",
      "title": "Page must not have more than one contentinfo landmark",
      "description": "<p>Ensures the page has at most one <code>contentinfo</code> landmark.</p>",
      "wcagPrinciples": [
        "Best Practice"
      ],
      "userImpact": "moderate",
      "requirements": [
        "Best Practice"
      ],
      "disabilitiesAffected": [
        "sighted keyboard users",
        "blind",
        "deafblind"
      ],
      "otherResources": [
        [
          {
            "url": "https://www.w3.org/TR/2017/NOTE-wai-aria-practices-1.1-20171214/examples/landmarks/contentinfo.html",
            "text": "Contentinfo Landmark: ARIA Landmark Examples (W3)"
          },
          {
            "url": "https://www.w3.org/TR/wai-aria-1.1/#contentinfo",
            "text": "W3C Recommendation - Contentinfo role"
          }
        ]
      ]
    }
  },
  {
    "id": "scope-attr-valid",
    "url": "/rules/axe/4.0/scope-attr-valid",
    "details": {
      "ruleId": "scope-attr-valid",
      "title": "Scope attribute should be used correctly on tables",
      "description": "<p>The <code>scope</code> attribute must be used correctly on tables in accordance with either HTML4 or HTML5 specifications to enable efficient table navigation for screen reader users.</p>",
      "wcagPrinciples": [
        "Best Practice"
      ],
      "userImpact": "critical",
      "requirements": [
        "Best Practice"
      ],
      "disabilitiesAffected": [
        "blind",
        "deafblind",
        "mobility"
      ],
      "otherResources": [
        [
          {
            "url": "https://www.w3.org/WAI/tutorials/tables/",
            "text": "Tables Concepts"
          }
        ]
      ]
    }
  },
  {
    "id": "duplicate-id",
    "url": "/rules/axe/4.0/duplicate-id",
    "details": {
      "ruleId": "duplicate-id",
      "title": "ID attribute values must be unique",
      "description": "<p>The value assigned to an ID attribute must be unique to prevent the second instance from being overlooked by assistive technology. Put another way; ID attributes may not be used more than once in the same document to differentiate each element from another.</p>",
      "wcagPrinciples": [
        "4.1.1"
      ],
      "userImpact": "minor",
      "requirements": [
        "WCAG 2.0 (A): MUST"
      ],
      "disabilitiesAffected": [
        "blind",
        "deafblind"
      ],
      "otherResources": [
        [
          {
            "url": "http://www.w3.org/TR/WCAG20-TECHS/F77.html",
            "text": "F77: Failure of Success Criterion 4.1.1 due to duplicate values of type ID"
          }
        ]
      ]
    }
  },
  {
    "id": "input-button-name",
    "url": "/rules/axe/4.0/input-button-name",
    "details": {
      "ruleId": "input-button-name",
      "title": "Input buttons must have discernible text",
      "description": "<p>Ensures input buttons have discernible text.</p>",
      "wcagPrinciples": [
        "4.1.2"
      ],
      "userImpact": "critical",
      "requirements": [
        "Section 508: MUST"
      ],
      "disabilitiesAffected": [
        "blind",
        "deafblind"
      ],
      "otherResources": []
    }
  },
  {
    "id": "heading-order",
    "url": "/rules/axe/4.0/heading-order",
    "details": {
      "ruleId": "heading-order",
      "title": "Heading levels should only increase by one",
      "description": "<p>Headings must be in a valid logical order, meaning <code>h1</code> through <code>h6</code> element tags must appear in a sequentially-descending order.</p>",
      "wcagPrinciples": [
        "Best Practice"
      ],
      "userImpact": "moderate",
      "requirements": [
        "Best Practice"
      ],
      "disabilitiesAffected": [
        "blind",
        "deafblind",
        "mobility"
      ],
      "otherResources": [
        [
          {
            "url": "http://accessibility.psu.edu/headingshtml",
            "text": "Heading Tags"
          }
        ]
      ]
    }
  },
  {
    "id": "image-alt",
    "url": "/rules/axe/4.0/image-alt",
    "details": {
      "ruleId": "image-alt",
      "title": "Images must have alternate text",
      "description": "<p>All images must have alternate text to convey their purpose and meaning to screen reader users.</p>",
      "wcagPrinciples": [
        "1.1.1"
      ],
      "userImpact": "critical",
      "requirements": [
        "Section 508: MUST"
      ],
      "disabilitiesAffected": [
        "blind",
        "deafblind"
      ],
      "otherResources": [
        [
          {
            "url": "http://dev.w3.org/html5/alt-techniques/#tree",
            "text": "A Simple Alt Text Decision Tree (HTML5 Specification)"
          },
          {
            "url": "http://www.w3.org/TR/WCAG20-TECHS/F65.html",
            "text": "F65: Failure of Success Criterion 1.1.1 due to omitting the alt attribute or text alternative on img elements, area elements, and input elements of type \"image\""
          },
          {
            "url": "http://www.w3.org/TR/WCAG20-TECHS/H37.html",
            "text": "H37: Using alt attributes on img elements"
          },
          {
            "url": "http://www.w3.org/TR/WCAG20-TECHS/H67.html",
            "text": "H67: Using null alt text and no title attribute on img elements for images that AT should ignore"
          },
          {
            "url": "http://www.w3.org/TR/WCAG20-TECHS/ARIA10.html",
            "text": "ARIA10: Using aria-labelledby to provide a text alternative for non-text content"
          }
        ]
      ]
    }
  },
  {
    "id": "label-content-name-mismatch",
    "url": "/rules/axe/4.0/label-content-name-mismatch",
    "details": {
      "ruleId": "label-content-name-mismatch",
      "title": "Label and name from content mismatch",
      "description": "<p>Interactive elements labeled through their content must have their visible label as part of their accessible name</p>",
      "wcagPrinciples": [
        "2.5.3"
      ],
      "userImpact": "serious",
      "requirements": [
        "Section 508: MUST"
      ],
      "disabilitiesAffected": [
        "blind",
        "low vision",
        "deafblind",
        "mobility"
      ],
      "otherResources": [
        [
          {
            "url": "https://auto-wcag.github.io/auto-wcag/rules/SC2-5-3-label-content-name-mismatch.html",
            "text": "Label and name from content mismatch"
          },
          {
            "url": "https://www.w3.org/TR/WCAG21/#label-in-name",
            "text": "Label in name"
          },
          {
            "url": "http://www.w3.org/TR/2014/NOTE-WCAG20-TECHS-20140408/F68.html",
            "text": "F68: Failure of Success Criterion 1.3.1 and 4.1.2 due to the association of label and user interface controls not being programmatically determined"
          },
          {
            "url": "http://www.w3.org/TR/2014/NOTE-WCAG20-TECHS-20140408/H44",
            "text": "H44: Using label elements to associate text labels with form controls"
          },
          {
            "url": "http://www.w3.org/TR/2014/NOTE-WCAG20-TECHS-20140408/ARIA16.html",
            "text": "ARIA16: Using aria-labelledby to provide a name for user interface controls"
          },
          {
            "url": "http://www.w3.org/TR/2014/NOTE-WCAG20-TECHS-20140408/ARIA14.html",
            "text": "ARIA14: Using aria-label to provide an invisible label where a visible label cannot be used"
          },
          {
            "url": "http://www.w3.org/TR/2014/NOTE-WCAG20-TECHS-20140408/H65",
            "text": "H65: Using the title attribute to identify form controls when the label element cannot be used"
          }
        ]
      ]
    }
  },
  {
    "id": "duplicate-id-aria",
    "url": "/rules/axe/4.0/duplicate-id-aria",
    "details": {
      "ruleId": "duplicate-id-aria",
      "title": "IDs used in ARIA and labels must be unique",
      "description": "<p>The value assigned to an ARIA ID must be unique to prevent the second instance from being overlooked by assistive technology. Put another way; ARIA ID values may not be used more than once in the same document to differentiate each element from another.</p>",
      "wcagPrinciples": [
        "4.1.1"
      ],
      "userImpact": "critical",
      "requirements": [
        "WCAG 2.0 (A): MUST"
      ],
      "disabilitiesAffected": [
        "blind",
        "deafblind"
      ],
      "otherResources": [
        [
          {
            "url": "http://www.w3.org/TR/WCAG20-TECHS/F77.html",
            "text": "F77: Failure of Success Criterion 4.1.1 due to duplicate values of type ID"
          }
        ]
      ]
    }
  },
  {
    "id": "duplicate-id-active",
    "url": "/rules/axe/4.0/duplicate-id-active",
    "details": {
      "ruleId": "duplicate-id-active",
      "title": "ID attribute value must be unique",
      "description": "<p>The value assigned to active ID attributes on focusable elements must be unique to prevent the second instance from being overlooked by assistive technology. Put another way, active ID attributes may not be used more than once on focusable elements in the same document; focusable active elements require unique IDs for assistive technology to distinguish one element from another.</p>",
      "wcagPrinciples": [
        "4.1.1"
      ],
      "userImpact": "critical",
      "requirements": [
        "WCAG 2.0 (A): MUST"
      ],
      "disabilitiesAffected": [
        "blind",
        "deafblind"
      ],
      "otherResources": [
        [
          {
            "url": "http://www.w3.org/TR/WCAG20-TECHS/F77.html",
            "text": "F77: Failure of Success Criterion 4.1.1 due to duplicate values of type ID"
          }
        ]
      ]
    }
  },
  {
    "id": "avoid-inline-spacing",
    "url": "/rules/axe/4.0/avoid-inline-spacing",
    "details": {
      "ruleId": "avoid-inline-spacing",
      "title": "Inline text spacing must be adjustable with custom stylesheets",
      "description": "<p>Ensure that text spacing set through style attributes can be adjusted with custom stylesheets.</p>",
      "wcagPrinciples": [
        "1.4.12"
      ],
      "userImpact": "serious",
      "requirements": [
        "WCAG 2.1 (AA): MUST"
      ],
      "disabilitiesAffected": [
        "attention deficit",
        "blind",
        "low vision",
        "deafblind",
        "mobility",
        "cognitive"
      ],
      "otherResources": [
        [
          {
            "url": "https://www.w3.org/WAI/WCAG21/quickref/?currentsidebar=%23col_overview&technologies=pdf%2Cflash%2Csl&showtechniques=247#text-spacing",
            "text": "1.4.12 Text Spacing Level AA (Added in 2.1)"
          }
        ]
      ]
    }
  },
  {
    "id": "table-duplicate-name",
    "url": "/rules/axe/4.0/table-duplicate-name",
    "details": {
      "ruleId": "table-duplicate-name",
      "title": "The <caption> element should not contain the same text as the summary attribute",
      "description": "<p>Data table markup can be tedious and confusing. Make sure the caption and summary table attributes are not identical.  Screen readers have some features to manage table navigation, but tables must be marked up accurately for these features to work correctly.</p>",
      "wcagPrinciples": [
        "Best Practice"
      ],
      "userImpact": "minor",
      "requirements": [
        "Best Practice"
      ],
      "disabilitiesAffected": [
        "blind",
        "deafblind"
      ],
      "otherResources": [
        [
          {
            "url": "http://www.w3.org/TR/WCAG20-TECHS/H39.html",
            "text": "H39: Using caption elements to associate data table captions with data tables"
          },
          {
            "url": "http://www.w3.org/TR/WCAG20-TECHS/H43.html",
            "text": "H43: Using id and headers attributes to associate data cells with header cells in data tables"
          },
          {
            "url": "http://www.w3.org/TR/WCAG20-TECHS/H51.html",
            "text": "H51: Using table markup to present tabular information"
          },
          {
            "url": "http://www.w3.org/TR/WCAG20-TECHS/H63.html",
            "text": "H63: Using the scope attribute to associate header cells and data cells in data tables"
          },
          {
            "url": "http://www.w3.org/TR/WCAG20-TECHS/H73.html",
            "text": "H73: Using the summary attribute of the table element to give an overview of data tables"
          }
        ]
      ]
    }
  },
  {
    "id": "meta-viewport",
    "url": "/rules/axe/4.0/meta-viewport",
    "details": {
      "ruleId": "meta-viewport",
      "title": "Zooming and scaling must not be disabled",
      "description": "<p>The document must not use the <code>user-scalable=&quot;no&quot;</code> parameter in the <code>&lt;meta name=&quot;viewport&quot;&gt;</code> element because it disables text scaling and zooming which is essential to users with low vision.</p>",
      "wcagPrinciples": [
        "1.4.4"
      ],
      "userImpact": "critical",
      "requirements": [
        "Best Practice"
      ],
      "disabilitiesAffected": [
        "low vision"
      ],
      "otherResources": [
        [
          {
            "url": "http://www.w3.org/TR/UNDERSTANDING-WCAG20/visual-audio-contrast-scale.html",
            "text": "Understanding WCAG SC 1.4.4 Resize Text"
          }
        ]
      ]
    }
  },
  {
    "id": "aria-roledescription",
    "url": "/rules/axe/4.0/aria-roledescription",
    "details": {
      "ruleId": "aria-roledescription",
      "title": "Use aria-roledescription on elements with a semantic role",
      "description": "<p>Ensures that the <code>aria-roledescription</code> attribute is only used on elements with an implicit or explicit <code>role</code> values.</p>",
      "wcagPrinciples": [
        "4.1.2"
      ],
      "userImpact": "serious",
      "requirements": [
        "WCAG 2.0 (A): MUST"
      ],
      "disabilitiesAffected": [
        "blind",
        "deafblind",
        "mobility"
      ],
      "otherResources": [
        [
          {
            "url": "https://www.w3.org/TR/wai-aria-1.1/#state_prop_taxonomy",
            "text": "Accessible Rich Internet Applications (WAI-ARIA) 1.1 - Taxonomy of States and Properties"
          },
          {
            "url": "https://www.w3.org/TR/wai-aria-1.1/#roles",
            "text": "Accessible Rich Internet Applications (WAI-ARIA) 1.1 - The Roles Model"
          }
        ]
      ]
    }
  },
  {
    "id": "svg-img-alt",
    "url": "/rules/axe/4.0/svg-img-alt",
    "details": {
      "ruleId": "svg-img-alt",
      "title": "SVG images and graphics require accessible text",
      "description": "<p>Ensures SVG elements with an <code>&lt;img&gt;</code>, graphics-document or graphics-symbol role have an accessible text alternative.</p>",
      "wcagPrinciples": [
        "1.1.1"
      ],
      "userImpact": "serious",
      "requirements": [
        "Not specified, or not applicable"
      ],
      "disabilitiesAffected": [
        "blind",
        "deafblind",
        "mobility"
      ],
      "otherResources": [
        [
          {
            "url": "https://www.deque.com/blog/creating-accessible-svgs/",
            "text": "https://www.deque.com/blog/creating-accessible-svgs/"
          },
          {
            "url": "https://www.w3.org/WAI/WCAG21/Techniques/general/G94",
            "text": "Providing short text alternative for non-text content that serves the same purpose and presents the same information as the non-text content"
          },
          {
            "url": "https://weboverhauls.github.io/demos/svg/",
            "text": "Accessible SVG test page"
          }
        ]
      ]
    }
  },
  {
    "id": "meta-refresh",
    "url": "/rules/axe/4.0/meta-refresh",
    "details": {
      "ruleId": "meta-refresh",
      "title": "Timed refresh must not exist",
      "description": "<p>The document must not use <code>&lt;meta http-equiv=&quot;refresh&quot;&gt;</code> because it can prevent control over when the refresh occurs for users with disabilities.</p>",
      "wcagPrinciples": [
        "2.2.1",
        "2.2.4",
        "3.2.5"
      ],
      "userImpact": "critical",
      "requirements": [
        "WCAG 2.0 (AA): MUST"
      ],
      "disabilitiesAffected": [
        "blind",
        "deafblind",
        "mobility"
      ],
      "otherResources": [
        [
          {
            "url": "http://www.w3.org/TR/WCAG20-TECHS/H76.html",
            "text": "H76: Using meta refresh to create an instant client-side redirect"
          },
          {
            "url": "http://www.w3.org/TR/WCAG20-TECHS/F41.html",
            "text": "F41: Failure of Success Criterion 2.2.1, 2.2.4, and 3.2.5 due to using meta refresh to reload the page"
          }
        ]
      ]
    }
  },
  {
    "id": "image-redundant-alt",
    "url": "/rules/axe/4.0/image-redundant-alt",
    "details": {
      "ruleId": "image-redundant-alt",
      "title": "Text of buttons and links should not be repeated in the image alternative",
      "description": "<p>When button and link text repeats in a <code>alt</code> attribute value, screen reader users hear the same information twice, which renders the alt text meaningless and confusing.</p>",
      "wcagPrinciples": [
        "Best Practice"
      ],
      "userImpact": "minor",
      "requirements": [
        "Best Practice"
      ],
      "disabilitiesAffected": [
        "blind",
        "deafblind"
      ],
      "otherResources": [
        [
          {
            "url": "http://www.w3.org/TR/WCAG20-TECHS/F65.html",
            "text": "F65: Failure of Success Criterion 1.1.1 due to omitting the alt attribute or text alternative on img elements, area elements, and input elements of type \"image\""
          },
          {
            "url": "http://www.w3.org/TR/WCAG20-TECHS/H36.html",
            "text": "H36: Using alt attributes on images used as submit buttons"
          }
        ]
      ]
    }
  },
  {
    "id": "meta-viewport-large",
    "url": "/rules/axe/4.0/meta-viewport-large",
    "details": {
      "ruleId": "meta-viewport-large",
      "title": "Users should be able to zoom and scale the text up to 500%",
      "description": "<p>The document must not use the <code>user-scalable=&quot;no&quot;</code> parameter in the <code>&lt;meta name=&quot;viewport&quot;&gt;</code> element because it disables text scaling and zooming which is essential to users with low vision.</p>",
      "wcagPrinciples": [
        "Best Practice"
      ],
      "userImpact": "minor",
      "requirements": [
        "Best Practice"
      ],
      "disabilitiesAffected": [
        "low vision"
      ],
      "otherResources": [
        [
          {
            "url": "http://www.w3.org/TR/UNDERSTANDING-WCAG20/visual-audio-contrast-scale.html",
            "text": "Understanding WCAG SC 1.4.4 Resize Text"
          }
        ]
      ]
    }
  },
  {
    "id": "server-side-image-map",
    "url": "/rules/axe/4.0/server-side-image-map",
    "details": {
      "ruleId": "server-side-image-map",
      "title": "Server-side image maps must not be used",
      "description": "<p>The document contains an image map that is server-side instead of client-side.</p>",
      "wcagPrinciples": [
        "2.1.1"
      ],
      "userImpact": "minor",
      "requirements": [
        "Section 508: MUST"
      ],
      "disabilitiesAffected": [
        "blind",
        "deafblind",
        "mobility"
      ],
      "otherResources": [
        [
          {
            "url": "http://www.jimthatcher.com/webcourse5.htm",
            "text": "Web Accessibility: Image Maps"
          }
        ]
      ]
    }
  }
] }
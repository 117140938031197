import React, { useState } from "react"
import pluralize from "pluralize"

const Result = ({ item }) => {
  const [currentContext, setCurrentContext] = useState(0)
  const previousContext = () => {
    if (currentContext - 1 >= 0) {
      setCurrentContext(cont => cont - 1)
    } else {
      setCurrentContext(item.contexts.length - 1)
    }
  }

  const nextContext = () => {
    if (currentContext + 1 <= item.contexts.length - 1) {
      setCurrentContext(cont => cont + 1)
    } else {
      setCurrentContext(0)
    }
  }

  return (
    <div className="result error" data-id={item.id}>
      <div className="row">
        <div className="description">
          <h4>Description</h4>
          <div dangerouslySetInnerHTML={{ __html: item.description }}></div>
        </div>

        <div className="wcag">
          <h4>Relevant WCAG Principles</h4>
          {item.wcag.length > 0 && (
            <ul>
              {item.wcag.map(w => {
                return (
                  <li key={w.slug}>
                    <a
                      href={`https://www.w3.org/TR/WCAG21/#${w.slug}`}
                      target="_blank"
                      rel="noreferrer"
                    >
                      {w.id} {w.title} ({w.level})
                    </a>
                  </li>
                )
              })}
            </ul>
          )}

          {item.wcag.length === 0 && (
            <ul>
              <li>N/A</li>
            </ul>
          )}
        </div>
      </div>

      {item.contexts?.length > 0 && (
        <div className="context">
          <h4>
            Context {pluralize("Sample", item.contexts.length)}{" "}
            {item.contexts.length > 1 && (
              <>
                <span>({item.contexts.length})</span>
                <span className="buttons">
                  <button
                    className="inline"
                    onClick={previousContext}
                    aria-label="Previous context sample"
                  >
                    Previous
                  </button>
                  <button
                    className="inline"
                    onClick={nextContext}
                    aria-label="Next context sample"
                  >
                    Next
                  </button>
                </span>
              </>
            )}
          </h4>
          <pre>{item.contexts[currentContext]?.replace(/[^ -~]+/g, "")}</pre>
        </div>
      )}

      {item.urls && item.urls.length > 0 && (
        <div className="urls">
          <h4>
            {pluralize("Page", item.urls.length)}{" "}
            {item.urls.length > 1 && <span>({item.urls.length})</span>}
          </h4>

          <ul>
            {item.urls
              .sort((a, b) => a.length - b.length)
              .map(u => {
                u = new URL(u)
                u.host = u.host.replace("www.", "")
                return (
                  <li key={u}>
                    <a href={u.href} target="_blank" rel="noreferrer">
                      {u.host}
                      {u.pathname !== "" && u.pathname !== "/"
                        ? `${u.pathname}`
                        : ``}
                    </a>
                  </li>
                )
              })}
          </ul>
        </div>
      )}
    </div>
  )
}

export default Result

import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

const NotFound = () => (
  <Layout>
    <SEO title="404" />

    <section className="page">
      <div className="inner">
        <h2>404</h2>
      </div>
    </section>
  </Layout>
)

export default NotFound
import React from "react"

import Header from "./header"
import Footer from "./footer"

import HashChangeHelper from "../utils/hashChangeHelper"

const Layout = ({ mainClass, children }) => {
  return (
    <>
      <Header />
      <main id="content" className={mainClass}>{children}</main>
      <Footer />
      <HashChangeHelper />
    </>
  )
}

export default Layout
import React from "react"
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from "react-accessible-accordion"

import Result from "./result"

const ScanResults = ({ data, scanner, logo, description, link }) => {
  return (
    <div className="scanner-results">
      <div className="scanner-details">
        <div className="scanner-info">
          {/* <h3>{scanner}</h3> */}
          <img src={logo} height="66" alt={scanner} />
          <p>{description}</p>
          <p>
            <a href={link} target="_blank" rel="noreferrer">
              Learn more about {scanner}
            </a>
          </p>
        </div>

        <ul className="tests">
          {data.tests.error > 0 && (
            <>
              <li className="total">
                <span>Total Tests</span> {data.tests.total.toLocaleString()}
              </li>

              <li className="error">
                <span>Failures</span> {data.tests.error.toLocaleString()}
              </li>
            </>
          )}
        </ul>
      </div>
      {data.allErrors.length > 0 && (
        <Accordion
          allowMultipleExpanded={true}
          allowZeroExpanded={true}
          preExpanded={[`${data.allErrors[0].id}`]}
        >
          {data.allErrors.map(item => {
            return (
              <AccordionItem key={item.id} uuid={`${item.id}`}>
                <AccordionItemHeading>
                  <AccordionItemButton>
                    <span>{item.count}</span>
                    {/* {item.title.indexOf(".") > -1 ? item.title : _.capitalize(item.title)} */}
                    <p>{item.title}</p>
                  </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                  <Result item={item} key={item.id} />
                </AccordionItemPanel>
              </AccordionItem>
            )
          })}
        </Accordion>
      )}
    </div>
  )
}

export default ScanResults

export default { rules: [
  {
    "id": "alt_missing",
    "type": "error",
    "title": "Missing alternative text",
    "description": "Image alternative text is not present.",
    "context": "Each image must have an alt attribute. Without alternative text, the content of an image will not be available to screen reader users or when the image is unavailable.",
    "wcagPrinciples": [
      {
        "principle": "1.1.1",
        "text": "Non-text Content (Level A)"
      }
    ]
  },
  {
    "id": "alt_link_missing",
    "type": "error",
    "title": "Linked image missing alternative text",
    "description": "An image without alternative text results in an empty link.",
    "context": "Images that are the only thing within a link must have descriptive alternative text. If an image is within a link that contains no text and that image does not provide alternative text, a screen reader has no content to present to the user regarding the function of the link.",
    "wcagPrinciples": [
      {
        "principle": "1.1.1",
        "text": "Non-text Content (Level A)"
      },
      {
        "principle": "2.4.4",
        "text": "Link Purpose (In Context) (Level A)"
      }
    ]
  },
  {
    "id": "alt_spacer_missing",
    "type": "error",
    "title": "Spacer image missing alternative text",
    "description": "A layout spacer image (which should have null/empty alternative text) does not have an alt attribute.",
    "context": "Spacer images are used to maintain layout. They do not convey content and should be given null/empty alternative text (alt=\"\") so they are not presented to users and are ignored by screen readers.",
    "wcagPrinciples": [
      {
        "principle": "1.1.1",
        "text": "Non-text Content (Level A)"
      }
    ]
  },
  {
    "id": "alt_input_missing",
    "type": "error",
    "title": "Image button missing alternative text",
    "description": "Alternative text is not present for a form image button.",
    "context": "Image buttons provide important functionality that must be presented in alternative text. Without alternative text, the function of an image button is not made available to screen reader users or when images are disabled or unavailable.",
    "wcagPrinciples": [
      {
        "principle": "1.1.1",
        "text": "Non-text Content (Level A)"
      },
      {
        "principle": "2.4.4",
        "text": "Link Purpose (In Context) (Level A)"
      }
    ]
  },
  {
    "id": "alt_area_missing",
    "type": "error",
    "title": "Image map area missing alternative text",
    "description": "Alternative text is not present for an image map area (hot spot).",
    "context": "Image map areas or clickable hot spots provide important functionality that must be provided in alternative text. Without alternative text, the function of the area is not made available to screen reader users or when images are unavailable.",
    "wcagPrinciples": [
      {
        "principle": "1.1.1",
        "text": "Non-text Content (Level A)"
      },
      {
        "principle": "2.4.4",
        "text": "Link Purpose (In Context) (Level A)"
      }
    ]
  },
  {
    "id": "alt_map_missing",
    "type": "error",
    "title": "Image map missing alternative text",
    "description": "An image that has hot spots does not have an alt attribute.",
    "context": "Any content or function of an image that uses an image map (hot spots) and does not have an alt attribute will not be available to screen reader users or if the image is unavailable.",
    "wcagPrinciples": [
      {
        "principle": "1.1.1",
        "text": "Non-text Content (Level A)"
      }
    ]
  },
  {
    "id": "longdesc_invalid",
    "type": "error",
    "title": "Invalid longdesc",
    "description": "The longdesc attribute is not a URL.",
    "context": "The longdesc attribute of an image must be a valid URL of a page that contains a description of the image content. A longdesc value that contains image description text will not provide any accessibility information. Due to poor support, a link to the long description content should be used instead of longdesc.",
    "wcagPrinciples": [
      {
        "principle": "1.1.1",
        "text": "Non-text Content (Level A)"
      }
    ]
  },
  {
    "id": "label_missing",
    "type": "error",
    "title": "Missing form label",
    "description": "A form control does not have a corresponding label.",
    "context": "If a form control does not have a properly associated text label, the function or purpose of that form control may not be presented to screen reader users. Form labels also provide visible descriptions and larger clickable targets for form controls.",
    "wcagPrinciples": [
      {
        "principle": "1.1.1",
        "text": "Non-text Content (Level A)"
      },
      {
        "principle": "1.3.1",
        "text": "Info and Relationships (Level A)"
      },
      {
        "principle": "2.4.6",
        "text": "Headings and Labels (Level AA)"
      },
      {
        "principle": "3.3.2",
        "text": "Labels or Instructions (Level A)"
      }
    ]
  },
  {
    "id": "label_empty",
    "type": "error",
    "title": "Empty form label",
    "description": "A form label is present, but does not contain any content.",
    "context": "A <label> element that is associated to a form control but does not contain text will not present any information about the form control to the user.",
    "wcagPrinciples": [
      {
        "principle": "1.1.1",
        "text": "Non-text Content (Level A)"
      },
      {
        "principle": "1.3.1",
        "text": "Info and Relationships (Level A)"
      },
      {
        "principle": "2.4.6",
        "text": "Headings and Labels (Level AA)"
      },
      {
        "principle": "3.3.2",
        "text": "Labels or Instructions (Level A)"
      }
    ]
  },
  {
    "id": "label_multiple",
    "type": "error",
    "title": "Multiple form labels",
    "description": "A form control has more than one label associated with it.",
    "context": "A form control should have at most one associated label element. If more than one label element is associated to the control, assistive technology may not read the appropriate label.",
    "wcagPrinciples": [
      {
        "principle": "1.1.1",
        "text": "Non-text Content (Level A)"
      },
      {
        "principle": "1.3.1",
        "text": "Info and Relationships (Level A)"
      },
      {
        "principle": "2.4.6",
        "text": "Headings and Labels (Level AA)"
      },
      {
        "principle": "3.3.2",
        "text": "Labels or Instructions (Level A)"
      }
    ]
  },
  {
    "id": "aria_reference_broken",
    "type": "error",
    "title": "Broken ARIA reference",
    "description": "An aria-labelledby or aria-describedby reference exists, but the target for the reference does not exist.",
    "context": "ARIA labels and descriptions will not be presented if the element referenced does not exist in the page.",
    "wcagPrinciples": [
      {
        "principle": "1.3.1",
        "text": "Info and Relationships (Level A)"
      },
      {
        "principle": "4.1.2",
        "text": "Name, Role, Value (Level A)"
      }
    ]
  },
  {
    "id": "aria_menu_broken",
    "type": "error",
    "title": "Broken ARIA menu",
    "description": "An ARIA menu does not contain required menu items.",
    "context": "ARIA menus are application menus (like those used in software menu) with a specific keyboard interactions. They are NOT for navigation links on a web site and must contain at least one menuitem, menuitemcheckbox, or menuitemradio element.",
    "wcagPrinciples": [
      {
        "principle": "2.1.1",
        "text": "Keyboard (Level A)"
      },
      {
        "principle": "4.1.2",
        "text": "Name, Role, Value (Level A)"
      }
    ]
  },
  {
    "id": "title_invalid",
    "type": "error",
    "title": "Missing or uninformative page title",
    "description": "The page title is missing or not descriptive.",
    "context": "A descriptive title helps users understand a page's purpose or content. Without a proper title, many users (especially those using screen readers or other assistive technology) may have difficulty orienting themselves to the page.",
    "wcagPrinciples": [
      {
        "principle": "2.4.2",
        "text": "Page Titled (Level A)"
      }
    ]
  },
  {
    "id": "language_missing",
    "type": "error",
    "title": "Document language missing",
    "description": "The language of the document is not identified.",
    "context": "Identifying the language of the page allows screen readers to read the content in the appropriate language. It also facilitates automatic translation of content.",
    "wcagPrinciples": [
      {
        "principle": "3.1.1",
        "text": "Language of Page (Level A)"
      }
    ]
  },
  {
    "id": "meta_refresh",
    "type": "error",
    "title": "Page refreshes or redirects",
    "description": "The page is set to automatically change location or refresh using a <meta> tag.",
    "context": "Pages that automatically change location or refresh pose significant usability issues, particularly for screen reader and keyboard users.",
    "wcagPrinciples": [
      {
        "principle": "2.2.1",
        "text": "Timing Adjustable (Level A)"
      },
      {
        "principle": "2.2.2",
        "text": "Pause, Stop, Hide (Level A)"
      }
    ]
  },
  {
    "id": "heading_empty",
    "type": "error",
    "title": "Empty heading",
    "description": "A heading contains no content.",
    "context": "Some users, especially keyboard and screen reader users, often navigate by heading elements. An empty heading will present no information and may introduce confusion.",
    "wcagPrinciples": [
      {
        "principle": "1.3.1",
        "text": "Info and Relationships (Level A)"
      },
      {
        "principle": "2.4.1",
        "text": "Bypass Blocks (Level A)"
      },
      {
        "principle": "2.4.6",
        "text": "Headings and Labels (Level AA)"
      }
    ]
  },
  {
    "id": "button_empty",
    "type": "error",
    "title": "Empty button",
    "description": "A button is empty or has no value text.",
    "context": "When navigating to a button, descriptive text must be presented to screen reader users to indicate the function of the button.",
    "wcagPrinciples": [
      {
        "principle": "1.1.1",
        "text": "Non-text Content (Level A)"
      },
      {
        "principle": "2.4.4",
        "text": "Link Purpose (In Context) (Level A)"
      }
    ]
  },
  {
    "id": "link_empty",
    "type": "error",
    "title": "Empty link",
    "description": "A link contains no text.",
    "context": "If a link contains no text, the function or purpose of the link will not be presented to the user. This can introduce confusion for keyboard and screen reader users.",
    "wcagPrinciples": [
      {
        "principle": "2.4.4",
        "text": "Link Purpose (In Context) (Level A)"
      }
    ]
  },
  {
    "id": "link_skip_broken",
    "type": "error",
    "title": "Broken skip link",
    "description": "A skip navigation link exists, but the target for the link does not exist or the link is not keyboard accessible.",
    "context": "A link to jump over navigation or jump to the main content of the page assists keyboard users only if the link is properly functioning and is keyboard accessible.",
    "wcagPrinciples": [
      {
        "principle": "2.1.1",
        "text": "Keyboard (Level A)"
      },
      {
        "principle": "2.4.1",
        "text": "Bypass Blocks (Level A)"
      }
    ]
  },
  {
    "id": "th_empty",
    "type": "error",
    "title": "Empty table header",
    "description": "A <th> (table header) contains no text.",
    "context": "The <th> element helps associate table cells with the correct row/column headers. A <th> that contains no text may result in cells with missing or incorrect header information.",
    "wcagPrinciples": [
      {
        "principle": "1.3.1",
        "text": "Info and Relationships (Level A)"
      }
    ]
  },
  {
    "id": "blink",
    "type": "error",
    "title": "Blinking content",
    "description": "Blinking content is present.",
    "context": "Blinking content can be distracting and confusing to users, particularly those with certain cognitive disabilities.",
    "wcagPrinciples": [
      {
        "principle": "2.2.2",
        "text": "Pause, Stop, Hide (Level A)"
      }
    ]
  },
  {
    "id": "marquee",
    "type": "error",
    "title": "Marquee",
    "description": "A <marquee> element is present.",
    "context": "A marquee element presents scrolling text that the user cannot stop. Scrolling animated content can be distracting and confusing to users, particularly for those with certain cognitive disabilities.",
    "wcagPrinciples": [
      {
        "principle": "2.2.2",
        "text": "Pause, Stop, Hide (Level A)"
      }
    ]
  },
  {
    "id": "contrast",
    "type": "contrast",
    "title": "Very Low Contrast",
    "description": "Very low contrast between foreground and background colors.",
    "context": "Adequate contrast is necessary for all users, especially users with low vision.",
    "wcagPrinciples": [
      {
        "principle": "1.4.3",
        "text": "Contrast (Minimum) (Level AA)"
      }
    ]
  },
  {
    "id": "alt_suspicious",
    "type": "alert",
    "title": "Suspicious alternative text",
    "description": "Alternative text is likely insufficient or contains extraneous information.",
    "context": "If the alternative text for an image does not provide the same content or information conveyed by the image, that content will not be available to screen reader users and when images are unavailable.",
    "wcagPrinciples": [
      {
        "principle": "1.1.1",
        "text": "Non-text Content (Level A)"
      }
    ]
  },
  {
    "id": "alt_redundant",
    "type": "alert",
    "title": "Redundant alternative text",
    "description": "The alternative text for an image is the same as nearby or adjacent text.",
    "context": "Alternative text that is the same as nearby or adjacent text will be presented multiple times to screen readers or when images are unavailable.",
    "wcagPrinciples": [
      {
        "principle": "1.1.1",
        "text": "Non-text Content (Level A)"
      }
    ]
  },
  {
    "id": "alt_duplicate",
    "type": "alert",
    "title": "A nearby image has the same alternative text",
    "description": "Two images near each other have the same alternative text.",
    "context": "When two images have the same alternative text, this often causes redundancy or indicates incorrect alternative text.",
    "wcagPrinciples": [
      {
        "principle": "1.1.1",
        "text": "Non-text Content (Level A)"
      }
    ]
  },
  {
    "id": "alt_long",
    "type": "alert",
    "title": "Long alternative text",
    "description": "An image has very long alternative text.",
    "context": "Alternative text should be succinct, yet descriptive of the content and function of an image. Lengthy alternative text (more than around 100 characters) often indicates that extraneous content or content that is not available to sighted users is being presented.",
    "wcagPrinciples": [
      {
        "principle": "1.1.1",
        "text": "Non-text Content (Level A)"
      }
    ]
  },
  {
    "id": "longdesc",
    "type": "alert",
    "title": "Long description",
    "description": "The longdesc attribute is present.",
    "context": "Because of poor support, the longdesc attribute should not be used.",
    "wcagPrinciples": [
      {
        "principle": "1.1.1",
        "text": "Non-text Content (Level A)"
      }
    ]
  },
  {
    "id": "label_orphaned",
    "type": "alert",
    "title": "Orphaned form label",
    "description": "A form label is present, but it is not correctly associated with a form control.",
    "context": "An incorrectly associated label does not provide functionality or information about the form control to the user. It usually indicates a coding or other form labeling issues.",
    "wcagPrinciples": [
      {
        "principle": "1.1.1",
        "text": "Non-text Content (Level A)"
      },
      {
        "principle": "1.3.1",
        "text": "Info and Relationships (Level A)"
      },
      {
        "principle": "2.4.6",
        "text": "Headings and Labels (Level AA)"
      },
      {
        "principle": "3.3.2",
        "text": "Labels or Instructions (Level A)"
      }
    ]
  },
  {
    "id": "label_title",
    "type": "alert",
    "title": "Unlabeled form element with title",
    "description": "A form control does not have a label, but has a title.",
    "context": "The title attribute value for unlabeled form controls will be presented to screen reader users. However, a properly associated text label provides better usability and accessibility and should be used unless the purpose of the form control is intuitive without the label.",
    "wcagPrinciples": [
      {
        "principle": "1.1.1",
        "text": "Non-text Content (Level A)"
      },
      {
        "principle": "1.3.1",
        "text": "Info and Relationships (Level A)"
      },
      {
        "principle": "2.4.6",
        "text": "Headings and Labels (Level AA)"
      },
      {
        "principle": "3.3.2",
        "text": "Labels or Instructions (Level A)"
      }
    ]
  },
  {
    "id": "fieldset_missing",
    "type": "alert",
    "title": "Missing fieldset",
    "description": "A group of check boxes or radio buttons is not enclosed in a fieldset.",
    "context": "A fieldset provides a visual and structural grouping of related form elements. It is typically necessary for groups of check boxes or radio buttons where a higher level description (called a legend) is necessary to understand the function of the check boxes or radio buttons. The description will be identified by a screen reader only if provided in a fieldset legend.",
    "wcagPrinciples": [
      {
        "principle": "1.1.1",
        "text": "Non-text Content (Level A)"
      },
      {
        "principle": "1.3.1",
        "text": "Info and Relationships (Level A)"
      },
      {
        "principle": "2.4.6",
        "text": "Headings and Labels (Level AA)"
      },
      {
        "principle": "3.3.2",
        "text": "Labels or Instructions (Level A)"
      }
    ]
  },
  {
    "id": "legend_missing",
    "type": "alert",
    "title": "Fieldset missing legend",
    "description": "A fieldset does not have a legend.",
    "context": "A fieldset legend presents a description of the form elements within a fieldset and is especially useful to screen reader users. A legend should be provided when a higher level description is necessary for groups of check boxes, radio buttons, or other form controls.",
    "wcagPrinciples": [
      {
        "principle": "1.1.1",
        "text": "Non-text Content (Level A)"
      },
      {
        "principle": "1.3.1",
        "text": "Info and Relationships (Level A)"
      },
      {
        "principle": "2.4.6",
        "text": "Headings and Labels (Level AA)"
      },
      {
        "principle": "3.3.2",
        "text": "Labels or Instructions (Level A)"
      }
    ]
  },
  {
    "id": "heading_missing",
    "type": "alert",
    "title": "No heading structure",
    "description": "The page has no headings.",
    "context": "Headings (<h1>-<h6>) provide important document structure, outlines, and navigation functionality to assistive technology users.",
    "wcagPrinciples": [
      {
        "principle": "1.3.1",
        "text": "Info and Relationships (Level A)"
      },
      {
        "principle": "2.4.2",
        "text": "Page Titled (Level A)"
      },
      {
        "principle": "2.4.6",
        "text": "Headings and Labels (Level AA)"
      }
    ]
  },
  {
    "id": "h1_missing",
    "type": "alert",
    "title": "Missing first level heading",
    "description": "A page does not have a first level heading.",
    "context": "Headings facilitate page navigation for users of many assistive technologies. They also provide semantic and visual meaning and structure to the document. A first level heading (<h1>) should be present on nearly all pages. It should contain the most important heading on the page (generally the document title).",
    "wcagPrinciples": [
      {
        "principle": "1.3.1",
        "text": "Info and Relationships (Level A)"
      },
      {
        "principle": "2.4.2",
        "text": "Page Titled (Level A)"
      },
      {
        "principle": "2.4.6",
        "text": "Headings and Labels (Level AA)"
      }
    ]
  },
  {
    "id": "region_missing",
    "type": "alert",
    "title": "No page regions",
    "description": "No page regions or ARIA landmarks were found.",
    "context": "Regions and ARIA landmarks identify significant page areas. Most web pages should have regions defined, particularly for the main content area.",
    "wcagPrinciples": [
      {
        "principle": "1.3.1",
        "text": "Info and Relationships (Level A)"
      },
      {
        "principle": "2.4.1",
        "text": "Bypass Blocks (Level A)"
      }
    ]
  },
  {
    "id": "heading_skipped",
    "type": "alert",
    "title": "Skipped heading level",
    "description": "A heading level is skipped.",
    "context": "Headings provide document structure and facilitate keyboard navigation by users of assistive technology. These users may be confused or experience difficulty navigating when heading levels are skipped.",
    "wcagPrinciples": [
      {
        "principle": "1.3.1",
        "text": "Info and Relationships (Level A)"
      },
      {
        "principle": "2.4.1",
        "text": "Bypass Blocks (Level A)"
      },
      {
        "principle": "2.4.6",
        "text": "Headings and Labels (Level AA)"
      }
    ]
  },
  {
    "id": "heading_possible",
    "type": "alert",
    "title": "Possible heading",
    "description": "Text appears to be a heading but is not a heading element.",
    "context": "Heading elements (<h1>-<h6>) provide important document structure, outlines, and navigation functionality to assistive technology users. If heading text is not a true heading, this information and functionality will not be available for that text.",
    "wcagPrinciples": [
      {
        "principle": "1.3.1",
        "text": "Info and Relationships (Level A)"
      },
      {
        "principle": "2.4.1",
        "text": "Bypass Blocks (Level A)"
      },
      {
        "principle": "2.4.6",
        "text": "Headings and Labels (Level AA)"
      }
    ]
  },
  {
    "id": "table_layout",
    "type": "alert",
    "title": "Layout table",
    "description": "A layout table is present.",
    "context": "Layout tables exist merely to position content visually - to create columns, insert spacing, or align content neatly for sighted users. Their content is not at all tabular in nature. Layout tables should not be used in HTML5. They can introduce reading and navigation order issues. Screen readers may interpret them as data tables (i.e., announcing column and row numbers), especially if they contain table header (<th>) cells. This introduces significant overhead on screen reader users.",
    "wcagPrinciples": [
      {
        "principle": "1.3.1",
        "text": "Info and Relationships (Level A)"
      },
      {
        "principle": "1.3.2",
        "text": "Meaningful Sequence (Level A)"
      }
    ]
  },
  {
    "id": "table_caption_possible",
    "type": "alert",
    "title": "Possible table caption",
    "description": "Text appears to be a table caption, but is not a caption element.",
    "context": "A table caption should be associated with a table using the <caption> element so it will be read by a screen reader with the table content.",
    "wcagPrinciples": [
      {
        "principle": "1.3.1",
        "text": "Info and Relationships (Level A)"
      }
    ]
  },
  {
    "id": "link_internal_broken",
    "type": "alert",
    "title": "Broken same-page link",
    "description": "A link to another location within the page is present but does not have a corresponding target.",
    "context": "A link to jump to another position within the the page assists users in navigating the web page, but only if the link target exists.",
    "wcagPrinciples": [
      {
        "principle": "2.1.1",
        "text": "Keyboard (Level A)"
      }
    ]
  },
  {
    "id": "link_suspicious",
    "type": "alert",
    "title": "Suspicious link text",
    "description": "Link text contains extraneous text or may not make sense out of context.",
    "context": "Links, which are often read out of context, should clearly describe the destination or function of the link. Ambiguous text, text that does not make sense out of context, and extraneous text (such as \"click here\") can cause confusion and should be avoided.",
    "wcagPrinciples": [
      {
        "principle": "2.4.4",
        "text": "Link Purpose (In Context) (Level A)"
      }
    ]
  },
  {
    "id": "link_redundant",
    "type": "alert",
    "title": "Redundant link",
    "description": "Adjacent links go to the same URL.",
    "context": "When adjacent links go to the same location (such as a linked product image and an adjacent linked product name that go to the same product page) this results in additional navigation and repetition for keyboard and screen reader users.",
    "wcagPrinciples": [
      {
        "principle": "2.4.4",
        "text": "Link Purpose (In Context) (Level A)"
      }
    ]
  },
  {
    "id": "link_word",
    "type": "alert",
    "title": "Link to Word document",
    "description": "A link to a Microsoft Word document is present.",
    "context": "Unless authored with accessibility in mind, Microsoft Word documents often have accessibility issues. Additionally, Word documents are typically viewed using a separate application, and can thus cause confusion and navigation difficulties."
  },
  {
    "id": "link_excel",
    "type": "alert",
    "title": "Link to Excel spreadsheet",
    "description": "A link to a Microsoft Excel spreadsheet is present.",
    "context": "Unless authored with accessibility in mind, Microsoft Excel spreadsheets often have accessibility issues. Additionally, Excel documents are typically viewed using a separate application, and can thus cause confusion and navigation difficulties."
  },
  {
    "id": "link_powerpoint",
    "type": "alert",
    "title": "Link to PowerPoint document",
    "description": "A link to a Microsoft PowerPoint presentation is present.",
    "context": "Unless authored with accessibility in mind, PowerPoint documents often have accessibility issues. Additionally, PowerPoint documents are typically viewed using a separate application, and can thus cause confusion and navigation difficulties."
  },
  {
    "id": "link_pdf",
    "type": "alert",
    "title": "Link to PDF document",
    "description": "A link to a PDF document is present.",
    "context": "Unless authored with accessibility in mind, PDF documents often have accessibility issues. Additionally, PDF documents are typically viewed using a separate application or plug-in, and can thus cause confusion and navigation difficulties."
  },
  {
    "id": "link_document",
    "type": "alert",
    "title": "Link to document",
    "description": "A link to a non-HTML document is present.",
    "context": "Unless authored with accessibility in mind, documents that are not HTML often have accessibility issues. Additionally, these documents are typically viewed using a separate application, and can thus cause confusion and navigation difficulties."
  },
  {
    "id": "audio_video",
    "type": "alert",
    "title": "Audio/Video",
    "description": "An audio or video file or link is present.",
    "context": "Audio content must be presented in a text format to be fully accessible to users who are deaf and hard of hearing. Video content with audio must have synchronized captions and a transcript. Audio-only content must have a transcript.",
    "wcagPrinciples": [
      {
        "principle": "1.2.1",
        "text": "Prerecorded Audio-only and Video-only (Level A)"
      },
      {
        "principle": "1.2.2",
        "text": "Captions (Prerecorded) (Level A)"
      },
      {
        "principle": "1.2.3",
        "text": "Audio Description or Media Alternative (Prerecorded) (Level A)"
      },
      {
        "principle": "1.2.5",
        "text": "Audio Description (Prerecorded) (Level AA)"
      },
      {
        "principle": "1.4.2",
        "text": "Audio Control (Level A)"
      }
    ]
  },
  {
    "id": "html5_video_audio",
    "type": "alert",
    "title": "HTML5 video or audio",
    "description": "A <video> or <audio> element is present.",
    "context": "<video> defines video, such as a movie clip or other video streams. <audio> defines sound, such as music or other audio streams. Audio content must be presented in a text format to be fully accessible to users who are deaf and hard of hearing. Video content with audio must have synchronized captions and a transcript. Audio-only content must have a transcript.",
    "wcagPrinciples": [
      {
        "principle": "1.2.1",
        "text": "Prerecorded Audio-only and Video-only (Level A)"
      },
      {
        "principle": "1.2.2",
        "text": "Captions (Prerecorded) (Level A)"
      },
      {
        "principle": "1.2.3",
        "text": "Audio Description or Media Alternative (Prerecorded) (Level A)"
      },
      {
        "principle": "1.2.5",
        "text": "Audio Description (Prerecorded) (Level AA)"
      },
      {
        "principle": "1.4.2",
        "text": "Audio Control (Level A)"
      }
    ]
  },
  {
    "id": "flash",
    "type": "alert",
    "title": "Flash",
    "description": "Flash content is present.",
    "context": "Flash content, if not authored to be accessible, will typically introduce significant accessibility issues.",
    "wcagPrinciples": [
      {
        "principle": "1.2.1",
        "text": "Prerecorded Audio-only and Video-only (Level A)"
      },
      {
        "principle": "1.2.2",
        "text": "Captions (Prerecorded) (Level A)"
      },
      {
        "principle": "1.2.3",
        "text": "Audio Description or Media Alternative (Prerecorded) (Level A)"
      },
      {
        "principle": "1.2.5",
        "text": "Audio Description (Prerecorded) (Level AA)"
      },
      {
        "principle": "2.1.2",
        "text": "No Keyboard Trap (Level A)"
      }
    ]
  },
  {
    "id": "applet",
    "type": "alert",
    "title": "Java Applet",
    "description": "A Java applet is present.",
    "context": "Java applets will typically introduce significant accessibility issues."
  },
  {
    "id": "plugin",
    "type": "alert",
    "title": "Plugin",
    "description": "An unidentified plugin is present.",
    "context": "Plugins allow the introduction of non-HTML content, media players, etc. Because of limitations in non-HTML content, these often introduce accessibility issues."
  },
  {
    "id": "noscript",
    "type": "alert",
    "title": "Noscript element",
    "description": "A <noscript> element is present.",
    "context": "Content within <noscript> is presented if JavaScript is disabled. Because nearly all users (including users of screen readers and other assistive technologies) have JavaScript enabled, <noscript> cannot be used to provide an accessible version of inaccessible scripted content."
  },
  {
    "id": "event_handler",
    "type": "alert",
    "title": "Device dependent event handler",
    "description": "An event handler is present that may not be accessible.",
    "context": "The JavaScript events in use do not appear to be accessible to both mouse and keyboard users. To be fully accessible, critical JavaScript interaction should be device independent.",
    "wcagPrinciples": [
      {
        "principle": "an",
        "text": "onmouseover event but not an onfocus event"
      },
      {
        "principle": "an",
        "text": "onclick event on something other than a link, form control, or element with a tabindex value of 0"
      },
      {
        "principle": "ondblclick",
        "text": ""
      }
    ]
  },
  {
    "id": "javascript_jumpmenu",
    "type": "alert",
    "title": "JavaScript jump menu",
    "description": "A JavaScript jump menu may be present.",
    "context": "A JavaScript jump menu is a select element that triggers a new web page with the onchange event handler. When navigating with the keyboard, each change in the select menu triggers a page change in some web browsers, thus making navigation very difficult.",
    "wcagPrinciples": [
      {
        "principle": "2.1.1",
        "text": "Keyboard (Level A)"
      },
      {
        "principle": "3.2.2",
        "text": "On Input (Level A)"
      }
    ]
  },
  {
    "id": "accesskey",
    "type": "alert",
    "title": "Accesskey",
    "description": "An accesskey attribute is present.",
    "context": "Accesskey provides a way to define shortcut keys for web page elements. Accesskeys often conflict with user or assistive technology shortcut keys and should be avoided or implemented with care.",
    "wcagPrinciples": [
      {
        "principle": "2.4.1",
        "text": "Bypass Blocks (Level A)"
      }
    ]
  },
  {
    "id": "tabindex",
    "type": "alert",
    "title": "Tabindex",
    "description": "A positive tabindex value is present.",
    "context": "Tabindex values of 1 or greater specify an explicit tab/navigation order for page elements. Because it modifies the default tab order, cause confusion, and result in decreased keyboard accessibility, it should be avoided.",
    "wcagPrinciples": [
      {
        "principle": "2.4.3",
        "text": "Focus Order (Level A)"
      }
    ]
  },
  {
    "id": "text_small",
    "type": "alert",
    "title": "Very small text",
    "description": "Text is very small.",
    "context": "Text which is very small is difficult to read, particularly for those with low vision."
  },
  {
    "id": "text_justified",
    "type": "alert",
    "title": "Justified text",
    "description": "Fully justified text is present.",
    "context": "Large blocks of justified text can negatively impact readability due to varying word/letter spacing and 'rivers of white' that flow through the text."
  },
  {
    "id": "underline",
    "type": "alert",
    "title": "Underlined text",
    "description": "Underlined text is present.",
    "context": "Underlines almost universally indicates linked text. Consider removing the underline from the non-link text. Other styling (e.g., bold or italics) can be used to differentiate the text."
  },
  {
    "id": "title_redundant",
    "type": "alert",
    "title": "Redundant title text",
    "description": "Title attribute text is the same as text or alternative text.",
    "context": "The title attribute value is used to provide advisory information. It typically appears when the users hovers the mouse over an element. The advisory information presented should not be identical to or very similar to the element text or alternative text."
  },
  {
    "id": "alt",
    "type": "feature",
    "title": "Alternative text",
    "description": "Image alternative text is present.",
    "context": "Alternative text presents the content or function of an image to screen reader users or in other situations where images cannot be seen or are unavailable.",
    "wcagPrinciples": [
      {
        "principle": "1.1.1",
        "text": "Non-text Content (Level A)"
      }
    ]
  },
  {
    "id": "alt_null",
    "type": "feature",
    "title": "Null or empty alternative text",
    "description": "Alternative text is null or empty (alt=\"\").",
    "context": "If an image does not convey content or if the content of the image is conveyed elsewhere (such as in a caption or nearby text), the image should have empty/null alternative text (alt=\"\") to ensure that it is ignored by a screen reader and is hidden when images are disabled or unavailable.",
    "wcagPrinciples": [
      {
        "principle": "1.1.1",
        "text": "Non-text Content (Level A)"
      }
    ]
  },
  {
    "id": "alt_spacer",
    "type": "feature",
    "title": "Null or empty alternative text on spacer",
    "description": "Alternative text is null or empty (alt=\"\") on a spacer image.",
    "context": "Spacer images are used to control layout or positioning. Because they do not convey content, they should be given empty/null alternative text (alt=\"\") to ensure that the content is not presented to screen reader users and is hidden when images are disabled or unavailable.",
    "wcagPrinciples": [
      {
        "principle": "1.1.1",
        "text": "Non-text Content (Level A)"
      }
    ]
  },
  {
    "id": "alt_link",
    "type": "feature",
    "title": "Linked image with alternative text",
    "description": "Alternative text is present for an image that is within a link.",
    "context": "Including appropriate alternative text on an image within a link ensures that the function and purpose of the link and the content of the image is available to screen reader users or when images are unavailable.",
    "wcagPrinciples": [
      {
        "principle": "1.1.1",
        "text": "Non-text Content (Level A)"
      },
      {
        "principle": "2.4.4",
        "text": "Link Purpose (In Context) (Level A)"
      }
    ]
  },
  {
    "id": "alt_input",
    "type": "feature",
    "title": "Image button with alternative text",
    "description": "Alternative text is present for an image input element.",
    "context": "Providing the functionality of image buttons in alternative text ensures that the button function is available to all users.",
    "wcagPrinciples": [
      {
        "principle": "1.1.1",
        "text": "Non-text Content (Level A)"
      },
      {
        "principle": "2.4.4",
        "text": "Link Purpose (In Context) (Level A)"
      }
    ]
  },
  {
    "id": "alt_map",
    "type": "feature",
    "title": "Image map with alternative text",
    "description": "An alt attribute is present for an image that has hot spots.",
    "context": "If an image that uses an image map provides content or a function that is not already available through the hot spots (and their respective alternative texts), that information must be in the image's alt attribute in order for it to be available to screen reader users or when images are disabled.",
    "wcagPrinciples": [
      {
        "principle": "1.1.1",
        "text": "Non-text Content (Level A)"
      }
    ]
  },
  {
    "id": "alt_area",
    "type": "feature",
    "title": "Image map area with alternative text",
    "description": "Alternative text is present for an image map area (hot spot).",
    "context": "Presenting the functionality of image map areas (hot spots) in the <area> element's alt attribute value ensures that this information is presented to screen reader users or when images are disabled or unavailable.",
    "wcagPrinciples": [
      {
        "principle": "1.1.1",
        "text": "Non-text Content (Level A)"
      },
      {
        "principle": "2.4.4",
        "text": "Link Purpose (In Context) (Level A)"
      }
    ]
  },
  {
    "id": "label",
    "type": "feature",
    "title": "Form label",
    "description": "A form label is present and associated with a form control.",
    "context": "A properly associated form label is presented to a screen reader user when the form control is accessed. Additionally, a label can be clicked with the mouse to set focus to the form control.",
    "wcagPrinciples": [
      {
        "principle": "1.1.1",
        "text": "Non-text Content (Level A)"
      },
      {
        "principle": "1.3.1",
        "text": "Info and Relationships (Level A)"
      },
      {
        "principle": "2.4.6",
        "text": "Headings and Labels (Level AA)"
      }
    ]
  },
  {
    "id": "fieldset",
    "type": "feature",
    "title": "Fieldset",
    "description": "A fieldset is present.",
    "context": "A fieldset provides a visual and structural grouping of related form elements. If present, a fieldset legend presents a description of the grouped form elements to screen reader users. A fieldset and legend are typically necessary for groups of check boxes or radio buttons.",
    "wcagPrinciples": [
      {
        "principle": "1.1.1",
        "text": "Non-text Content (Level A)"
      },
      {
        "principle": "1.3.1",
        "text": "Info and Relationships (Level A)"
      },
      {
        "principle": "2.4.6",
        "text": "Headings and Labels (Level AA)"
      }
    ]
  },
  {
    "id": "link_skip",
    "type": "feature",
    "title": "Skip link",
    "description": "A link is present which allows users to skip over navigation or other content.",
    "context": "A link that provides functionality for the user to jump over navigation or other elements or jump to the main content of the page greatly assists keyboard users in navigating the web page.",
    "wcagPrinciples": [
      {
        "principle": "2.4.1",
        "text": "Bypass Blocks (Level A)"
      }
    ]
  },
  {
    "id": "link_skip_target",
    "type": "feature",
    "title": "Skip link target",
    "description": "A target for a \"skip\" link is present.",
    "context": "A \"skip\" target identifies the location within the page where reading and navigation will resume after a \"skip\" link is activated.",
    "wcagPrinciples": [
      {
        "principle": "2.4.1",
        "text": "Bypass Blocks (Level A)"
      }
    ]
  },
  {
    "id": "lang",
    "type": "feature",
    "title": "Element language",
    "description": "The language of a page element or part is identified.",
    "context": "Identifying the language of an element or portion of page that is in a different language than the page itself allows screen readers to read the content appropriately.",
    "wcagPrinciples": [
      {
        "principle": "3.1.2",
        "text": "Language of Parts (Level AA)"
      }
    ]
  },
  {
    "id": "h1",
    "type": "structure",
    "title": "Heading level 1",
    "description": "A first level heading (<h1> element) is present.",
    "context": "Headings facilitate page navigation for users of assistive technologies. They also provide semantic and visual meaning and structure to the document. First level headings should contain the most important heading(s) on the page (generally the document title).",
    "wcagPrinciples": [
      {
        "principle": "1.3.1",
        "text": "Info and Relationships (Level A)"
      },
      {
        "principle": "2.4.1",
        "text": "Bypass Blocks (Level A)"
      },
      {
        "principle": "2.4.6",
        "text": "Headings and Labels (Level AA)"
      }
    ]
  },
  {
    "id": "h2",
    "type": "structure",
    "title": "Heading level 2",
    "description": "A second level heading (<h2> element) is present.",
    "context": "Headings facilitate page navigation for users of assistive technologies. They also provide semantic and visual meaning and structure to the document.",
    "wcagPrinciples": [
      {
        "principle": "1.3.1",
        "text": "Info and Relationships (Level A)"
      },
      {
        "principle": "2.4.1",
        "text": "Bypass Blocks (Level A)"
      },
      {
        "principle": "2.4.6",
        "text": "Headings and Labels (Level AA)"
      }
    ]
  },
  {
    "id": "h3",
    "type": "structure",
    "title": "Heading level 3",
    "description": "A third level heading (<h3> element) is present.",
    "context": "Headings facilitate page navigation for users of assistive technologies. They also provide semantic and visual meaning and structure to the document.",
    "wcagPrinciples": [
      {
        "principle": "1.3.1",
        "text": "Info and Relationships (Level A)"
      },
      {
        "principle": "2.4.1",
        "text": "Bypass Blocks (Level A)"
      },
      {
        "principle": "2.4.6",
        "text": "Headings and Labels (Level AA)"
      }
    ]
  },
  {
    "id": "h4",
    "type": "structure",
    "title": "Heading level 4",
    "description": "A fourth level heading (<h4> element) is present.",
    "context": "Headings facilitate page navigation for users of assistive technologies. They also provide semantic and visual meaning and structure to the document.",
    "wcagPrinciples": [
      {
        "principle": "1.3.1",
        "text": "Info and Relationships (Level A)"
      },
      {
        "principle": "2.4.1",
        "text": "Bypass Blocks (Level A)"
      },
      {
        "principle": "2.4.6",
        "text": "Headings and Labels (Level AA)"
      }
    ]
  },
  {
    "id": "h5",
    "type": "structure",
    "title": "Heading level 5",
    "description": "A fifth level heading (<h5> element) is present.",
    "context": "Headings facilitate page navigation for users of assistive technologies. They also provide semantic and visual meaning and structure to the document.",
    "wcagPrinciples": [
      {
        "principle": "1.3.1",
        "text": "Info and Relationships (Level A)"
      },
      {
        "principle": "2.4.1",
        "text": "Bypass Blocks (Level A)"
      },
      {
        "principle": "2.4.6",
        "text": "Headings and Labels (Level AA)"
      }
    ]
  },
  {
    "id": "h6",
    "type": "structure",
    "title": "Heading level 6",
    "description": "A sixth level heading (<h6> element) is present.",
    "context": "Headings facilitate page navigation for users of assistive technologies. They also provide semantic and visual meaning and structure to the document.",
    "wcagPrinciples": [
      {
        "principle": "1.3.1",
        "text": "Info and Relationships (Level A)"
      },
      {
        "principle": "2.4.1",
        "text": "Bypass Blocks (Level A)"
      },
      {
        "principle": "2.4.6",
        "text": "Headings and Labels (Level AA)"
      }
    ]
  },
  {
    "id": "ol",
    "type": "structure",
    "title": "Ordered list",
    "description": "An ordered (numbered) list (<ol> element) is present.",
    "context": "Ordered lists present a group of related sequential items. Users of assistive technologies can navigate by and within lists.",
    "wcagPrinciples": [
      {
        "principle": "1.3.1",
        "text": "Info and Relationships (Level A)"
      }
    ]
  },
  {
    "id": "ul",
    "type": "structure",
    "title": "Unordered list",
    "description": "An unordered (bulleted) list (<ul> element) is present.",
    "context": "Ordered lists present a group of related, parallel items. Users of many assistive technologies can navigate by and within lists.",
    "wcagPrinciples": [
      {
        "principle": "1.3.1",
        "text": "Info and Relationships (Level A)"
      }
    ]
  },
  {
    "id": "dl",
    "type": "structure",
    "title": "Definition/description list",
    "description": "A definition/description list (<dl> element) is present.",
    "context": "Definition lists (called description lists in HTML5) present the descriptions for terms or name/value pairs. Users of many assistive technologies can navigate by and within lists.",
    "wcagPrinciples": [
      {
        "principle": "1.3.1",
        "text": "Info and Relationships (Level A)"
      }
    ]
  },
  {
    "id": "header",
    "type": "structure",
    "title": "Header",
    "description": "A <header> element or banner landmark is present.",
    "context": "Headers identify page introduction or navigation. They typically surrounds the site or page name, logo, top navigation, or other header content. Headers facilitate page semantics and navigation.",
    "wcagPrinciples": [
      {
        "principle": "1.3.1",
        "text": "Info and Relationships (Level A)"
      },
      {
        "principle": "2.4.1",
        "text": "Bypass Blocks (Level A)"
      }
    ]
  },
  {
    "id": "nav",
    "type": "structure",
    "title": "Navigation",
    "description": "A <nav> element or navigation landmark is present.",
    "context": "The navigation identifies a section of navigation links and can facilitate page semantics and navigation.",
    "wcagPrinciples": [
      {
        "principle": "1.3.1",
        "text": "Info and Relationships (Level A)"
      },
      {
        "principle": "2.4.1",
        "text": "Bypass Blocks (Level A)"
      }
    ]
  },
  {
    "id": "search",
    "type": "structure",
    "title": "Search",
    "description": "An ARIA search landmark is present.",
    "context": "The search landmark identifies the search area within the page and facilitates keyboard navigation to the search area.",
    "wcagPrinciples": [
      {
        "principle": "1.3.1",
        "text": "Info and Relationships (Level A)"
      },
      {
        "principle": "2.4.1",
        "text": "Bypass Blocks (Level A)"
      }
    ]
  },
  {
    "id": "main",
    "type": "structure",
    "title": "Main content",
    "description": "A <main> element or main landmark is present.",
    "context": "The <main> element or role=\"main\" attribute identifies the main content for the page. This facilitate page semantics and navigation.",
    "wcagPrinciples": [
      {
        "principle": "1.3.1",
        "text": "Info and Relationships (Level A)"
      },
      {
        "principle": "2.4.1",
        "text": "Bypass Blocks (Level A)"
      }
    ]
  },
  {
    "id": "aside",
    "type": "structure",
    "title": "Aside",
    "description": "An <aside> element or complementary landmark is present.",
    "context": "An aside identifies secondary, related, or complementary content. It is typically presented in a sidebar.",
    "wcagPrinciples": [
      {
        "principle": "1.3.1",
        "text": "Info and Relationships (Level A)"
      },
      {
        "principle": "2.4.1",
        "text": "Bypass Blocks (Level A)"
      }
    ]
  },
  {
    "id": "footer",
    "type": "structure",
    "title": "Footer",
    "description": "A <footer> element or contentinfo landmark is present.",
    "context": "Footers identify a footer for the page or a page section. It typically identifies authorship, related links, copyright date, or other footer content. Footers facilitate page semantics and navigation.",
    "wcagPrinciples": [
      {
        "principle": "1.3.1",
        "text": "Info and Relationships (Level A)"
      },
      {
        "principle": "2.4.1",
        "text": "Bypass Blocks (Level A)"
      }
    ]
  },
  {
    "id": "table_data",
    "type": "structure",
    "title": "Data table",
    "description": "A data table is present.",
    "context": "Data tables present tabular data. Data tables should contain table header cells that identify the content of their respective row and/or columns. Tables with proper table headers provide additional information and navigation for screen reader users.",
    "wcagPrinciples": [
      {
        "principle": "1.3.1",
        "text": "Info and Relationships (Level A)"
      }
    ]
  },
  {
    "id": "table_caption",
    "type": "structure",
    "title": "Table caption",
    "description": "A table caption is present.",
    "context": "An associated table caption will be read by a screen reader with the table content.",
    "wcagPrinciples": [
      {
        "principle": "1.3.1",
        "text": "Info and Relationships (Level A)"
      }
    ]
  },
  {
    "id": "th",
    "type": "structure",
    "title": "Table header cell",
    "description": "A table header cell (<th>) is present.",
    "context": "Table headers describe the content of their respective row or column. They can be identified by screen readers when data cells are encountered.",
    "wcagPrinciples": [
      {
        "principle": "1.3.1",
        "text": "Info and Relationships (Level A)"
      }
    ]
  },
  {
    "id": "th_col",
    "type": "structure",
    "title": "Column header cell",
    "description": "A table column header (<th scope=\"col\">) is present.",
    "context": "Adding a column scope to a table header ensures the cells within that column will be programmatically associated to that header, particularly with complex tables. This facilitates screen reader navigation and orientation within the data table.",
    "wcagPrinciples": [
      {
        "principle": "1.3.1",
        "text": "Info and Relationships (Level A)"
      }
    ]
  },
  {
    "id": "th_row",
    "type": "structure",
    "title": "Row header cell",
    "description": "A table row header (<th scope=\"row\">) is present.",
    "context": "Adding a row scope to a table header ensures the cells within that row will be programmatically associated to that header, particularly with complex tables. This facilitates screen reader navigation and orientation within the data table.",
    "wcagPrinciples": [
      {
        "principle": "1.3.1",
        "text": "Info and Relationships (Level A)"
      }
    ]
  },
  {
    "id": "iframe",
    "type": "structure",
    "title": "Inline Frame",
    "description": "An inline frame (<iframe>) is present. \t",
    "context": "The content of an inline frame is read as if it were part of the page that contains it. The content of the iframe must be accessible. A title attribute value for the iframe will generally be read by a screen reader when the iframe is encountered."
  },
  {
    "id": "aria",
    "type": "aria",
    "title": "ARIA",
    "description": "An ARIA role, state, or property is present.",
    "context": "ARIA provides enhanced semantics and accessibility for web content.",
    "wcagPrinciples": [
      {
        "principle": "4.1.2",
        "text": "Name, Role, Value (Level A)"
      }
    ]
  },
  {
    "id": "aria_label",
    "type": "aria",
    "title": "ARIA label",
    "description": "An aria-label or aria-labelledby attribute is present.",
    "context": "ARIA labels define accessible names to be read by screen readers for interface elements. They may be used when HTML associations (label, alternative text, etc.) are not sufficient.",
    "wcagPrinciples": [
      {
        "principle": "1.1.1",
        "text": "Non-text Content (Level A)"
      },
      {
        "principle": "1.3.1",
        "text": "Info and Relationships (Level A)"
      },
      {
        "principle": "2.4.6",
        "text": "Headings and Labels (Level AA)"
      },
      {
        "principle": "4.1.2",
        "text": "Name, Role, Value (Level A)"
      }
    ]
  },
  {
    "id": "aria_describedby",
    "type": "aria",
    "title": "ARIA description",
    "description": "An aria-describedby attribute is present.",
    "context": "ARIA descriptions provide additional information about interface elements, primarily form controls.",
    "wcagPrinciples": [
      {
        "principle": "1.3.1",
        "text": "Info and Relationships (Level A)"
      },
      {
        "principle": "4.1.2",
        "text": "Name, Role, Value (Level A)"
      }
    ]
  },
  {
    "id": "aria_live_region",
    "type": "aria",
    "title": "ARIA alert or live region",
    "description": "An ARIA alert role or live region is present.",
    "context": "ARIA live regions and alerts can be used to dynamically inform screen reader users of content updates.",
    "wcagPrinciples": [
      {
        "principle": "3.3.1",
        "text": "Error Identification (Level A)"
      },
      {
        "principle": "4.1.3",
        "text": "Status Messages (Level AA)"
      }
    ]
  },
  {
    "id": "aria_menu",
    "type": "aria",
    "title": "ARIA menu",
    "description": "An ARIA menu is present.",
    "context": "ARIA menus are application menus (like those used in software menu) with a specific keyboard interactions. They are NOT for navigation links on a web site.",
    "wcagPrinciples": [
      {
        "principle": "2.1.1",
        "text": "Keyboard (Level A)"
      },
      {
        "principle": "4.1.2",
        "text": "Name, Role, Value (Level A)"
      }
    ]
  },
  {
    "id": "aria_button",
    "type": "aria",
    "title": "ARIA button",
    "description": "An element with role=\"button\" is present.",
    "context": "Elements with role=\"button\" must function like native buttons. These should typically be replaced with true button elements.",
    "wcagPrinciples": [
      {
        "principle": "2.1.1",
        "text": "Keyboard (Level A)"
      },
      {
        "principle": "4.1.2",
        "text": "Name, Role, Value (Level A)"
      }
    ]
  },
  {
    "id": "aria_expanded",
    "type": "aria",
    "title": "ARIA expanded",
    "description": "An aria-expanded attribute is present.",
    "context": "The aria-expanded attribute indicates the status of content that can be expanded and collapsed.",
    "wcagPrinciples": [
      {
        "principle": "4.1.2",
        "text": "Name, Role, Value (Level A)"
      }
    ]
  },
  {
    "id": "aria_haspopup",
    "type": "aria",
    "title": "ARIA popup",
    "description": "An element triggers a popup menu, dialog, or other element.",
    "context": "The aria-haspopup element, when applied to a button, indicates that triggering the element will open an ARIA menu, dialog, listbox, tree, or grid.",
    "wcagPrinciples": [
      {
        "principle": "4.1.2",
        "text": "Name, Role, Value (Level A)"
      }
    ]
  },
  {
    "id": "aria_tabindex",
    "type": "aria",
    "title": "ARIA tabindex",
    "description": "A tabindex value of 0 or less is present.",
    "context": "Tabindex can facilitate keyboard navigation for interactive elements. A tabindex attribute value of 0 places an item into the keyboard navigation order (i.e., you can navigate to it using the Tab key). A value of less than 0 (typically -1) removes an element from the keyboard flow (you cannot Tab to it), but allows it to receive programmatic focus (e.g., via scripting). ",
    "wcagPrinciples": [
      {
        "principle": "2.1.1",
        "text": "Keyboard (Level A)"
      }
    ]
  },
  {
    "id": "aria_hidden",
    "type": "aria",
    "title": "ARIA hidden",
    "description": "Content is hidden with ARIA.",
    "context": "Content hidden with aria-hidden=\"true\" is not presented to screen reader users.",
    "wcagPrinciples": [
      {
        "principle": "4.1.2",
        "text": "Name, Role, Value (Level A)"
      }
    ]
  }
] }
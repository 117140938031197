import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

const PrivacyPolicy = () => (
  <Layout>
    <SEO title="Privacy Policy" />

    <section className="page">
      <div className="inner">
        <h1>Privacy Policy</h1>
        <p dir="ltr">Last updated September 10, 2020</p>

        <p dir="ltr">
          Thank you for choosing to be part of our community at
          AccessibilityTest.org, LLC ("Company", "we", "us", or "our"). We are
          committed to protecting your personal information and your right to
          privacy. If you have any questions or concerns about this privacy
          notice, or our practices with regards to your personal information,
          please contact us at ryan@nightswim.co.
        </p>
        <p dir="ltr">
          When you visit our website AccessibilityTest.org (the "Website"), and
          more generally, use any of our services (the "Services", which include
          the Website), we appreciate that you are trusting us with your
          personal information. We take your privacy very seriously. In this
          privacy notice, we seek to explain to you in the clearest way possible
          what information we collect, how we use it and what rights you have in
          relation to it. We hope you take some time to read through it
          carefully, as it is important. If there are any terms in this privacy
          notice that you do not agree with, please discontinue use of our
          Services immediately.
        </p>
        <p dir="ltr">
          This privacy notice applies to all information collected through our
          Services (which, as described above, includes our Website), as well as
          any related services, sales, marketing or events.
        </p>
        <p dir="ltr">
          Please read this privacy notice carefully as it will help you
          understand what we do with the information that we collect.
        </p>

        <h2>1. WHAT INFORMATION DO WE COLLECT?</h2>

        <p dir="ltr">Personal information you disclose to us</p>
        <p dir="ltr">
          <em>In Short: We collect information that you provide to us.</em>
        </p>
        <p dir="ltr">
          We collect personal information that you voluntarily provide to us
          when you express an interest in obtaining information about us or our
          products and Services, when you participate in activities on the
          Website or otherwise when you contact us.
        </p>
        <p dir="ltr">
          The personal information that we collect depends on the context of
          your interactions with us and the Website, the choices you make and
          the products and features you use. The personal information we collect
          may include the following:
        </p>
        <p dir="ltr">
          Personal Information Provided by You. We collect email addresses; and
          other similar information.
        </p>
        <p dir="ltr">
          All personal information that you provide to us must be true, complete
          and accurate, and you must notify us of any changes to such personal
          information.
        </p>

        <p dir="ltr">Information automatically collected</p>
        <p dir="ltr">
          <em>
            In Short: Some information - such as your Internet Protocol (IP)
            address and/or browser and device characteristics - is collected
            automatically when you visit our Website.
          </em>
        </p>

        <p dir="ltr">
          We automatically collect certain information when you visit, use or
          navigate the Website. This information does not reveal your specific
          identity (like your name or contact information) but may include
          device and usage information, such as your IP address, browser and
          device characteristics, operating system, language preferences,
          referring URLs, device name, country, location, information about who
          and when you use our Website and other technical information. This
          information is primarily needed to maintain the security and operation
          of our Website, and for our internal analytics and reporting purposes.
        </p>

        <p dir="ltr">The information we collect includes:</p>
        <ul>
          <li dir="ltr">
            <p dir="ltr">
              Log and Usage Data. Log and usage data is service-related,
              diagnostic usage and performance information our servers
              automatically collect when you access or use our Website and which
              we record in log files. Depending on how you interact with us,
              this log data may include your IP address, device information,
              browser type and settings and information about your activity in
              the Website (such as the date/time stamps associated with your
              usage, pages and files viewed, searches and other actions you take
              such as which features you use), device event information (such as
              system activity, error reports (sometimes called 'crash dumps')
              and hardware settings).
            </p>
          </li>
        </ul>
        <ul>
          <li dir="ltr">
            <p dir="ltr">
              Device Data. We collect device data such as information about your
              computer, phone, tablet or other device you use to access the
              Website. Depending on the device used, this device data may
              include information such as your IP address (or proxy server),
              device application identification numbers, location, browser type,
              hardware model Internet service provider and/or mobile carrier,
              operating system configuration information.
            </p>
          </li>
        </ul>

        <h2 dir="ltr">2. HOW DO WE USE YOUR INFORMATION?</h2>
        <p dir="ltr">
          <em>
            In Short: We process your information for purposes based on
            legitimate business interests, the fulfillment of our contract with
            you, compliance with our legal obligations, and/or your consent.
          </em>
        </p>
        <p dir="ltr">
          We use personal information collected via our Website for a variety of
          business purposes described below. We process your personal
          information for these purposes in reliance on our legitimate business
          interests, in order to enter into or perform a contract with you, with
          your consent, and/or for compliance with our legal obligations. We
          indicate the specific processing grounds we rely on next to each
          purpose listed below.
        </p>
        <p dir="ltr">We use the information we collect or receive:</p>
        <ul>
          <li dir="ltr">
            <p dir="ltr">
              To send you marketing and promotional communications. We and/or
              our third-party marketing partners may use the personal
              information you send to us for our marketing purposes, if this is
              in accordance with your marketing preferences. For example, when
              expressing an interest in obtaining information about us or our
              Website, subscribing to marketing or otherwise contacting us, we
              will collect personal information from you. You can opt-out of our
              marketing emails at any time (see the "
              <a href="https://app.termly.io/dashboard/website/549005/privacy-policy#privacyrights">
                WHAT ARE YOUR PRIVACY RIGHTS
              </a>
              " below).
            </p>
          </li>
          <li dir="ltr">
            <p dir="ltr">
              Deliver targeted advertising to you. We may use your information
              to develop and display personalized content and advertising (and
              work with third parties who do so) tailored to your interests
              and/or location and to measure its effectiveness.
            </p>
          </li>
        </ul>

        <h2 dir="ltr">3. WILL YOUR INFORMATION BE SHARED WITH ANYONE?</h2>
        <p dir="ltr">
          <em>
            In Short: We only share information with your consent, to comply
            with laws, to provide you with services, to protect your rights, or
            to fulfill business obligations.
          </em>
        </p>
        <p dir="ltr">
          We may process or share your data that we hold based on the following
          legal basis:
        </p>
        <ul>
          <li dir="ltr">
            <p dir="ltr">
              Consent: We may process your data if you have given us specific
              consent to use your personal information in a specific purpose.
            </p>
          </li>
          <li dir="ltr">
            <p dir="ltr">
              Legitimate Interests: We may process your data when it is
              reasonably necessary to achieve our legitimate business interests.
            </p>
          </li>
          <li dir="ltr">
            <p dir="ltr">
              Performance of a Contract: Where we have entered into a contract
              with you, we may process your personal information to fulfill the
              terms of our contract.
            </p>
          </li>
          <li dir="ltr">
            <p dir="ltr">
              Legal Obligations: We may disclose your information where we are
              legally required to do so in order to comply with applicable law,
              governmental requests, a judicial proceeding, court order, or
              legal process, such as in response to a court order or a subpoena
              (including in response to public authorities to meet national
              security or law enforcement requirements).
            </p>
          </li>
          <li dir="ltr">
            <p dir="ltr">
              Vital Interests: We may disclose your information where we believe
              it is necessary to investigate, prevent, or take action regarding
              potential violations of our policies, suspected fraud, situations
              involving potential threats to the safety of any person and
              illegal activities, or as evidence in litigation in which we are
              involved.
            </p>
          </li>
        </ul>
        <p dir="ltr">
          More specifically, we may need to process your data or share your
          personal information in the following situations:
        </p>
        <ul>
          <li dir="ltr">
            <p dir="ltr">
              Business Transfers. We may share or transfer your information in
              connection with, or during negotiations of, any merger, sale of
              company assets, financing, or acquisition of all or a portion of
              our business to another company.
            </p>
          </li>
        </ul>
        <ul>
          <li dir="ltr">
            <p dir="ltr">
              Affiliates. We may share your information with our affiliates, in
              which case we will require those affiliates to honor this privacy
              notice. Affiliates include our parent company and any
              subsidiaries, joint venture partners or other companies that we
              control or that are under common control with us.
            </p>
          </li>
        </ul>
        <ul>
          <li dir="ltr">
            <p dir="ltr">
              Business Partners. We may share your information with our business
              partners to offer you certain products, services or promotions.
            </p>
          </li>
        </ul>

        <h2 dir="ltr">4. HOW LONG DO WE KEEP YOUR INFORMATION?</h2>
        <p dir="ltr">
          <em>
            In Short: We keep your information for as long as necessary to
            fulfill the purposes outlined in this privacy notice unless
            otherwise required by law.
          </em>
        </p>
        <p dir="ltr">
          We will only keep your personal information for as long as it is
          necessary for the purposes set out in this privacy notice, unless a
          longer retention period is required or permitted by law (such as tax,
          accounting or other legal requirements). No purpose in this notice
          will require us keeping your personal information for longer than 1
          year.
        </p>
        <p dir="ltr">
          When we have no ongoing legitimate business need to process your
          personal information, we will either delete or anonymize such
          information, or, if this is not possible (for example, because your
          personal information has been stored in backup archives), then we will
          securely store your personal information and isolate it from any
          further processing until deletion is possible.
        </p>

        <h2 dir="ltr">5. HOW DO WE KEEP YOUR INFORMATION SAFE?</h2>
        <p dir="ltr">
          <em>
            In Short: We aim to protect your personal information through a
            system of organizational and technical security measures.
          </em>
        </p>
        <p dir="ltr">
          We have implemented appropriate technical and organizational security
          measures designed to protect the security of any personal information
          we process. However, despite our safeguards and efforts to secure your
          information, no electronic transmission over the Internet or
          information storage technology can be guaranteed to be 100% secure, so
          we cannot promise or guarantee that hackers, cybercriminals, or other
          unauthorized third parties will not be able to defeat our security,
          and improperly collect, access, steal, or modify your information.
          Although we will do our best to protect your personal information,
          transmission of personal information to and from our Website is at
          your own risk. You should only access the Website within a secure
          environment.
        </p>

        <p dir="ltr">
          <h2>6. DO WE COLLECT INFORMATION FROM MINORS?</h2>
        </p>
        <p dir="ltr">
          <em>
            In Short: We do not knowingly collect data from or market to
            children under 18 years of age.
          </em>
        </p>
        <p dir="ltr">
          We do not knowingly solicit data from or market to children under 18
          years of age. By using the Website, you represent that you are at
          least 18 or that you are the parent or guardian of such a minor and
          consent to such minor dependent's use of the Website. If we learn that
          personal information from users less than 18 years of age has been
          collected, we will deactivate the account and take reasonable measures
          to promptly delete such data from our records. If you become aware of
          any data we may have collected from children under age 18, please
          contact us at hello@AccessibilityTest.org.
        </p>

        <h2 dir="ltr">7. WHAT ARE YOUR PRIVACY RIGHTS?</h2>
        <p dir="ltr">
          <em>
            In Short: You may review, change, or terminate your account at any
            time.
          </em>
        </p>
        <p dir="ltr">
          If you are resident in the European Economic Area and you believe we
          are unlawfully processing your personal information, you also have the
          right to complain to your local data protection supervisory authority.
          You can find their contact details here:
          <a href="http://ec.europa.eu/justice/data-protection/bodies/authorities/index_en.htm">
            http://ec.europa.eu/justice/data-protection/bodies/authorities/index_en.htm
          </a>
          .
        </p>
        <p dir="ltr">
          If you are resident in Switzerland, the contact details for the data
          protection authorities are available here:
          <a href="https://www.edoeb.admin.ch/edoeb/en/home.html">
            https://www.edoeb.admin.ch/edoeb/en/home.html
          </a>
          .
        </p>

        <h2 dir="ltr">8. CONTROLS FOR DO-NOT-TRACK FEATURES</h2>
        <p dir="ltr">
          Most web browsers and some mobile operating systems and mobile
          applications include a Do-Not-Track ("DNT") feature or setting you can
          activate to signal your privacy preference not to have data about your
          online browsing activities monitored and collected. At this stage, no
          uniform technology standard for recognizing and implementing DNT
          signals has been finalized. As such, we do not currently respond to
          DNT browser signals or any other mechanism that automatically
          communicates your choice not to be tracked online. If a standard for
          online tracking is adopted that we must follow in the future, we will
          inform you about that practice in a revised version of this privacy
          notice.
        </p>

        <h2 dir="ltr">
          9. DO CALIFORNIA RESIDENTS HAVE SPECIFIC PRIVACY RIGHTS?
        </h2>
        <p dir="ltr">
          <em>
            In Short: Yes, if you are a resident of California, you are granted
            specific rights regarding access to your personal information.
          </em>
        </p>
        <p dir="ltr">
          California Civil Code Section 1798.83, also known as the "Shine The
          Light" law, permits our users who are California residents to request
          and obtain from us, once a year and free of charge, information about
          categories of personal information (if any) we disclosed to third
          parties for direct marketing purposes and the names and addresses of
          all third parties with which we shared personal information in the
          immediately preceding calendar year. If you are a California resident
          and would like to make such a request, please submit your request in
          writing to us using the contact information provided below.
        </p>
        <p dir="ltr">
          If you are under 18 years of age, reside in California, and have a
          registered account with the Website, you have the right to request
          removal of unwanted data that you publicly post on the Website. To
          request removal of such data, please contact us using the contact
          information provided below, and include the email address associated
          with your account and a statement that you reside in California. We
          will make sure the data is not publicly displayed on the Website, but
          please be aware that the data may not be completely or comprehensively
          removed from all our systems (e.g. backups, etc.).
        </p>

        <p dir="ltr">CCPA Privacy Notice</p>
        <p dir="ltr">
          The California Code of Regulations defines a "resident" as:
        </p>
        <p dir="ltr">
          (1) every individual who is in the State of California for other than
          a temporary or transitory purpose and
        </p>
        <p dir="ltr">
          (2) every individual who is domiciled in the State of California who
          is outside the State of California for a temporary or transitory
          purpose
        </p>
        <p dir="ltr">All other individuals are defined as "non-residents."</p>

        <p dir="ltr">
          If this definition of "resident" applies to you, certain rights and
          obligations apply regarding your personal information.
        </p>

        <p dir="ltr">
          We may also collect other personal information outside of these
          categories in instances where you interact with us in-person, online,
          or by phone or mail in the context of:
        </p>
        <ul>
          <li dir="ltr">
            <p dir="ltr">
              Receiving help through our customer support channels
            </p>
          </li>
          <li dir="ltr">
            <p dir="ltr">Participation in customer surveys or contests; and</p>
          </li>
          <li dir="ltr">
            <p dir="ltr">
              Facilitation in the delivery of our Services and to respond to
              your inquiries
            </p>
          </li>
        </ul>
        <p dir="ltr">How do we use and share your personal information?</p>
        <p dir="ltr">
          More information about our data collection and sharing practices can
          be found in this privacy notice.
        </p>

        <p dir="ltr">
          You may contact us by email at hello@AccessibilityTest.org, or by
          referring to the contact details at the bottom of this document.
        </p>

        <p dir="ltr">
          If you are using an authorized agent to exercise your right to
          opt-out, we may deny a request if the authorized agent does not submit
          proof that they have been validly authorized to act on your behalf.
        </p>

        <p dir="ltr">Will your information be shared with anyone else?</p>

        <p dir="ltr">
          We may disclose your personal information with our service providers
          pursuant to a written contract between us and each service provider.
          Each service provider is a for-profit entity that processes the
          information on our behalf.
        </p>

        <p dir="ltr">
          We may use your personal information for our own business purposes,
          such as for undertaking internal research for technological
          development and demonstration. This is not considered to be "selling"
          of your personal data.
        </p>

        <p dir="ltr">
          AccessibilityTest.org, LLC has not disclosed or sold any personal
          information to third parties for a business or commercial purpose in
          the preceding twelve (12) months. AccessibilityTest.org, LLC will not
          sell personal information in the future belonging to website visitors,
          users and other consumers.
        </p>

        <p dir="ltr">Your rights with respect to your personal data</p>

        <p dir="ltr">
          Right to request deletion of the data - Request to delete
        </p>

        <p dir="ltr">
          You can ask for the deletion of your personal information. If you ask
          us to delete your personal information, we will respect your request
          and delete your personal information, subject to certain exceptions
          provided by law, such as (but not limited to) the exercise by another
          consumer of his or her right to free speech, our compliance
          requirements resulting from a legal obligation or any processing that
          may be required to protect against illegal activities.
        </p>

        <p dir="ltr">Right to be informed - Request to know</p>

        <p dir="ltr">
          Depending on the circumstances, you have a right to know:
        </p>
        <ul>
          <li dir="ltr">
            <p dir="ltr">
              whether we collect and use your personal information;
            </p>
          </li>
          <li dir="ltr">
            <p dir="ltr">
              the categories of personal information that we collect;
            </p>
          </li>
          <li dir="ltr">
            <p dir="ltr">
              the purposes for which the collected personal information is used;
            </p>
          </li>
          <li dir="ltr">
            <p dir="ltr">
              whether we sell your personal information to third parties;
            </p>
          </li>
          <li dir="ltr">
            <p dir="ltr">
              the categories of personal information that we sold or disclosed
              for a business purpose;
            </p>
          </li>
          <li dir="ltr">
            <p dir="ltr">
              the categories of third parties to whom the personal information
              was sold or disclosed for a business purpose; and
            </p>
          </li>
          <li dir="ltr">
            <p dir="ltr">
              the business or commercial purpose for collecting or selling
              personal information.
            </p>
          </li>
        </ul>
        <p dir="ltr">
          In accordance with applicable law, we are not obligated to provide or
          delete consumer information that is de-identified in response to a
          consumer request or to re-identify individual data to verify a
          consumer request.
        </p>

        <p dir="ltr">
          Right to Non-Discrimination for the Exercise of a Consumer's Privacy
          Rights
        </p>

        <p dir="ltr">
          We will not discriminate against you if you exercise your privacy
          rights.
        </p>

        <p dir="ltr">Verification process</p>

        <p dir="ltr">
          Upon receiving your request, we will need to verify your identity to
          determine you are the same person about whom we have the information
          in our system. These verification efforts require us to ask you to
          provide information so that we can match it with the information you
          have previously provided us. For instance, depending on the type of
          request you submit, we may ask you to provide certain information so
          that we can match the information you provide with the information we
          already have on file, or we may contact you through a communication
          method (e.g. phone or email) that you have previously provided to us.
          We may also use other verification methods as the circumstances
          dictate.
        </p>

        <p dir="ltr">
          We will only use personal information provided in your request to
          verify your identity or authority to make the request. To the extent
          possible, we will avoid requesting additional information from you for
          the purposes of verification. If, however, if we cannot verify your
          identity from the information already maintained by us, we may request
          that you provide additional information for the purposes of verifying
          your identity, and for security or fraud-prevention purposes. We will
          delete such additionally provided information as soon as we finish
          verifying you.
        </p>

        <p dir="ltr">Other privacy rights</p>
        <ul>
          <li dir="ltr">
            <p dir="ltr">
              you may object to the processing of your personal data
            </p>
          </li>
          <li dir="ltr">
            <p dir="ltr">
              you may request correction of your personal data if it is
              incorrect or no longer relevant, or ask to restrict the processing
              of the data
            </p>
          </li>
          <li dir="ltr">
            <p dir="ltr">
              you can designate an authorized agent to make a request under the
              CCPA on your behalf. We may deny a request from an authorized
              agent that does not submit proof that they have been validly
              authorized to act on your behalf in accordance with the CCPA.
            </p>
          </li>
          <li dir="ltr">
            <p dir="ltr">
              you may request to opt-out from future selling of your personal
              information to third parties. Upon receiving a request to opt-out,
              we will act upon the request as soon as feasibly possible, but no
              later than 15 days from the date of the request submission.
            </p>
          </li>
        </ul>
        <p dir="ltr">
          To exercise these rights, you can contact us by email at
          hello@AccessibilityTest.org, or by referring to the contact details at
          the bottom of this document. If you have a complaint about how we
          handle your data, we would like to hear from you.
        </p>

        <h2 dir="ltr">10. DO WE MAKE UPDATES TO THIS NOTICE?</h2>
        <p dir="ltr">
          <em>
            In Short: Yes, we will update this notice as necessary to stay
            compliant with relevant laws.
          </em>
        </p>
        <p dir="ltr">
          We may update this privacy notice from time to time. The updated
          version will be indicated by an updated "Revised" date and the updated
          version will be effective as soon as it is accessible. If we make
          material changes to this privacy notice, we may notify you either by
          prominently posting a notice of such changes or by directly sending
          you a notification. We encourage you to review this privacy notice
          frequently to be informed of how we are protecting your information.
        </p>

        <h2 dir="ltr">11. HOW CAN YOU CONTACT US ABOUT THIS NOTICE?</h2>
        <p dir="ltr">
          If you have questions or comments about this notice, you may email us
          at hello@AccessibilityTest.org or by post to:
        </p>
        <p dir="ltr">AccessibilityTest.org, LLC</p>
        <p dir="ltr">715 SE 62nd Ave.</p>
        <p dir="ltr">Portland, OR 97215</p>
        <p dir="ltr">United States</p>

        <p dir="ltr">
          HOW CAN YOU REVIEW, UPDATE, OR DELETE THE DATA WE COLLECT FROM YOU?
        </p>
        <p dir="ltr">
          Based on the applicable laws of your country, you may have the right
          to request access to the personal information we collect from you,
          change that information, or delete it in some circumstances. To
          request to review, update, or delete your personal information, please
          visit: mailchimp.com. We will respond to your request within 30 days.
        </p>
      </div>
    </section>
  </Layout>
)

export default PrivacyPolicy

import { navigate } from "gatsby"
import React, { useState, useEffect, useRef } from "react"
import { nanoid } from "nanoid"
import axios from "axios"
import _ from "lodash"

import Layout from "../components/layout"
import SEO from "../components/seo"
import useQueryOrStateUrl from "../components/_hooks/useQueryOrStateUrl"
import { trackCustomEvent } from "gatsby-plugin-google-analytics"

const SCAN_TIMEOUT = 90000

const Scan = props => {
  const SCAN_ID = useRef(nanoid(12))
  const SCANNER_COUNT = 4
  let DEFAULT_CRAWL_COUNT = process.env.GATSBY_OFFLINE ? 3 : 25
  let {
    url,
    type = "single",
    count: CRAWL_COUNT = DEFAULT_CRAWL_COUNT,
  } = useQueryOrStateUrl(props.location)

  if (type === "single") {
    CRAWL_COUNT = 1
  }

  const wsEndpoint = process.env.GATSBY_WS_ENDPOINT
  const ws = useRef(null)
  const [wsStatus, setWsStatus] = useState(false)
  const [messages, setMessages] = useState([])
  const [urls, setUrls] = useState([])

  const [audioeyeStatus, setAudioeyeStatus] = useState(0)
  const [waveStatus, setWaveStatus] = useState(0)
  const [pa11yStatus, setPa11yStatus] = useState(0)
  const [toolbarStatus, setToolbarStatus] = useState(0)
  const [completeUrls, setCompleteUrls] = useState([])
  const [scanDone, setScanDone] = useState(false)
  const [status, setStatus] = useState("")
  const [progress, setProgress] = useState(0)
  const [progressPercentage, setProgressPercentage] = useState(0)
  const progressInt = useRef(null)
  const [progressRuns, setProgressRuns] = useState(0)
  const [progressIntervals, setProgressIntervals] = useState(0)

  useEffect(() => {
    const t = setTimeout(() => {
      if (!scanDone) {
        if (
          window.confirm(
            `Rats, looks like the scanners got messed up. Want to try again?`
          )
        ) {
          ws.current.onclose = null
          ws.current.close()
          trackCustomEvent({
            category: "scanner",
            action: "error",
            label: "timeout",
          })
          setScanDone(true)
          window.location.reload()
        }
      }
    }, SCAN_TIMEOUT)

    return () => {
      clearTimeout(t)
    }
  }, [scanDone])

  // get crawler results
  useEffect(() => {
    async function fetchCrawlResults() {
      try {
        const crawlResults = await axios.get(
          `${process.env.GATSBY_API_ENDPOINT}/crawler?url=${url}&count=${CRAWL_COUNT}`
        )
        trackCustomEvent({
          category: "scanner",
          action: "crawl",
          label: crawlResults.data.results.length,
        })
        setUrls(crawlResults.data.results)
      } catch (error) {
        console.error(error)
        trackCustomEvent({
          category: "scanner",
          action: "error",
          label: "crawl-error",
        })
        alert(
          `There was an error finding pages for your scan. Please try another URL.`
        )
      }
    }
    if (type === "multi") {
      fetchCrawlResults()
    } else {
      setUrls([url])
    }
  }, [url, type, CRAWL_COUNT])

  // websocket things
  useEffect(() => {
    ws.current = new WebSocket(wsEndpoint)
    ws.current.onopen = e => {
      setWsStatus("open")
      console.log(e)
    }
    ws.current.onclose = e => {
      setWsStatus("closed")

      console.log(e)
    }
    ws.current.onerror = e => {
      setWsStatus("error")
      console.log(e)
      trackCustomEvent({
        category: "scanner",
        action: "error",
        label: "websocket-error",
      })
    }
    ws.current.onmessage = e => {
      handleOnMessage(e)
    }

    return () => {
      ws.current.onclose = null
      ws.current.close()
    }
  }, [wsEndpoint])

  // set current status
  useEffect(() => {
    let lastMessage = _.last(messages)
    let lastUrl = ""
    if (lastMessage) {
      lastUrl = new URL(lastMessage.url)
    }

    if (!urls.length) {
      setStatus("Finding pages to scan")
    }

    if (
      [audioeyeStatus, waveStatus, pa11yStatus, toolbarStatus].some(
        s => s === 0
      )
    ) {
      setStatus("Initializing")
    }

    if (
      [audioeyeStatus, waveStatus, pa11yStatus, toolbarStatus].some(
        s => s === 1
      ) &&
      lastUrl
    ) {
      if (urls.length > 1) {
        setStatus(
          `Scanning: ${Math.floor(completeUrls.length / SCANNER_COUNT)}/${
            urls.length
          } pages complete`
        )
      } else if (status !== "Scanning...") {
        setStatus(`Scanning...`)
      }
    }

    if (wsStatus === "closed" && !messages.length) {
      setStatus("Scanners disconnected")
    }

    if (scanDone) {
      const tooManyErrors =
        [audioeyeStatus, waveStatus, pa11yStatus, toolbarStatus].filter(
          s => s === 3
        ).length >
        urls.length / 2

      if (!tooManyErrors) {
        trackCustomEvent({
          category: "scanner",
          action: "scan",
          label: "complete",
        })
        navigate(`/results/${SCAN_ID.current}`)
      } else {
        trackCustomEvent({
          category: "scanner",
          action: "scan",
          label: "error",
        })
        navigate(`/error`, {
          state: {
            scanId: SCAN_ID.current,
            urls: urls,
            messages: messages,
          },
        })
      }
    }
  }, [
    messages,
    status,
    urls,
    completeUrls,
    wsStatus,
    audioeyeStatus,
    waveStatus,
    pa11yStatus,
    toolbarStatus,
    scanDone,
    SCANNER_COUNT,
  ])

  // progress bar
  useEffect(() => {
    const messagesNeeded = urls.length * SCANNER_COUNT
    const successMessages = messages.filter(
      m => m.status === 2 || m.status === 3
    ).length

    setProgressIntervals(Math.floor(100 / messagesNeeded))

    const newProgress = _.clamp(
      Math.ceil((successMessages / messagesNeeded) * 100),
      1,
      100
    )

    if (newProgress > progress) {
      // console.log(`newProgress`, newProgress)
      setProgress(newProgress)
      setProgressRuns(0)
    }
  }, [messages, urls, progress])

  // looping progress runs
  useEffect(() => {
    progressInt.current = setInterval(() => {
      if (progressRuns === 0) {
        setProgressRuns(runs => runs + 0.5)
      }
    }, 350)

    return () => {
      clearInterval(progressInt.current)
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  // set progress bar percentage
  useEffect(() => {
    const maxCurrentProgress = Math.min(...[progress + progressIntervals, 100])
    const newCurrent = _.clamp(
      progress + progressRuns,
      progress,
      maxCurrentProgress
    )

    if (newCurrent <= maxCurrentProgress) {
      setProgressPercentage(newCurrent)
    }
  }, [progressRuns, progress, progressIntervals])

  // start scanning
  useEffect(() => {
    async function startScans() {
      try {
        return await Promise.all(
          urls.map(url => {
            return sendMessage({
              action: "scan",
              data: {
                id: SCAN_ID.current,
                url: url,
              },
            })
          })
        )
      } catch (error) {
        console.error(error)
      }
    }

    if (wsStatus === "open" && _.compact(urls).length) {
      startScans()
    }
  }, [wsStatus, urls])

  // sync scanner-specific statuses
  useEffect(() => {
    if (messages && messages.length) {
      setAudioeyeStatus(
        getStatus(messages.filter(m => m.scanner === "audioeye"))
      )
      setWaveStatus(getStatus(messages.filter(m => m.scanner === "wave")))
      setPa11yStatus(getStatus(messages.filter(m => m.scanner === "pa11y")))
      setToolbarStatus(getStatus(messages.filter(m => m.scanner === "toolbar")))
    }
  }, [messages])

  // determine url complete count
  useEffect(() => {
    if (messages.length) {
      const currentUrls = messages
        .filter(m => m.status === 2 || m.status === 3)
        .map(m => {
          return m.url
        })

      if (!_.isEqual(completeUrls, currentUrls)) {
        setCompleteUrls(currentUrls)
      }
    }
  }, [messages, completeUrls])

  // all done?
  useEffect(() => {
    if (
      (audioeyeStatus === 2 || audioeyeStatus === 3) &&
      (waveStatus === 2 || waveStatus === 3) &&
      (pa11yStatus === 2 || pa11yStatus === 3) &&
      (toolbarStatus === 2 || toolbarStatus === 3)
    ) {
      ws.current.onclose = null
      ws.current.close()

      setTimeout(() => {
        setScanDone(true)
      }, 500)
    }
  }, [audioeyeStatus, waveStatus, pa11yStatus, toolbarStatus])

  function handleOnMessage(msg) {
    const data = JSON.parse(msg.data)
    setMessages(prev => [...prev, data])
  }

  function sendMessage(msg) {
    ws.current.send(JSON.stringify(msg))
  }

  function getStatus(messages) {
    const urls = _.groupBy(messages, m => {
      return m.url
    })

    let urlStatuses = []
    for (const url in urls) {
      const lastStatus = _.last(urls[url]).status
      urlStatuses.push(lastStatus)
    }

    if (urlStatuses.some(s => s === 3)) {
      return 3
    }

    if (urlStatuses.some(s => s === 1)) {
      return 1
    }

    if (urlStatuses.every(s => s === 2)) {
      return 2
    }
  }

  return (
    <Layout>
      <SEO title="Scanner" />
      <section className="scan scanning">
        <div className="inner">
          {!scanDone && (
            <>
              <h3 aria-live="polite">{status}</h3>

              {wsStatus === "closed" && (
                <button onClick={() => window.location.reload()}>Retry</button>
              )}
              {messages.length > 0 && (
                <>
                  <div className="progress">
                    <div
                      className="bar"
                      style={{
                        width: `${progressPercentage}%`,
                      }}
                    ></div>
                  </div>
                  <p className="mono xsmall">
                    {_.truncate(_.last(messages).url, {
                      length: 50,
                    })}
                  </p>
                  <p className="mono xsmall">ID: {SCAN_ID.current}</p>
                </>
              )}
            </>
          )}
        </div>
      </section>
    </Layout>
  )
}

export default Scan

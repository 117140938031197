import React from "react"
import { Link } from "gatsby"

const Footer = () => {
  return (
    <footer>
      <div className="inner">
        <div className="left">
          <p>&copy; 2020 AccessibilityTest.org, LLC. All rights reserved.</p>
        </div>
        
        <div className="right">
          <p><Link to="/privacy-policy">Privacy Policy</Link></p>
        </div>
      </div>
    </footer>
  )
}

export default Footer
import React, { useState } from "react"
import { navigate } from "gatsby"
import { trackCustomEvent } from "gatsby-plugin-google-analytics"

const ScanForm = props => {
  const DEFAULT_CRAWL_COUNT = process.env.GATSBY_OFFLINE ? 5 : 25
  const [url, setUrl] = useState("")
  const [scanType, setScanType] = useState("single")

  const handleSubmit = async e => {
    e.preventDefault()
    trackCustomEvent({ category: "scan", action: scanType, label: url })
    navigate(`/scan`, {
      state: {
        url: url,
        type: scanType,
        count: scanType === "multi" ? DEFAULT_CRAWL_COUNT : 1,
      },
    })
  }

  function handleTypeChange(e) {
    setScanType(e.target.value)
  }

  return (
    <>
      <form onSubmit={handleSubmit} action="#" name="urlForm">
        <label htmlFor="url" className="visuallyhidden">
          Enter your URL
        </label>
        <input
          id="url"
          type="text"
          placeholder="Enter your URL"
          autoCorrect="off"
          autoCapitalize="off"
          autoComplete="off"
          value={url}
          ref={props.inputRef}
          required
          pattern="(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)"
          onChange={e => setUrl(e.target.value)}
          onInput={e => {
            e.target.setCustomValidity("")
            if (!e.target.validity.valid) {
              e.target.setCustomValidity("Please enter a URL.")
            }
          }}
          inputMode="url"
        />
        <button type="submit" className="cream">
          Test Site
        </button>

        <div className="scan-types">
          <div className="scan-type">
            <input
              id="scanType-single"
              name="scanType"
              type="radio"
              value="single"
              onChange={handleTypeChange}
              defaultChecked
            />
            <label htmlFor="scanType-single">Single Page Scan</label>
          </div>

          <div className="scan-type">
            <input
              id="scanType-multi"
              name="scanType"
              type="radio"
              value="multi"
              onChange={handleTypeChange}
            />
            <label htmlFor="scanType-multi">Deep Site Scan</label>
          </div>
        </div>
      </form>

      <p className="error"></p>
    </>
  )
}

export default ScanForm

import React from "react"
import ScanForm from "./form"

const ScanIntro = () => {
  return (
    <section className="scan intro">
      <div className="inner">
        <h2>
          Test the accessibility of your website with the world’s most
          comprehensive free accessibility testing tool.
        </h2>

        <ScanForm />

        <h3>Comprehensive Testing Provided By:</h3>
        <div className="testing-providers">
          <img src="/logo-audioeye-mark.svg" alt="Audioeye" />
          <img src="/logo-wave-mark.svg" alt="WAVE" />
          <img src="/logo-axe-mark.png" alt="Deque axe" />
          <img src="/logo-html_codesniffer-mark.svg" alt="HTML_CodeSniffer" />
        </div>
      </div>
    </section>
  )
}

export default ScanIntro

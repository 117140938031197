import React, { useState, useEffect, useRef } from "react"
import axios from "axios"
import axiosRetry from "axios-retry"
import _ from "lodash"
import Modal from "react-modal"
import { Tab, Tabs, TabList, TabPanel } from "react-tabs"
import pluralize from "pluralize"

import Layout from "../components/layout"
import SEO from "../components/seo"
import ScanEngine from "../components/scan/engine"
import ScanResults from "../components/scan/scanResults"
import { Link } from "gatsby"
import { trackCustomEvent } from "gatsby-plugin-google-analytics"

Modal.setAppElement(`#___gatsby`)

const Results = props => {
  const shareUrl = typeof window !== `undefined` ? window.location.href : ""
  const scanId = props.params.id
  const [results, setResults] = useState(false)
  const [engineResults, setEngineResults] = useState(false)
  const scanUrls = useRef([])
  const [modalOpen, setModalOpen] = useState(false)
  const modalStyles = {
    overlay: {
      backgroundColor: "rgba(0, 0, 0, 0.75)",
    },
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      padding: "40px",
      transform: "translate(-50%, -50%)",
      backgroundColor: "#FFF9F0",
      border: "0",
      borderRadius: "0",
    },
  }
  function closeModal() {
    setModalOpen(false)
  }

  function setScanUrls(results) {
    scanUrls.current = _.uniq(results.map(r => r.url)).sort(
      (a, b) => a.length - b.length
    )
  }

  useEffect(() => {
    async function fetchData() {
      axiosRetry(axios, { retries: 3 })
      const scanDetails = await axios.get(
        `${process.env.GATSBY_API_ENDPOINT}/scanDetails?id=${scanId}`
      )
      const detailPromises = _.flatten(
        scanDetails.data.results.map(scan => {
          return scan.keys.map(key => {
            return axios.get(
              `${process.env.GATSBY_RESULTS_BUCKET}/${scanId}/${scan.scanner}/${key}`
            )
          })
        })
      )

      try {
        const finalResults = await Promise.all(detailPromises)
        setResults(finalResults.map(r => r.data))
        setScanUrls(finalResults.map(r => r.data))
        trackCustomEvent({
          category: "results",
          action: "fetch",
          label: "success",
        })
      } catch (e) {
        trackCustomEvent({
          category: "results",
          action: "fetch",
          label: "error",
        })
        alert(`There was an error: ${e}`)
      }
    }
    fetchData()
  }, [scanId])

  useEffect(() => {
    if (results && results.length > 0) {
      const engine = new ScanEngine(results).results()
      setEngineResults(engine)
      console.log(engine)
      trackCustomEvent({
        category: "results",
        action: "engine",
        label: JSON.stringify({
          id: scanId,
          url: engine.urls[0],
          totals: engine.total,
        }),
      })
    }
  }, [results, scanId])

  function duration() {
    if (!engineResults) {
      return 0
    }
    const durations = engineResults._scanResults.map(r =>
      parseInt(r.data.duration)
    )
    return `${Math.floor(Math.max(...durations) / 1000)} seconds`
  }

  function scoreColorClass(score) {
    if (score >= 80) {
      return "green"
    }
    if (score >= 60) {
      return "yellow"
    }
    if (score < 60) {
      return "red"
    }

    return ""
  }

  return (
    <Layout>
      <SEO title="Results" />
      <section className="results">
        <div className="inner">
          {!engineResults && <p className="loading mono">LOADING...</p>}
          {engineResults && (
            <>
              <p className="mono small test-another">
                <Link to="/">Test Another Site</Link>
              </p>
              <div className="overall">
                <div
                  className={`score ${scoreColorClass(
                    engineResults.total.score
                  )}`}
                >
                  <p>Score</p>

                  <div
                    className={`score-wrapper ${scoreColorClass(
                      engineResults.total.score
                    )}`}
                  >
                    <p>
                      <span>{engineResults.total.score}</span> / 100
                    </p>
                  </div>
                </div>
                <div className="details">
                  <p className="mono small">
                    {new URL(engineResults.urls[0]).hostname}
                  </p>
                  <h2>
                    You have an accessibility score of{" "}
                    <span
                      className={scoreColorClass(engineResults.total.score)}
                    >
                      {engineResults.total.score}
                    </span>
                  </h2>
                  <p>
                    This score is based on the results from four different
                    accessibility testers and is meant to provide you a
                    comprehensive understanding of your websites accessibility.
                  </p>

                  {engineResults.toolbar && (
                    <p className="mono small">
                      ✔ Found an accessibility toolbar
                    </p>
                  )}

                  <ul className="meta">
                    <li>
                      <button
                        onClick={() => setModalOpen(true)}
                        className="inline"
                      >
                        {pluralize("page", engineResults.urls.length, true)}
                      </button>
                    </li>
                    <li>{engineResults.total.tests.toLocaleString()} tests</li>
                    <li>
                      {engineResults.total.errors.toLocaleString()} failures
                    </li>
                    <li>{duration()} scan time</li>
                  </ul>
                </div>
              </div>

              <Modal
                isOpen={modalOpen}
                contentLabel="Pages scanned"
                onRequestClose={closeModal}
                style={modalStyles}
              >
                <ul className="on-dark">
                  {scanUrls.current.map(url => {
                    url = new URL(url)
                    return (
                      <li key={url.href}>
                        <a
                          href={url.href}
                          target="_blank"
                          rel="noreferrer noopener"
                        >
                          {url.href}
                        </a>
                      </li>
                    )
                  })}
                </ul>

                <button
                  className="small close"
                  onClick={() => setModalOpen(false)}
                >
                  Close
                </button>
              </Modal>

              {engineResults.total.score < 100 && (
                <div className="all-scan-results">
                  <h3>Accessibility Test Results</h3>

                  <Tabs>
                    <div className="tablist-wrapper">
                      <div className="tablist-instructions">
                        <p>Select a tester:</p>
                      </div>

                      <TabList>
                        <Tab>
                          <span>AudioEye</span>
                        </Tab>
                        <Tab>
                          <span>WAVE</span>
                        </Tab>
                        <Tab>
                          <span>axe</span>
                        </Tab>
                        <Tab>
                          <span>HTML_CodeSniffer</span>
                        </Tab>
                      </TabList>
                    </div>

                    <TabPanel>
                      <ScanResults
                        data={engineResults.audioeye}
                        scanner="AudioEye"
                        logo="/logo-audioeye.svg"
                        description="AudioEye is a leading provider of tools and resources that help businesses of all sizes make their digital properties more accessible."
                        link="https://audioeye.com"
                      />
                    </TabPanel>

                    <TabPanel>
                      <ScanResults
                        data={engineResults.wave}
                        scanner="WAVE"
                        logo="/logo-wave-mark.svg"
                        description="WAVE is a suite of evaluation tools that helps authors make their web content more accessible to individuals with disabilities."
                        link="https://wave.webaim.org/"
                      />
                    </TabPanel>

                    <TabPanel>
                      <ScanResults
                        data={engineResults.axe}
                        scanner="Axe"
                        logo="/logo-axe-mark.png"
                        description="Axe is an accessibility testing engine for websites and other HTML-based user interfaces. It's fast, secure, lightweight, and was built to seamlessly integrate with any existing test environment so you can automate accessibility testing alongside your regular functional testing."
                        link="https://dequeuniversity.com/"
                      />
                    </TabPanel>

                    <TabPanel>
                      <ScanResults
                        data={engineResults.htmlcs}
                        scanner="HTML_CodeSniffer"
                        logo="/logo-html_codesniffer-mark.svg"
                        description="HTML_CodeSniffer is a client-side script that checks HTML source code and detects violations of a defined coding standard."
                        link="https://squizlabs.github.io/HTML_CodeSniffer/"
                      />
                    </TabPanel>
                  </Tabs>
                </div>
              )}

              <div className="share">
                <h3>
                  Share URL <span>Click and copy to your clipboard</span>
                </h3>
                <input
                  type="text"
                  readOnly
                  aria-label={`Share your results at ${shareUrl}`}
                  value={shareUrl}
                  onFocus={e => {
                    e.persist()
                    setTimeout(() => {
                      e.target.setSelectionRange(0, e.target.value.length)
                    }, 1)
                    trackCustomEvent({
                      category: "results",
                      action: "share",
                      label: e.target.value,
                    })
                  }}
                />
              </div>
            </>
          )}
        </div>
      </section>
    </Layout>
  )
}

export default Results

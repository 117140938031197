import waveRules from "../../data/wave-ref"
import axeRules from "../../data/axe-ref"
import audioeyeRules from "../../data/audioeye-ref"
import w3Rules from "../../data/w3-ref"
import techniques from "../../data/techniques-ref"
import _ from "lodash"

class ScanEngine {
  constructor(scanResults) {
    this.scanResults = scanResults

    this.audioeyeResults = this.scanResults.filter(
      s => s.scanner === "audioeye"
    )
    this.waveResults = this.scanResults.filter(s => s.scanner === "wave")
    this.pa11yResults = this.scanResults.filter(s => {
      return s.scanner === "pa11y"
    })

    this.axeResults = _.cloneDeep(this.pa11yResults)
    this.axeResults.forEach(r => {
      r.data.results.issues = r.data.results.issues.filter(
        i => i.runner === "axe"
      )
    })

    this.htmlcsResults = _.cloneDeep(this.pa11yResults)
    this.htmlcsResults.forEach(r => {
      r.data.results.issues = r.data.results.issues.filter(
        i => i.runner === "htmlcs"
      )
    })

    this.toolbarResults = this.scanResults.filter(s => s.scanner === "toolbar")

    this.waveRules = waveRules.rules
    this.axeRules = axeRules.rules
    this.audioeyeRules = audioeyeRules.lookups
    this.w3Rules = w3Rules.rules
    this.techniques = techniques.rules

    this.toolbar = this._hasToolbar(this.toolbarResults)
    this.audioeye = this._audioeye(this.audioeyeResults)
    this.axe = this._axe(this.axeResults)
    this.htmlcs = this._htmlcs(this.htmlcsResults)
    this.wave = this._wave(this.waveResults)
    this.total = this._total()
  }

  _audioeye(data) {
    const errors = data.map(url => {
      url.data.results.page_scan_elements.forEach(e => {
        e.scan_test_lookup = this.audioeyeRules[e.scan_result]
      })

      const errorItems = _.uniqBy(
        url.data.results.page_scan_elements.filter(
          e => !e.test_passed && e.classification === "Error"
        ),
        "scan_result"
      )

      errorItems.forEach(item => {
        const instances = _.compact(
          _.flatten(
            data.map(url => {
              return url.data.results.page_scan_elements.filter(
                e => e.scan_result === item.scan_result
              )
            })
          )
        )
        item.url = url.url
        item.id = item.scan_test_lookup.result_code
        item.title = item.scan_test_lookup.result_name
        item.description = item.scan_test_lookup.result_description
        item.context = item.element_reference
        item.contexts = _.uniq(instances.map(i => i.element_reference))
        item.wcag = this.w3Rules.filter(
          r => r.id === item.scan_test_lookup.wcag_success_criteria
        )
        item.count = url.data.results.page_scan_elements.filter(
          e =>
            !e.test_passed &&
            e.scan_result === item.scan_test_lookup.result_code
        ).length
        item.urls = _.compact(
          data.map(u => {
            const other = u.data.results.page_scan_elements.find(e => {
              return e.scan_result === item.id
            })

            if (other) {
              return u.url
            } else {
              return null
            }
          })
        )
      })

      return {
        url: url.url,
        items: errorItems,
      }
    })

    const allErrors = _.uniqBy(
      _.flatten(
        errors.map(error => {
          return error.items.map(item => {
            return item
          })
        })
      ),
      "id"
    )

    const successes = data.map(url => {
      return {
        url: url.url,
        items: _.uniqBy(
          url.data.results.page_scan_elements.filter(e => e.test_passed),
          "scan_test"
        ),
      }
    })

    return {
      score: Math.ceil(_.mean(data.map(r => r.data.results.ae_score))),
      allErrors: allErrors,
      errors: errors,
      successes: successes,
      tests: {
        total: _.sum(
          data.map(url => {
            return url.data.results.page_scan_elements.length
          })
        ),
        error: _.sum(allErrors.map(e => e.count)),
        success: _.sum(
          data.map(url => {
            return url.data.results.page_scan_elements.filter(
              e => e.test_passed
            ).length
          })
        ),
      },
    }
  }

  _axe(data) {
    const errors = data.map(url => {
      const errorItems = _.uniqBy(
        url.data.results.issues.filter(e => e.type === "error"),
        "code"
      )

      errorItems.forEach(item => {
        const instances = _.compact(
          _.flatten(
            data.map(url => {
              return url.data.results.issues.filter(i => i.code === item.code)
            })
          )
        )
        item.url = url.url
        item.details = this.axeRules.find(r => r.id === item.code)
        item.id = item.details.id
        item.title = item.details.details.title
        item.description = item.details.details.description
        item.contexts = _.uniq(instances.map(i => i.context))
        item.wcag = _.compact(
          item.details.details.wcagPrinciples.map(p => {
            return this.w3Rules.find(r => r.id === p)
          })
        )
        item.count = url.data.results.issues.filter(
          i => i.code === item.code
        ).length
        item.urls = _.compact(
          data.map(u => {
            const other = u.data.results.issues.find(e => {
              return e.code === item.id
            })

            if (other) {
              return u.url
            } else {
              return null
            }
          })
        )
      })

      return {
        url: url.url,
        items: errorItems,
      }
    })

    const allErrors = _.uniqBy(
      _.flatten(
        errors.map(error => {
          return error.items.map(item => {
            return item
          })
        })
      ),
      "id"
    )

    const successes = data.map(url => {
      return {
        url: url.url,
        items: _.filter(this.axeRules, r => {
          return !url.data.results.issues.find(i => i.code === r.id)
        }),
      }
    })

    const totalTests = _.sum(
      data.map(url => {
        return url.data.results.passCount
      })
    )

    return {
      errors: errors,
      allErrors: allErrors,
      successes: successes,
      tests: {
        total: totalTests,
        error: _.sum(
          allErrors.map(e => {
            return e.count
          })
        ),
        success: null,
      },
    }
  }

  _htmlcs(data) {
    const errors = data.map(url => {
      const errorItems = _.uniqBy(
        url.data.results.issues.filter(e => e.type === "error"),
        "code"
      )
      errorItems.forEach(item => {
        const matchingTechnique = _.find(this.techniques, t => {
          return _.flatten(
            item.code.split(".").map(s => s.split(","))
          ).includes(t.id)
        })
        const instances = _.compact(
          _.flatten(
            data.map(url => {
              return url.data.results.issues.filter(i => i.code === item.code)
            })
          )
        )
        item.url = url.url
        item.id = item.code
        item.title = matchingTechnique ? matchingTechnique.title : item.code
        item.description = item.message
        item.contexts = _.uniq(instances.map(i => i.context))
        item.wcag = this.w3Rules.filter(
          r => r.id === item.code.split(".")[3].split("_").join(".")
        )
        item.count = url.data.results.issues.filter(
          i => i.code === item.code
        ).length
        item.urls = _.compact(
          data.map(u => {
            const other = u.data.results.issues.find(e => {
              return e.code === item.id
            })

            if (other) {
              return u.url
            } else {
              return null
            }
          })
        )
      })

      return {
        url: url.url,
        items: errorItems,
      }
    })

    const allErrors = _.uniqBy(
      _.flatten(
        errors.map(error => {
          return error.items.map(item => {
            return item
          })
        })
      ),
      "id"
    )

    return {
      errors: errors,
      allErrors: allErrors,
      successes: [],
      tests: {
        total: Math.floor(this.axe.tests.total * 0.75),
        error: _.sum(allErrors.map(e => e.count)),
        success: null,
      },
    }
  }

  _wave(data) {
    if (!data.length) {
      return false
    }

    const allScores = data.map(url => {
      return (
        100 -
        ((url.data.results.categories.error.count +
          url.data.results.categories.contrast.count) /
          (url.data.results.statistics["totalelements"] -
            url.data.results.statistics["allitemcount"])) *
          100
      )
    })

    const errors = data.map(url => {
      const errorItems = [
        ...Object.values(url.data.results.categories.error.items),
        ...Object.values(url.data.results.categories.contrast.items),
      ]

      errorItems.forEach(item => {
        item.url = url.url
        item.details = this.waveRules.find(r => r.id === item.id)
        item.title = item.details.title
        item.description = item.details.description
        item.context = item.selectors?.length ? item.selectors[0] : null
        item.contexts = _.uniq(_.compact(item.selectors))
        item.wcag = item.details.wcagPrinciples.map(w => {
          return this.w3Rules.find(r => r.id === w.principle)
        })
        item.urls = _.compact(
          data.map(u => {
            const other = errorItems.find(e => {
              return e.id === item.id
            })

            if (other) {
              return u.url
            } else {
              return null
            }
          })
        )
      })

      return {
        url: url.url,
        items: errorItems,
      }
    })

    const allErrors = _.uniqBy(
      _.flatten(
        errors.map(error => {
          return error.items.map(item => {
            return item
          })
        })
      ),
      "id"
    )

    return {
      score: Math.floor(_.mean(allScores)),
      errors: errors,
      allErrors: allErrors,
      tests: {
        total: _.sum(
          data.map(url => {
            return url.data.results.statistics.totalelements
          })
        ),
        error: _.sum(allErrors.map(e => e.count)),
        success: null,
      },
    }
  }

  _hasToolbar(data) {
    return data.some(url => {
      return url.data.results.toolbar
    })
  }

  _total() {
    const score = () => {
      let score = Math.ceil(
        _.mean([
          this.audioeye.score,
          _.clamp(
            this.wave.score,
            this.audioeye.score - 10,
            this.audioeye.score + 10
          ),
        ])
      )
      if (score < 100 && this.toolbar.hasToolbar) {
        score = score + 5
      }

      if (errors() > 0) {
        score = _.clamp(score, 0, 99)
      }

      return _.clamp(score, 0, 100)
    }

    const errors = () => {
      return (
        this.audioeye.tests.error +
        this.axe.tests.error +
        this.htmlcs.tests.error +
        this.wave.tests.error
      )
    }

    return {
      score: score(),
      urls: _.uniq(
        this.scanResults.map(r => {
          return r.url
        })
      ).length,
      tests: Math.ceil(
        this.audioeye.tests.total +
          this.wave.tests.total +
          this.axe.tests.total +
          this.htmlcs.tests.total
      ),
      errors: errors(),
    }
  }

  results() {
    return {
      _scanResults: this.scanResults,
      total: this.total,
      urls: _.uniq(
        this.scanResults.map(r => {
          return r.url
        })
      ).sort((a, b) => a.length - b.length),
      audioeye: this.audioeye,
      axe: this.axe,
      htmlcs: this.htmlcs,
      wave: this.wave,
      toolbar: this.toolbar,
    }
  }
}

export default ScanEngine

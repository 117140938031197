export default { rules: [
  {
    "id": "1.1.1",
    "slug": "non-text-content",
    "title": "Non-text Content",
    "level": "A"
  },
  {
    "id": "1.2.1",
    "slug": "audio-only-and-video-only-prerecorded",
    "title": "Audio-only and Video-only (Prerecorded)",
    "level": "A"
  },
  {
    "id": "1.2.2",
    "slug": "captions-prerecorded",
    "title": "Captions (Prerecorded)",
    "level": "A"
  },
  {
    "id": "1.2.3",
    "slug": "audio-description-or-media-alternative-prerecorded",
    "title": "Audio Description or Media Alternative (Prerecorded)",
    "level": "A"
  },
  {
    "id": "1.2.4",
    "slug": "captions-live",
    "title": "Captions (Live)",
    "level": "AA"
  },
  {
    "id": "1.2.5",
    "slug": "audio-description-prerecorded",
    "title": "Audio Description (Prerecorded)",
    "level": "AA"
  },
  {
    "id": "1.2.6",
    "slug": "sign-language-prerecorded",
    "title": "Sign Language (Prerecorded)",
    "level": "AAA"
  },
  {
    "id": "1.2.7",
    "slug": "extended-audio-description-prerecorded",
    "title": "Extended Audio Description (Prerecorded)",
    "level": "AAA"
  },
  {
    "id": "1.2.8",
    "slug": "media-alternative-prerecorded",
    "title": "Media Alternative (Prerecorded)",
    "level": "AAA"
  },
  {
    "id": "1.2.9",
    "slug": "audio-only-live",
    "title": "Audio-only (Live)",
    "level": "AAA"
  },
  {
    "id": "1.3.1",
    "slug": "info-and-relationships",
    "title": "Info and Relationships",
    "level": "A"
  },
  {
    "id": "1.3.2",
    "slug": "meaningful-sequence",
    "title": "Meaningful Sequence",
    "level": "A"
  },
  {
    "id": "1.3.3",
    "slug": "sensory-characteristics",
    "title": "Sensory Characteristics",
    "level": "A"
  },
  {
    "id": "1.3.4",
    "slug": "orientation",
    "title": "Orientation",
    "level": "AA"
  },
  {
    "id": "1.3.5",
    "slug": "identify-input-purpose",
    "title": "Identify Input Purpose",
    "level": "AA"
  },
  {
    "id": "1.3.6",
    "slug": "identify-purpose",
    "title": "Identify Purpose",
    "level": "AAA"
  },
  {
    "id": "1.4.1",
    "slug": "use-of-color",
    "title": "Use of Color",
    "level": "A"
  },
  {
    "id": "1.4.2",
    "slug": "audio-control",
    "title": "Audio Control",
    "level": "A"
  },
  {
    "id": "1.4.3",
    "slug": "contrast-minimum",
    "title": "Contrast (Minimum)",
    "level": "AA"
  },
  {
    "id": "1.4.4",
    "slug": "resize-text",
    "title": "Resize text",
    "level": "AA"
  },
  {
    "id": "1.4.5",
    "slug": "images-of-text",
    "title": "Images of Text",
    "level": "AA"
  },
  {
    "id": "1.4.6",
    "slug": "contrast-enhanced",
    "title": "Contrast (Enhanced)",
    "level": "AAA"
  },
  {
    "id": "1.4.7",
    "slug": "low-or-no-background-audio",
    "title": "Low or No Background Audio",
    "level": "AAA"
  },
  {
    "id": "1.4.8",
    "slug": "visual-presentation",
    "title": "Visual Presentation",
    "level": "AAA"
  },
  {
    "id": "1.4.9",
    "slug": "images-of-text-no-exception",
    "title": "Images of Text (No Exception)",
    "level": "AAA"
  },
  {
    "id": "1.4.10",
    "slug": "reflow",
    "title": "Reflow",
    "level": "AA"
  },
  {
    "id": "1.4.11",
    "slug": "non-text-contrast",
    "title": "Non-text Contrast",
    "level": "AA"
  },
  {
    "id": "1.4.12",
    "slug": "text-spacing",
    "title": "Text Spacing",
    "level": "AA"
  },
  {
    "id": "1.4.13",
    "slug": "content-on-hover-or-focus",
    "title": "Content on Hover or Focus",
    "level": "AA"
  },
  {
    "id": "2.1.1",
    "slug": "keyboard",
    "title": "Keyboard",
    "level": "A"
  },
  {
    "id": "2.1.2",
    "slug": "no-keyboard-trap",
    "title": "No Keyboard Trap",
    "level": "A"
  },
  {
    "id": "2.1.3",
    "slug": "keyboard-no-exception",
    "title": "Keyboard (No Exception)",
    "level": "AAA"
  },
  {
    "id": "2.1.4",
    "slug": "character-key-shortcuts",
    "title": "Character Key Shortcuts",
    "level": "A"
  },
  {
    "id": "2.2.1",
    "slug": "timing-adjustable",
    "title": "Timing Adjustable",
    "level": "A"
  },
  {
    "id": "2.2.2",
    "slug": "pause-stop-hide",
    "title": "Pause, Stop, Hide",
    "level": "A"
  },
  {
    "id": "2.2.3",
    "slug": "no-timing",
    "title": "No Timing",
    "level": "AAA"
  },
  {
    "id": "2.2.4",
    "slug": "interruptions",
    "title": "Interruptions",
    "level": "AAA"
  },
  {
    "id": "2.2.5",
    "slug": "re-authenticating",
    "title": "Re-authenticating",
    "level": "AAA"
  },
  {
    "id": "2.2.6",
    "slug": "timeouts",
    "title": "Timeouts",
    "level": "AAA"
  },
  {
    "id": "2.3.1",
    "slug": "three-flashes-or-below-threshold",
    "title": "Three Flashes or Below Threshold",
    "level": "A"
  },
  {
    "id": "2.3.2",
    "slug": "three-flashes",
    "title": "Three Flashes",
    "level": "AAA"
  },
  {
    "id": "2.3.3",
    "slug": "animation-from-interactions",
    "title": "Animation from Interactions",
    "level": "AAA"
  },
  {
    "id": "2.4.1",
    "slug": "bypass-blocks",
    "title": "Bypass Blocks",
    "level": "A"
  },
  {
    "id": "2.4.2",
    "slug": "page-titled",
    "title": "Page Titled",
    "level": "A"
  },
  {
    "id": "2.4.3",
    "slug": "focus-order",
    "title": "Focus Order",
    "level": "A"
  },
  {
    "id": "2.4.4",
    "slug": "link-purpose-in-context",
    "title": "Link Purpose (In Context)",
    "level": "A"
  },
  {
    "id": "2.4.5",
    "slug": "multiple-ways",
    "title": "Multiple Ways",
    "level": "AA"
  },
  {
    "id": "2.4.6",
    "slug": "headings-and-labels",
    "title": "Headings and Labels",
    "level": "AA"
  },
  {
    "id": "2.4.7",
    "slug": "focus-visible",
    "title": "Focus Visible",
    "level": "AA"
  },
  {
    "id": "2.4.8",
    "slug": "location",
    "title": "Location",
    "level": "AAA"
  },
  {
    "id": "2.4.9",
    "slug": "link-purpose-link-only",
    "title": "Link Purpose (Link Only)",
    "level": "AAA"
  },
  {
    "id": "2.4.10",
    "slug": "section-headings",
    "title": "Section Headings",
    "level": "AAA"
  },
  {
    "id": "2.5.1",
    "slug": "pointer-gestures",
    "title": "Pointer Gestures",
    "level": "A"
  },
  {
    "id": "2.5.2",
    "slug": "pointer-cancellation",
    "title": "Pointer Cancellation",
    "level": "A"
  },
  {
    "id": "2.5.3",
    "slug": "label-in-name",
    "title": "Label in Name",
    "level": "A"
  },
  {
    "id": "2.5.4",
    "slug": "motion-actuation",
    "title": "Motion Actuation",
    "level": "A"
  },
  {
    "id": "2.5.5",
    "slug": "target-size",
    "title": "Target Size",
    "level": "AAA"
  },
  {
    "id": "2.5.6",
    "slug": "concurrent-input-mechanisms",
    "title": "Concurrent Input Mechanisms",
    "level": "AAA"
  },
  {
    "id": "3.1.1",
    "slug": "language-of-page",
    "title": "Language of Page",
    "level": "A"
  },
  {
    "id": "3.1.2",
    "slug": "language-of-parts",
    "title": "Language of Parts",
    "level": "AA"
  },
  {
    "id": "3.1.3",
    "slug": "unusual-words",
    "title": "Unusual Words",
    "level": "AAA"
  },
  {
    "id": "3.1.4",
    "slug": "abbreviations",
    "title": "Abbreviations",
    "level": "AAA"
  },
  {
    "id": "3.1.5",
    "slug": "reading-level",
    "title": "Reading Level",
    "level": "AAA"
  },
  {
    "id": "3.1.6",
    "slug": "pronunciation",
    "title": "Pronunciation",
    "level": "AAA"
  },
  {
    "id": "3.2.1",
    "slug": "on-focus",
    "title": "On Focus",
    "level": "A"
  },
  {
    "id": "3.2.2",
    "slug": "on-input",
    "title": "On Input",
    "level": "A"
  },
  {
    "id": "3.2.3",
    "slug": "consistent-navigation",
    "title": "Consistent Navigation",
    "level": "AA"
  },
  {
    "id": "3.2.4",
    "slug": "consistent-identification",
    "title": "Consistent Identification",
    "level": "AA"
  },
  {
    "id": "3.2.5",
    "slug": "change-on-request",
    "title": "Change on Request",
    "level": "AAA"
  },
  {
    "id": "3.3.1",
    "slug": "error-identification",
    "title": "Error Identification",
    "level": "A"
  },
  {
    "id": "3.3.2",
    "slug": "labels-or-instructions",
    "title": "Labels or Instructions",
    "level": "A"
  },
  {
    "id": "3.3.3",
    "slug": "error-suggestion",
    "title": "Error Suggestion",
    "level": "AA"
  },
  {
    "id": "3.3.4",
    "slug": "error-prevention-legal-financial-data",
    "title": "Error Prevention (Legal, Financial, Data)",
    "level": "AA"
  },
  {
    "id": "3.3.5",
    "slug": "help",
    "title": "Help",
    "level": "AAA"
  },
  {
    "id": "3.3.6",
    "slug": "error-prevention-all",
    "title": "Error Prevention (All)",
    "level": "AAA"
  },
  {
    "id": "4.1.1",
    "slug": "parsing",
    "title": "Parsing",
    "level": "A"
  },
  {
    "id": "4.1.2",
    "slug": "name-role-value",
    "title": "Name, Role, Value",
    "level": "A"
  },
  {
    "id": "4.1.3",
    "slug": "status-messages",
    "title": "Status Messages",
    "level": "AA"
  }
] }
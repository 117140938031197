export default { rules: [
  {
    "id": "ARIA13",
    "title": "Using aria-labelledby to name regions and landmarks"
  },
  {
    "id": "ARIA10",
    "title": "Using aria-labelledby to provide a text alternative for non-text content"
  },
  {
    "id": "ARIA16",
    "title": "Using aria-labelledby to provide a name for user interface controls"
  },
  {
    "id": "SCR22",
    "title": "Using scripts to control blinking and stop it in five seconds or less"
  },
  {
    "id": "SCR1",
    "title": "Allowing the user to extend the default time limit"
  },
  {
    "id": "C12",
    "title": "Using percent for font sizes"
  },
  {
    "id": "SCR19",
    "title": "Using an onchange event on a select element without causing a change of context"
  },
  {
    "id": "C6",
    "title": "Positioning content based on structural markup"
  },
  {
    "id": "ARIA19",
    "title": "Using ARIA role=alert or Live Regions to Identify Errors"
  },
  {
    "id": "ARIA14",
    "title": "Using aria-label to provide an invisible label where a visible label cannot be used"
  },
  {
    "id": "F1",
    "title": "Failure of Success Criterion 1.3.2 due to changing the meaning of content by positioning information with CSS"
  },
  {
    "id": "C17",
    "title": "Scaling form elements which contain text"
  },
  {
    "id": "SCR26",
    "title": "Inserting dynamic content into the Document Object Model immediately following its trigger element"
  },
  {
    "id": "SCR27",
    "title": "Reordering page sections using the Document Object Model"
  },
  {
    "id": "ARIA5",
    "title": "Using WAI-ARIA state and property attributes to expose the state of a user interface component"
  },
  {
    "id": "C23",
    "title": "Specifying text and background colors of secondary content such as banners features and navigation in CSS while not specifying text and background colors of the main content"
  },
  {
    "id": "C8",
    "title": "Using CSS letter-spacing to control spacing within a word"
  },
  {
    "id": "SCR20",
    "title": "Using both keyboard and other device-specific functions"
  },
  {
    "id": "ARIA18",
    "title": "Using aria-alertdialog to Identify Errors"
  },
  {
    "id": "ARIA22",
    "title": "Using role=status to present status messages"
  },
  {
    "id": "SCR33",
    "title": "Using script to scroll content and providing a mechanism to pause it"
  },
  {
    "id": "C33",
    "title": "Allowing for Reflow with Long URLs and Strings of Text"
  },
  {
    "id": "C13",
    "title": "Using named font sizes"
  },
  {
    "id": "F10",
    "title": "Failure of Success Criterion 2.1.2 and Conformance Requirement 5 due to combining multiple content formats in a way that traps users inside one format type"
  },
  {
    "id": "SCR30",
    "title": "Using scripts to change the link text"
  },
  {
    "id": "C9",
    "title": "Using CSS to include decorative images"
  },
  {
    "id": "C34",
    "title": "Using media queries to un-fixing sticky headers / footers"
  },
  {
    "id": "F2",
    "title": "Failure of Success Criterion 1.3.1 due to using changes in text presentation to convey information without using the appropriate markup or text"
  },
  {
    "id": "C18",
    "title": "Using CSS margin and padding rules instead of spacer images for layout design"
  },
  {
    "id": "C20",
    "title": "Using relative measurements to set column widths so that lines can average 80 characters or less when the browser is resized"
  },
  {
    "id": "C40",
    "title": "Creating a two-color focus indicator to ensure sufficient contrast with all components"
  },
  {
    "id": "F4",
    "title": "Failure of Success Criterion 2.2.2 due to using text-decoration:blink without a mechanism to stop it in less than five seconds"
  },
  {
    "id": "C28",
    "title": "Specifying the size of text containers using em units"
  },
  {
    "id": "SCR29",
    "title": "Adding keyboard-accessible actions to static HTML elements"
  },
  {
    "id": "C27",
    "title": "Making the DOM order match the visual order"
  },
  {
    "id": "SCR28",
    "title": "Using an expandable and collapsible menu to bypass block of content"
  },
  {
    "id": "SCR24",
    "title": "Using progressive enhancement to open new windows on user request"
  },
  {
    "id": "ARIA4",
    "title": "Using a WAI-ARIA role to expose the role of a user interface component"
  },
  {
    "id": "ARIA8",
    "title": "Using aria-label for link purpose"
  },
  {
    "id": "C25",
    "title": "Specifying borders and layout in CSS to delineate areas of a Web page while not specifying text and text-background colors"
  },
  {
    "id": "C41",
    "title": "Creating a focus indicator within the component"
  },
  {
    "id": "C19",
    "title": "Specifying alignment either to the left OR right in CSS"
  },
  {
    "id": "C35",
    "title": "Allowing for text spacing without wrapping"
  },
  {
    "id": "F22",
    "title": "Failure of Success Criterion 3.2.5 due to opening windows that are not requested by the user"
  },
  {
    "id": "ARIA6",
    "title": "Using aria-label to provide labels for objects"
  },
  {
    "id": "F7",
    "title": "Failure of Success Criterion 2.2.2 due to an object or applet such as Java or Flash  that has blinking content without a mechanism to pause the content that blinks for more than five seconds"
  },
  {
    "id": "C14",
    "title": "Using em units for font sizes"
  },
  {
    "id": "SCR14",
    "title": "Using scripts to make nonessential alerts optional"
  },
  {
    "id": "C30",
    "title": "Using CSS to replace text with images of text and providing user interface controls to switch"
  },
  {
    "id": "SCR16",
    "title": "Providing a script that warns the user a time limit is about to expire"
  },
  {
    "id": "SCR2",
    "title": "Using redundant keyboard and mouse event handlers"
  },
  {
    "id": "F26",
    "title": "Failure of Success Criterion 1.3.3 due to using a graphical symbol alone to convey information"
  },
  {
    "id": "C15",
    "title": "Using CSS to change the presentation of a user interface component when it receives focus"
  },
  {
    "id": "ARIA23",
    "title": "Using role=log to identify sequential information updates"
  },
  {
    "id": "ARIA17",
    "title": "Using grouping roles to identify related form controls"
  },
  {
    "id": "SCR37",
    "title": "Creating Custom Dialogs in a Device Independent Way"
  },
  {
    "id": "C21",
    "title": "Specifying line spacing in CSS"
  },
  {
    "id": "SCR36",
    "title": "Providing a mechanism to allow users to display moving scrolling or auto-updating text in a static window or area"
  },
  {
    "id": "F13",
    "title": "Failure of Success Criterion 1.1.1 and 1.4.1 due to having a text alternative that does not include information that is conveyed by color differences in the image"
  },
  {
    "id": "F12",
    "title": "Failure of Success Criterion 2.2.5 due to having a session time limit without a mechanism for saving user's input and re-establishing that information upon re-authentication"
  },
  {
    "id": "F36",
    "title": "Failure of Success Criterion 3.2.2 due to automatically submitting a form and presenting new content without prior warning when the last field in the form is given a value"
  },
  {
    "id": "F73",
    "title": "Failure of Success Criterion 1.4.1 due to creating links that are not visually evident without color vision"
  },
  {
    "id": "F87",
    "title": "Failure of Success Criterion 1.3.1 due to inserting non-decorative content by using :before and :after pseudo-elements and the 'content' property in CSS"
  },
  {
    "id": "C37",
    "title": "Using CSS max-width and height to fit images"
  },
  {
    "id": "F71",
    "title": "Failure of Success Criterion 1.1.1 due to using text look-alikes to represent text without providing a text alternative"
  },
  {
    "id": "SCR18",
    "title": "Providing client-side validation and alert"
  },
  {
    "id": "C32",
    "title": "Using media queries and grid CSS to reflow columns"
  },
  {
    "id": "ARIA12",
    "title": "Using role=heading to identify headings"
  },
  {
    "id": "F95",
    "title": "Failure of Success Criterion 1.4.13 due to content shown on hover not being hoverable"
  },
  {
    "id": "FLASH22",
    "title": "Adding keyboard-accessible actions to static elements"
  },
  {
    "id": "FLASH16",
    "title": "Making actions keyboard accessible by using the click event on standard components"
  },
  {
    "id": "G59",
    "title": "Placing the interactive elements in an order that follows sequences and relationships within the content"
  },
  {
    "id": "G54",
    "title": "Including a sign language interpreter in the video stream"
  },
  {
    "id": "ARIA2",
    "title": "Identifying a required field with the aria-required property"
  },
  {
    "id": "G15",
    "title": "Using a tool to ensure that content does not violate the general flash threshold or red flash threshold"
  },
  {
    "id": "G69",
    "title": "Providing an alternative for time based media"
  },
  {
    "id": "G71",
    "title": "Providing a help link on every Web page"
  },
  {
    "id": "G89",
    "title": "Providing expected data format and example"
  },
  {
    "id": "G127",
    "title": "Identifying a Web page's relationship to a larger collection of Web pages"
  },
  {
    "id": "FLASH3",
    "title": "Marking objects in Flash so that they can be ignored by AT"
  },
  {
    "id": "ARIA24",
    "title": "Semantically identifying a font icon with role=\"img\""
  },
  {
    "id": "G180",
    "title": "Providing the user with a means to set the time limit to 10 times the default time limit"
  },
  {
    "id": "PDF18",
    "title": "Specifying the document title using the Title entry in the document information dictionary of a PDF document"
  },
  {
    "id": "G133",
    "title": "Providing a checkbox on the first page of a multipart form that allows users to ask for longer session time limit or no session time limit"
  },
  {
    "id": "FLASH12",
    "title": "Providing client-side validation and adding error text via the accessible description"
  },
  {
    "id": "G163",
    "title": "Using standard diacritical marks that can be turned off"
  },
  {
    "id": "FLASH32",
    "title": "Using auto labeling to associate text labels with form controls"
  },
  {
    "id": "G73",
    "title": "Providing a long description in another location with a link to it that is immediately adjacent to the non-text content"
  },
  {
    "id": "G68",
    "title": "Providing a short text alternative that describes the purpose of live audio-only and live video-only content"
  },
  {
    "id": "G60",
    "title": "Playing a sound that turns off automatically within three seconds"
  },
  {
    "id": "G64",
    "title": "Providing a Table of Contents"
  },
  {
    "id": "G87",
    "title": "Providing closed captions"
  },
  {
    "id": "G90",
    "title": "Providing keyboard-triggered event handlers"
  },
  {
    "id": "G88",
    "title": "Providing descriptive titles for Web pages"
  },
  {
    "id": "G196",
    "title": "Using a text alternative on one item within a group of images that describes all items in the group"
  },
  {
    "id": "G108",
    "title": "Using markup features to expose the name and role allow user-settable properties to be directly set and provide notification of changes"
  },
  {
    "id": "G142",
    "title": "Using a technology that has commonly-available user agents that support zoom"
  },
  {
    "id": "G58",
    "title": "Placing a link to the alternative for time-based media immediately next to the non-text content"
  },
  {
    "id": "G107",
    "title": "Using \"activate\" rather than \"focus\" as a trigger for changes of context"
  },
  {
    "id": "H48",
    "title": "Using ol ul and dl for lists or groups of links"
  },
  {
    "id": "H85",
    "title": "Using OPTGROUP to group OPTION elements inside a SELECT"
  },
  {
    "id": "G5",
    "title": "Allowing users to complete an activity without any time limit"
  },
  {
    "id": "G138",
    "title": "Using semantic markup whenever color cues are used"
  },
  {
    "id": "G128",
    "title": "Indicating current location within navigation bars"
  },
  {
    "id": "G92",
    "title": "Providing long description for non-text content that serves the same purpose and presents the same information"
  },
  {
    "id": "G95",
    "title": "Providing short text alternatives that provide a brief description of the non-text content"
  },
  {
    "id": "H35",
    "title": "Providing text alternatives on applet elements"
  },
  {
    "id": "F15",
    "title": "Failure of Success Criterion 4.1.2 due to implementing custom controls that do not use an accessibility API for the technology or do so incompletely"
  },
  {
    "id": "F44",
    "title": "Failure of Success Criterion 2.4.3 due to using tabindex to create a tab order that does not preserve meaning and operability"
  },
  {
    "id": "G21",
    "title": "Ensuring that users are not trapped in content"
  },
  {
    "id": "F14",
    "title": "Failure of Success Criterion 1.3.3 due to identifying content only by its shape or location"
  },
  {
    "id": "F72",
    "title": "Failure of Success Criterion 1.1.1 due to using ASCII art without providing a text alternative"
  },
  {
    "id": "G136",
    "title": "Providing a link at the beginning of a nonconforming Web page that points to a conforming alternate version"
  },
  {
    "id": "F33",
    "title": "Failure of Success Criterion 1.3.1 and 1.3.2 due to using white space characters to create multiple columns in plain text content"
  },
  {
    "id": "G121",
    "title": "Linking to pronunciations"
  },
  {
    "id": "G101",
    "title": "Providing the definition of a word or phrase used in an unusual or restricted way"
  },
  {
    "id": "G155",
    "title": "Providing a checkbox in addition to a submit button"
  },
  {
    "id": "H54",
    "title": "Using the dfn element to identify the defining instance of a word"
  },
  {
    "id": "H2",
    "title": "Combining adjacent image and text links for the same resource"
  },
  {
    "id": "F78",
    "title": "Failure of Success Criterion 2.4.7 due to styling element outlines and borders in a way that removes or renders non-visible the visual focus indicator"
  },
  {
    "id": "G86",
    "title": "Providing a text summary that can be understood by people with lower secondary education level reading ability"
  },
  {
    "id": "G105",
    "title": "Saving data so that it can be used after a user re-authenticates"
  },
  {
    "id": "G103",
    "title": "Providing visual illustrations pictures and symbols to help explain ideas events  and processes"
  },
  {
    "id": "G112",
    "title": "Using inline definitions"
  },
  {
    "id": "G63",
    "title": "Providing a site map"
  },
  {
    "id": "G140",
    "title": "Separating information and structure from presentation to enable different presentations"
  },
  {
    "id": "G188",
    "title": "Providing a button on the page to increase line spaces and paragraph spaces"
  },
  {
    "id": "G179",
    "title": "Ensuring that there is no loss of content or functionality when the text resizes and text containers do not change their width"
  },
  {
    "id": "G126",
    "title": "Providing a list of links to all other Web pages"
  },
  {
    "id": "G146",
    "title": "Using liquid layout"
  },
  {
    "id": "F105",
    "title": "Failure of Success Criterion 2.5.1 due to providing functionality via a path-based gesture without simple pointer alternative"
  },
  {
    "id": "H39",
    "title": "Using caption elements to associate data table captions with data tables"
  },
  {
    "id": "H98",
    "title": "Using HTML 5.2 autocomplete attributes"
  },
  {
    "id": "SM12",
    "title": "Providing captions through synchronized text streams in SMIL 2.0"
  },
  {
    "id": "G100",
    "title": "Providing a short text alternative which is the accepted name or a descriptive name of the non-text content"
  },
  {
    "id": "F3",
    "title": "Failure of Success Criterion 1.1.1 due to using CSS to include images that convey important information"
  },
  {
    "id": "F52",
    "title": "Failure of Success Criterion 3.2.1 and 3.2.5 due to opening a new window as soon as a new page is loaded"
  },
  {
    "id": "G201",
    "title": "Giving users advanced warning when opening a new window"
  },
  {
    "id": "F19",
    "title": "Failure of Conformance Requirement 1 due to not providing a method for the user to find the alternative conforming version of a non-conforming Web page"
  },
  {
    "id": "F59",
    "title": "Failure of Success Criterion 4.1.2 due to using script to make div or span a user interface control in HTML without providing a role for the control"
  },
  {
    "id": "FLASH26",
    "title": "Applying audio descriptions to Flash video"
  },
  {
    "id": "FLASH36",
    "title": "Using scripts to control blinking and stop it in five seconds or less"
  },
  {
    "id": "FLASH18",
    "title": "Providing a control to turn off sounds that play automatically in Flash"
  },
  {
    "id": "F100",
    "title": "Failure of Success Criterion 1.3.4 due to showing a message asking to reorient device"
  },
  {
    "id": "F63",
    "title": "Failure of Success Criterion 2.4.4 due to providing link context only in content that is not related to the link"
  },
  {
    "id": "F104",
    "title": "Failure of Success Criterion 1.4.12 due to clipped or overlapped content when text spacing is adjusted"
  },
  {
    "id": "G10",
    "title": "Creating components using a technology that supports the accessibility API features of the platforms on which the user agents will be run to expose the names and roles allow user-settable properties to be directly set and provide notification of changes"
  },
  {
    "id": "ARIA1",
    "title": "Using the aria-describedby property to provide a descriptive label for user interface controls"
  },
  {
    "id": "F41",
    "title": "Failure of Success Criterion 2.2.1 2.2.4 and 3.2.5 due to using meta refresh to reload the page"
  },
  {
    "id": "C29",
    "title": "Using a style switcher to provide a conforming alternate version"
  },
  {
    "id": "G19",
    "title": "Ensuring that no component of the content flashes more than three times in any 1-second period"
  },
  {
    "id": "F83",
    "title": "Failure of Success Criterion 1.4.3 and 1.4.6 due to using background images that do not provide sufficient contrast with foreground text (or images of text)"
  },
  {
    "id": "FLASH23",
    "title": "Adding summary information to a DataGrid"
  },
  {
    "id": "G8",
    "title": "Providing a movie with extended audio descriptions"
  },
  {
    "id": "G13",
    "title": "Describing what will happen before a change to a form control that causes a change of context to occur is made"
  },
  {
    "id": "H30",
    "title": "Providing link text that describes the purpose of a link for anchor elements"
  },
  {
    "id": "FLASH13",
    "title": "Using HTML language attributes to specify language in Flash content"
  },
  {
    "id": "FLASH34",
    "title": "Turning off sounds that play automatically when an assistive technology is detected"
  },
  {
    "id": "G14",
    "title": "Ensuring that information conveyed by color differences is also available in text"
  },
  {
    "id": "FLASH29",
    "title": "Setting the label property for form components"
  },
  {
    "id": "SCR21",
    "title": "Using functions of the Document Object Model (DOM) to add content to a page"
  },
  {
    "id": "PDF16",
    "title": "Setting the default language using the /Lang entry in the document catalog of a PDF document"
  },
  {
    "id": "G85",
    "title": "Providing a text description when user input falls outside the required format or values"
  },
  {
    "id": "G182",
    "title": "Ensuring that additional visual cues are available when text color differences are used to convey information"
  },
  {
    "id": "G79",
    "title": "Providing a spoken version of the text"
  },
  {
    "id": "G75",
    "title": "Providing a mechanism to postpone any updating of content"
  },
  {
    "id": "F99",
    "title": "Failure of Success Criterion 2.1.4 due to implementing character key shortcuts that cannot be turned off or remapped"
  },
  {
    "id": "FLASH27",
    "title": "Providing button labels that describe the purpose of a button"
  },
  {
    "id": "G74",
    "title": "Providing a long description in text near the non-text content with a reference to the location of the long description in the short description"
  },
  {
    "id": "FLASH7",
    "title": "Using scripting to change control labels"
  },
  {
    "id": "G11",
    "title": "Creating content that blinks for less than 5 seconds"
  },
  {
    "id": "F80",
    "title": "Failure of Success Criterion 1.4.4 when text-based form controls do not resize when visually rendered text is resized up to 200%"
  },
  {
    "id": "G190",
    "title": "Providing a link adjacent to or associated with a non-conforming object that links to a conforming alternate version"
  },
  {
    "id": "G213",
    "title": "Provide conventional controls and an application setting for motion activated input"
  },
  {
    "id": "F92",
    "title": "Failure of Success Criterion 1.3.1 due to the use of role presentation on content which conveys semantic information"
  },
  {
    "id": "F82",
    "title": "Failure of Success Criterion 3.3.2 by visually formatting a set of phone number fields but not including a text label"
  },
  {
    "id": "G115",
    "title": "Using semantic elements to mark up structure"
  },
  {
    "id": "G175",
    "title": "Providing a multi color selection tool on the page for foreground and background colors"
  },
  {
    "id": "G70",
    "title": "Providing a function to search an online dictionary"
  },
  {
    "id": "G185",
    "title": "Linking to all of the pages on the site from the home page"
  },
  {
    "id": "G178",
    "title": "Providing controls on the Web page that allow users to incrementally change the size of all text on the page up to 200 percent"
  },
  {
    "id": "G165",
    "title": "Using the default focus indicator for the platform so that high visibility default focus indicators will carry over"
  },
  {
    "id": "G62",
    "title": "Providing a glossary"
  },
  {
    "id": "H36",
    "title": "Using alt attributes on images used as submit buttons"
  },
  {
    "id": "H62",
    "title": "Using the ruby element"
  },
  {
    "id": "SL22",
    "title": "Supporting Browser Zoom in Silverlight"
  },
  {
    "id": "G170",
    "title": "Providing a control near the beginning of the Web page that turns off sounds that play automatically"
  },
  {
    "id": "G200",
    "title": "Opening new windows and tabs from a link only when necessary"
  },
  {
    "id": "FLASH20",
    "title": "Reskinning Flash components to provide highly visible focus indication"
  },
  {
    "id": "G189",
    "title": "Providing a control near the beginning of the Web page that changes the link text"
  },
  {
    "id": "C36",
    "title": "Allowing for text spacing override"
  },
  {
    "id": "F70",
    "title": "Failure of Success Criterion 4.1.1 due to incorrect use of start and end tags or attribute markup"
  },
  {
    "id": "G167",
    "title": "Using an adjacent button to label the purpose of a field"
  },
  {
    "id": "SL12",
    "title": "Pausing Stopping or Playing Media in Silverlight MediaElements"
  },
  {
    "id": "G17",
    "title": "Ensuring that a contrast ratio of at least 7:1 exists between text (and images of text) and background behind the text"
  },
  {
    "id": "G65",
    "title": "Providing a breadcrumb trail"
  },
  {
    "id": "FLASH25",
    "title": "Labeling a form control by setting its accessible name"
  },
  {
    "id": "G18",
    "title": "Ensuring that a contrast ratio of at least 4.5:1 exists between text (and images of text)  and background behind the text"
  },
  {
    "id": "G120",
    "title": "Providing the pronunciation immediately following the word"
  },
  {
    "id": "H67",
    "title": "Using null alt text and no title attribute on img elements for images that AT should ignore"
  },
  {
    "id": "H34",
    "title": "Using a Unicode right-to-left mark (RLM) or left-to-right mark (LRM) to mix text direction inline"
  },
  {
    "id": "G181",
    "title": "Encoding user data as hidden or encrypted data in a re-authorization page"
  },
  {
    "id": "G208",
    "title": "Including the text of the visible label as part of the accessible name"
  },
  {
    "id": "FLASH8",
    "title": "Adding a group name to the accessible name of a form control"
  },
  {
    "id": "H97",
    "title": "Grouping related links using the nav element"
  },
  {
    "id": "H45",
    "title": "Using longdesc"
  },
  {
    "id": "H95",
    "title": "Using the track element to provide captions"
  },
  {
    "id": "H32",
    "title": "Providing submit buttons"
  },
  {
    "id": "G197",
    "title": "Using labels names and text alternatives consistently for content that has the same functionality"
  },
  {
    "id": "G153",
    "title": "Making the text easier to read"
  },
  {
    "id": "G134",
    "title": "Validating Web pages"
  },
  {
    "id": "G173",
    "title": "Providing a version of a movie with audio descriptions"
  },
  {
    "id": "FLASH21",
    "title": "Using the DataGrid component to associate column headers with cells"
  },
  {
    "id": "SCR39",
    "title": "Making content on focus or hover hoverable dismissible and persistent"
  },
  {
    "id": "C7",
    "title": "Using CSS to hide a portion of the link text"
  },
  {
    "id": "C31",
    "title": "Using CSS Flexbox to reflow content"
  },
  {
    "id": "SCR31",
    "title": "Using script to change the background color or border of the element with focus"
  },
  {
    "id": "H24",
    "title": "Providing text alternatives for the area elements of image maps"
  },
  {
    "id": "G187",
    "title": "Using a technology to include blinking content that can be turned off via the user agent"
  },
  {
    "id": "F74",
    "title": "Failure of Success Criterion 1.2.2 and 1.2.8 due to not labeling a synchronized media alternative to text as an alternative"
  },
  {
    "id": "H51",
    "title": "Using table markup to present tabular information"
  },
  {
    "id": "PDF15",
    "title": "Providing submit buttons with the submit-form action in PDF forms"
  },
  {
    "id": "SL16",
    "title": "Providing Script-Embedded Text Captions for MediaElement Content"
  },
  {
    "id": "G194",
    "title": "Providing spell checking and suggestions for text input"
  },
  {
    "id": "SL17",
    "title": "Providing Static Alternative Content for Silverlight Media Playing in a MediaElement"
  },
  {
    "id": "SL15",
    "title": "Providing Keyboard Shortcuts that Work Across the Entire Silverlight Application"
  },
  {
    "id": "SVR3",
    "title": "Using HTTP referer to ensure that the only way to access non-conforming content is from conforming content"
  },
  {
    "id": "SL3",
    "title": "Controlling Silverlight MediaElement Audio Volume"
  },
  {
    "id": "F86",
    "title": "Failure of Success Criterion 4.1.2 due to not providing names for each part of a multi-part form field such as a US telephone number"
  },
  {
    "id": "SCR35",
    "title": "Making actions keyboard accessible by using the onclick event of anchors and buttons"
  },
  {
    "id": "ARIA21",
    "title": "Using Aria-Invalid to Indicate An Error Field"
  },
  {
    "id": "C38",
    "title": "Using CSS width max-width and flexbox to fit labels and inputs"
  },
  {
    "id": "F54",
    "title": "Failure of Success Criterion 2.1.1 due to using only pointing-device-specific event handlers (including gesture) for a function"
  },
  {
    "id": "F50",
    "title": "Failure of Success Criterion 2.2.2 due to a script that causes a blink effect without a mechanism to stop the blinking at 5 seconds or less"
  },
  {
    "id": "F48",
    "title": "Failure of Success Criterion 1.3.1 due to using the pre element to markup tabular information"
  },
  {
    "id": "ARIA9",
    "title": "Using aria-labelledby to concatenate a label from several text nodes"
  },
  {
    "id": "FLASH6",
    "title": "Creating accessible hotspots using invisible buttons"
  },
  {
    "id": "SCR32",
    "title": "Providing client-side validation and adding error text via the DOM"
  },
  {
    "id": "G56",
    "title": "Mixing audio files so that non-speech sounds are at least 20 decibels lower than the speech audio content"
  },
  {
    "id": "F103",
    "title": "Failure of Success Criterion 4.1.3 due to providing status messages that cannot be programmatically determined through role or properties"
  },
  {
    "id": "F101",
    "title": "Failure of Success Criterion 2.5.2 due to activating a control on the down-event"
  },
  {
    "id": "FLASH24",
    "title": "Allowing the user to extend the default time limit"
  },
  {
    "id": "FLASH4",
    "title": "Providing submit buttons in Flash"
  },
  {
    "id": "SL19",
    "title": "Providing User Instructions With AutomationProperties.HelpText in Silverlight"
  },
  {
    "id": "SL4",
    "title": "Declaring Discrete Silverlight Objects to Specify Language Parts in the HTML DOM"
  },
  {
    "id": "SL33",
    "title": "Using Well-Formed XAML to Define a Silverlight User Interface"
  },
  {
    "id": "F24",
    "title": "Failure of Success Criterion 1.4.3 1.4.6 and 1.4.8 due to specifying foreground colors without specifying background colors or vice versa"
  },
  {
    "id": "H44",
    "title": "Using label elements to associate text labels with form controls"
  },
  {
    "id": "G164",
    "title": "Providing a stated time within which an online request (or transaction) may be amended or canceled by the user after making the request"
  },
  {
    "id": "G53",
    "title": "Identifying the purpose of a link using link text combined with the text of the enclosing sentence"
  },
  {
    "id": "FLASH33",
    "title": "Using relative values for Flash object dimensions"
  },
  {
    "id": "G159",
    "title": "Providing an alternative for time-based media for video-only content"
  },
  {
    "id": "H33",
    "title": "Supplementing link text with the title attribute"
  },
  {
    "id": "G97",
    "title": "Providing the first use of an abbreviation immediately before or after the expanded form"
  },
  {
    "id": "H88",
    "title": "Using HTML according to spec"
  },
  {
    "id": "SVR4",
    "title": "Allowing users to provide preferences for the display of conforming alternate versions"
  },
  {
    "id": "H42",
    "title": "Using h1-h6 to identify headings"
  },
  {
    "id": "G143",
    "title": "Providing a text alternative that describes the purpose of the CAPTCHA"
  },
  {
    "id": "G117",
    "title": "Using text to convey information that is conveyed by variations in presentation of text"
  },
  {
    "id": "H60",
    "title": "Using the link element to link to a glossary"
  },
  {
    "id": "G55",
    "title": "Linking to definitions"
  },
  {
    "id": "H76",
    "title": "Using meta refresh to create an instant client-side redirect"
  },
  {
    "id": "SVR5",
    "title": "Specifying the default language in the HTTP header"
  },
  {
    "id": "G184",
    "title": "Providing text instructions at the beginning of a form or set of fields that describes the necessary input"
  },
  {
    "id": "G158",
    "title": "Providing an alternative for time-based media for audio-only content"
  },
  {
    "id": "H79",
    "title": "Identifying the purpose of a link in a data table using the link text combined with its enclosing table cell and associated table header cells"
  },
  {
    "id": "G209",
    "title": "Provide sufficient contrast at the boundaries between adjoining colors"
  },
  {
    "id": "H37",
    "title": "Using alt attributes on img elements"
  },
  {
    "id": "F98",
    "title": "Failure due to interactions being limited to touch-only on touchscreen devices"
  },
  {
    "id": "H73",
    "title": "Using the summary attribute of the table element to give an overview of data tables"
  },
  {
    "id": "H49",
    "title": "Using semantic markup to mark emphasized or special text"
  },
  {
    "id": "SL8",
    "title": "Displaying HelpText in Silverlight User Interfaces"
  },
  {
    "id": "PDF13",
    "title": "Providing replacement text using the /Alt entry for links in PDF documents"
  },
  {
    "id": "G219",
    "title": "Ensuring that a single pointer operable alternative is available for dragging movements that operate on content"
  },
  {
    "id": "G124",
    "title": "Adding links at the top of the page to each area of the content"
  },
  {
    "id": "SL20",
    "title": "Relying on Silverlight AutomationPeer Behavior to Set AutomationProperties.Name"
  },
  {
    "id": "H57",
    "title": "Using the language attribute on the HTML element"
  },
  {
    "id": "G157",
    "title": "Incorporating a live audio captioning service into a Web page"
  },
  {
    "id": "F31",
    "title": "Failure of Success Criterion 3.2.4 due to using two different labels for the same function on different Web pages within a set of Web pages"
  },
  {
    "id": "F32",
    "title": "Failure of Success Criterion 1.3.2 due to using white space characters to control spacing within a word"
  },
  {
    "id": "F46",
    "title": "Failure of Success Criterion 1.3.1 due to using th elements  caption elements or non-empty summary attributes in layout tables"
  },
  {
    "id": "G168",
    "title": "Requesting confirmation to continue with selected action"
  },
  {
    "id": "G80",
    "title": "Providing a submit button to initiate a change of context"
  },
  {
    "id": "G204",
    "title": "Not interfering with the user agent's reflow of text as the viewing window is narrowed"
  },
  {
    "id": "SL9",
    "title": "Handling Key Events to Enable Keyboard Functionality in Silverlight"
  },
  {
    "id": "G9",
    "title": "Creating captions for live synchronized media"
  },
  {
    "id": "SL5",
    "title": "Defining a Focusable Image Class for Silverlight"
  },
  {
    "id": "G218",
    "title": "Email link authentication"
  },
  {
    "id": "G176",
    "title": "Keeping the flashing area small enough"
  },
  {
    "id": "G202",
    "title": "Ensuring keyboard control for all functionality"
  },
  {
    "id": "H78",
    "title": "Identifying the purpose of a link using link text combined with its enclosing paragraph"
  },
  {
    "id": "FLASH15",
    "title": "Using the tabIndex property to specify a logical reading order and a logical tab order in Flash"
  },
  {
    "id": "H28",
    "title": "Providing definitions for abbreviations by using the abbr element"
  },
  {
    "id": "G98",
    "title": "Providing the ability for the user to review and correct answers before submitting"
  },
  {
    "id": "FLASH31",
    "title": "Specifying caption text for a DataGrid"
  },
  {
    "id": "PDF3",
    "title": "Ensuring correct tab and reading order in PDF documents"
  },
  {
    "id": "PDF1",
    "title": "Applying text alternatives to images with the Alt entry in PDF documents"
  },
  {
    "id": "F49",
    "title": "Failure of Success Criterion 1.3.2 due to using an HTML layout table that does not make sense when linearized"
  },
  {
    "id": "F65",
    "title": "Failure of Success Criterion 1.1.1 due to omitting the alt attribute or text alternative on img elements area elements and input elements of type \"image\""
  },
  {
    "id": "F38",
    "title": "Failure of Success Criterion 1.1.1 due to not marking up decorative images in HTML in a way that allows assistive technology to ignore them"
  },
  {
    "id": "F93",
    "title": "Failure of Success Criterion 1.4.2 for absence of a way to pause or stop an HTML5 media element that autoplays"
  },
  {
    "id": "SL30",
    "title": "Using Silverlight Control Compositing and AutomationProperties.Name"
  },
  {
    "id": "PDF20",
    "title": "Using Adobe Acrobat Pro's Table Editor to repair mistagged tables"
  },
  {
    "id": "SL2",
    "title": "Changing The Visual Focus Indicator in Silverlight"
  },
  {
    "id": "F79",
    "title": "Failure of Success Criterion 4.1.2 due to the focus state of a user interface component not being programmatically determinable or no notification of change of focus state available"
  },
  {
    "id": "F75",
    "title": "Failure of Success Criterion 1.2.2 by providing synchronized media without captions when the synchronized media presents more information than is presented on the page"
  },
  {
    "id": "F66",
    "title": "Failure of Success Criterion 3.2.3 due to presenting navigation links in a different relative order on different pages"
  },
  {
    "id": "G110",
    "title": "Using an instant client-side redirect"
  },
  {
    "id": "G91",
    "title": "Providing link text that describes the purpose of a link"
  },
  {
    "id": "G4",
    "title": "Allowing the content to be paused and restarted from where it was paused"
  },
  {
    "id": "ARIA15",
    "title": "Using aria-describedby to provide descriptions of images"
  },
  {
    "id": "F34",
    "title": "Failure of Success Criterion 1.3.1 and 1.3.2 due to using white space characters to format tables in plain text content"
  },
  {
    "id": "F85",
    "title": "Failure of Success Criterion 2.4.3 due to using dialogs or menus that are not adjacent to their trigger control in the sequential navigation order"
  },
  {
    "id": "G93",
    "title": "Providing open (always visible) captions"
  },
  {
    "id": "C24",
    "title": "Using percentage values in CSS for container sizes"
  },
  {
    "id": "H53",
    "title": "Using the body of the object element"
  },
  {
    "id": "ARIA7",
    "title": "Using aria-labelledby for link purpose"
  },
  {
    "id": "H74",
    "title": "Ensuring that opening and closing tags are used according to specification"
  },
  {
    "id": "G186",
    "title": "Using a control in the Web page that stops moving blinking or auto-updating content"
  },
  {
    "id": "G149",
    "title": "Using user interface components that are highlighted by the user agent when they receive focus"
  },
  {
    "id": "SM2",
    "title": "Adding extended audio description in SMIL 2.0"
  },
  {
    "id": "SL26",
    "title": "Using LabeledBy to Associate Labels and Targets in Silverlight"
  },
  {
    "id": "G177",
    "title": "Providing suggested correction text"
  },
  {
    "id": "G217",
    "title": "Providing a mechanism to allow users to remap or turn off character key shortcuts"
  },
  {
    "id": "G172",
    "title": "Providing a mechanism to remove full justification of text"
  },
  {
    "id": "G152",
    "title": "Setting animated gif images to stop blinking after n cycles (within 5 seconds)"
  },
  {
    "id": "G192",
    "title": "Fully conforming to specifications"
  },
  {
    "id": "G205",
    "title": "Including a text cue for colored form control labels"
  },
  {
    "id": "H64",
    "title": "Using the title attribute of the frame and iframe elements"
  },
  {
    "id": "G206",
    "title": "Providing options within the content to switch to a layout that does not require the user to scroll horizontally to read a line of text"
  },
  {
    "id": "G139",
    "title": "Creating a mechanism that allows users to jump to errors"
  },
  {
    "id": "H93",
    "title": "Ensuring that id attributes are unique on a Web page"
  },
  {
    "id": "G198",
    "title": "Providing a way for the user to turn the time limit off"
  },
  {
    "id": "H83",
    "title": "Using the target attribute to open a new window on user request and indicating this in link text"
  },
  {
    "id": "G148",
    "title": "Not specifying background color not specifying text color and not using technology features that change those defaults"
  },
  {
    "id": "G94",
    "title": "Providing short text alternative for non-text content that serves the same purpose and presents the same information as the non-text content"
  },
  {
    "id": "H80",
    "title": "Identifying the purpose of a link using link text combined with the preceding heading element"
  },
  {
    "id": "G162",
    "title": "Positioning labels to maximize predictability of relationships"
  },
  {
    "id": "PDF4",
    "title": "Hiding decorative images with the Artifact tag in PDF documents"
  },
  {
    "id": "H43",
    "title": "Using id and headers attributes to associate data cells with header cells in data tables"
  },
  {
    "id": "G199",
    "title": "Providing success feedback when data is submitted successfully"
  },
  {
    "id": "H63",
    "title": "Using the scope attribute to associate header cells and data cells in data tables"
  },
  {
    "id": "H89",
    "title": "Using the title attribute to provide context-sensitive help"
  },
  {
    "id": "H84",
    "title": "Using a button with a select element to perform an action"
  },
  {
    "id": "H46",
    "title": "Using noembed with embed"
  },
  {
    "id": "SL31",
    "title": "Using Silverlight Font Properties to Control Text Presentation"
  },
  {
    "id": "F40",
    "title": "Failure due to using meta redirect with a time limit"
  },
  {
    "id": "FLASH2",
    "title": "Setting the description property for a non-text object in Flash"
  },
  {
    "id": "F47",
    "title": "Failure of Success Criterion 2.2.2 due to using the blink element"
  },
  {
    "id": "G183",
    "title": "Using a contrast ratio of 3:1 with surrounding text and providing additional visual cues on focus for links or controls where color alone is used to identify them"
  },
  {
    "id": "SL23",
    "title": "Using A Style Switcher to Increase Font Size of Silverlight Text Elements"
  },
  {
    "id": "F68",
    "title": "Failure of Success Criterion 4.1.2 due to a user interface control not having a programmatically determined name"
  },
  {
    "id": "SCR38",
    "title": "Creating a conforming alternate version for a web page designed with progressive enhancement"
  },
  {
    "id": "SL34",
    "title": "Using the Silverlight Default Tab Sequence and Altering Tab Sequences With Properties"
  },
  {
    "id": "SL28",
    "title": "Using Separate Text-Format Text Captions for MediaElement Content"
  },
  {
    "id": "F37",
    "title": "Failure of Success Criterion 3.2.2 due to launching a new window without prior warning when the selection of a radio button check box or select list is changed"
  },
  {
    "id": "G211",
    "title": "Matching the accessible name to the visible label"
  },
  {
    "id": "PDF6",
    "title": "Using table elements for table markup in PDF Documents"
  },
  {
    "id": "F39",
    "title": "Failure of Success Criterion 1.1.1 due to providing a text alternative that is not null (e.g. alt=\"spacer\" or alt=\"image\") for images that should be ignored by assistive technology"
  },
  {
    "id": "PDF19",
    "title": "Specifying the language for a passage or phrase with the Lang entry in PDF documents"
  },
  {
    "id": "F58",
    "title": "Failure of Success Criterion 2.2.1 due to using server-side techniques to automatically redirect pages after a time-out"
  },
  {
    "id": "F43",
    "title": "Failure of Success Criterion 1.3.1 due to using structural markup in a way that does not represent relationships in the content"
  },
  {
    "id": "F61",
    "title": "Failure of Success Criterion 3.2.5 due to complete change of main content through an automatic update that the user cannot disable from within the content"
  },
  {
    "id": "F67",
    "title": "Failure of Success Criterion 1.1.1 and 1.2.1 due to providing long descriptions for non-text content that does not serve the same purpose or does not present the same information"
  },
  {
    "id": "F69",
    "title": "Failure of Success Criterion 1.4.4 when resizing visually rendered text up to 200 percent causes the text image or controls to be clipped truncated or obscured"
  },
  {
    "id": "F23",
    "title": "Failure of 1.4.2 due to playing a sound longer than 3 seconds where there is no mechanism to turn it off"
  },
  {
    "id": "H91",
    "title": "Using HTML form controls and links"
  },
  {
    "id": "F20",
    "title": "Failure of Success Criterion 1.1.1 and 4.1.2 due to not updating text alternatives when changes to non-text content occur"
  },
  {
    "id": "G78",
    "title": "Providing a second user-selectable audio track that includes audio descriptions"
  },
  {
    "id": "SL27",
    "title": "Using Language/Culture Properties as Exposed by Silverlight Applications and Assistive Technologies"
  },
  {
    "id": "PDF9",
    "title": "Providing headings by marking content with heading tags in PDF documents"
  },
  {
    "id": "F42",
    "title": "Failure of Success Criteria 1.3.1 2.1.1 2.1.3 or 4.1.2 when emulating links"
  },
  {
    "id": "C22",
    "title": "Using CSS to control visual presentation of text"
  },
  {
    "id": "G102",
    "title": "Providing the expansion or explanation of an abbreviation"
  },
  {
    "id": "ARIA20",
    "title": "Using the region role to identify a region of the page"
  },
  {
    "id": "H25",
    "title": "Providing a title using the title element"
  },
  {
    "id": "H59",
    "title": "Using the link element and navigation tools"
  },
  {
    "id": "H4",
    "title": "Creating a logical tab order through links form controls and objects"
  },
  {
    "id": "F55",
    "title": "Failure of Success Criteria 2.1.1 2.4.7 and 3.2.1 due to using script to remove focus when focus is received"
  },
  {
    "id": "F60",
    "title": "Failure of Success Criterion 3.2.5 due to launching a new window when a user enters text into an input field"
  },
  {
    "id": "SCR34",
    "title": "Calculating size and position in a way that scales with text size"
  },
  {
    "id": "C39",
    "title": "Using the CSS reduce-motion query to prevent motion"
  },
  {
    "id": "SL18",
    "title": "Providing Text Equivalent for Nontext Silverlight Controls With AutomationProperties.Name"
  },
  {
    "id": "F16",
    "title": "Failure of Success Criterion 2.2.2 due to including scrolling content where movement is not essential to the activity without also including a mechanism to pause and restart the content"
  },
  {
    "id": "FLASH17",
    "title": "Providing keyboard access to a Flash object and avoiding a keyboard trap"
  },
  {
    "id": "ARIA11",
    "title": "Using ARIA landmarks to identify regions of a page"
  },
  {
    "id": "FLASH5",
    "title": "Combining adjacent image and text buttons for the same resource"
  },
  {
    "id": "H70",
    "title": "Using frame elements to group blocks of repeated material"
  },
  {
    "id": "G215",
    "title": "Providing controls to achieve the same result as path based or multipoint gestures"
  },
  {
    "id": "PDF23",
    "title": "Providing interactive form controls in PDF documents"
  },
  {
    "id": "PDF22",
    "title": "Indicating when user input falls outside the required format or values in PDF forms"
  },
  {
    "id": "PDF8",
    "title": "Providing definitions for abbreviations via an E entry for a structure element"
  },
  {
    "id": "SL6",
    "title": "Defining a UI Automation Peer for a Custom Silverlight Control"
  },
  {
    "id": "PDF17",
    "title": "Specifying consistent page numbering for PDF documents"
  },
  {
    "id": "PDF2",
    "title": "Creating bookmarks in PDF documents"
  },
  {
    "id": "G145",
    "title": "Ensuring that a contrast ratio of at least 3:1 exists between text (and images of text)  and background behind the text"
  },
  {
    "id": "PDF21",
    "title": "Using List tags for lists in PDF documents"
  },
  {
    "id": "PDF14",
    "title": "Providing running headers and footers in PDF documents"
  },
  {
    "id": "FLASH10",
    "title": "Indicating required form controls in Flash"
  },
  {
    "id": "F108",
    "title": "Failure of Success Criterion 2.5.X Dragging due to not providing a single pointer method for the user to operate a function that does not require a dragging movement"
  },
  {
    "id": "F102",
    "title": "Failure of Success Criterion 1.4.10 due to content disappearing and not being available when content has reflowed"
  },
  {
    "id": "F107",
    "title": "Failure of Success Criterion 1.3.5 due to incorrect autocomplete attribute values"
  },
  {
    "id": "F8",
    "title": "Failure of Success Criterion 1.2.2 due to captions omitting some dialogue or important sound effects"
  },
  {
    "id": "G195",
    "title": "Using an author-supplied highly visible focus indicator"
  },
  {
    "id": "F84",
    "title": "Failure of Success Criterion 2.4.9 due to using a non-specific link such as \"click here\" or \"more\" without a mechanism to change the link text to specific text."
  },
  {
    "id": "F9",
    "title": "Failure of Success Criterion 3.2.5 due to changing the context when the user removes focus from a form element"
  },
  {
    "id": "F88",
    "title": "Failure of Success Criterion 1.4.8 due to using text that is justified (aligned to both the left and the right margins)"
  },
  {
    "id": "H77",
    "title": "Identifying the purpose of a link using link text combined with its enclosing list item"
  },
  {
    "id": "F30",
    "title": "Failure of Success Criterion 1.1.1 and 1.2.1 due to using text alternatives that are not alternatives (e.g. filenames or placeholder text)"
  },
  {
    "id": "F25",
    "title": "Failure of Success Criterion 2.4.2 due to the title of a Web page not identifying the contents"
  },
  {
    "id": "SL7",
    "title": "Designing a Focused Visual State for Custom Silverlight Controls"
  },
  {
    "id": "SL13",
    "title": "Providing A Style Switcher To Switch To High Contrast"
  },
  {
    "id": "PDF11",
    "title": "Providing links and link text using the Link annotation and the /Link structure element in PDF documents"
  },
  {
    "id": "H71",
    "title": "Providing a description for groups of form controls using fieldset and legend elements"
  },
  {
    "id": "F90",
    "title": "Failure of Success Criterion 1.3.1 for incorrectly associating table headers and content via the headers and id attributes"
  },
  {
    "id": "F77",
    "title": "Failure of Success Criterion 4.1.1 due to duplicate values of type ID"
  },
  {
    "id": "F89",
    "title": "Failure of Success Criteria 2.4.4 2.4.9 and 4.1.2 due to not providing an accessible name for an image which is the only content in a link"
  },
  {
    "id": "F96",
    "title": "Failure due to the accessible name not containing the visible label text"
  },
  {
    "id": "F94",
    "title": "Failure of Success Criterion 1.4.4 due to incorrect use of viewport units to resize text"
  },
  {
    "id": "FLASH9",
    "title": "Applying captions to prerecorded synchronized media"
  },
  {
    "id": "H69",
    "title": "Providing heading elements at the beginning of each section of content"
  },
  {
    "id": "F81",
    "title": "Failure of Success Criterion 1.4.1 due to identifying required or error fields using color differences only"
  },
  {
    "id": "F91",
    "title": "Failure of Success Criterion 1.3.1 for not correctly marking up table headers"
  },
  {
    "id": "H94",
    "title": "Ensuring that elements do not contain duplicate attributes"
  },
  {
    "id": "G171",
    "title": "Playing sounds only on user request"
  },
  {
    "id": "F106",
    "title": "Failure due to inability to deactivate motion actuation"
  },
  {
    "id": "G166",
    "title": "Providing audio that describes the important video content and describing it as such"
  },
  {
    "id": "H96",
    "title": "Using the track element to provide audio descriptions"
  },
  {
    "id": "G214",
    "title": "Using a control to allow access to content in different orientations which is otherwise restricted"
  },
  {
    "id": "G1",
    "title": "Adding a link at the top of each page that goes directly to the main content area"
  },
  {
    "id": "FLASH35",
    "title": "Using script to scroll Flash content and providing a mechanism to pause it"
  },
  {
    "id": "H75",
    "title": "Ensuring that Web pages are well-formed"
  },
  {
    "id": "SVR1",
    "title": "Implementing automatic redirects on the server side instead of on the client side"
  },
  {
    "id": "H56",
    "title": "Using the dir attribute on an inline element to resolve problems with nested directional runs"
  },
  {
    "id": "G83",
    "title": "Providing text descriptions to identify required fields that were not completed"
  },
  {
    "id": "G111",
    "title": "Using color and pattern"
  },
  {
    "id": "F97",
    "title": "Failure due to locking the orientation to landscape or portrait view"
  },
  {
    "id": "FLASH19",
    "title": "Providing a script that warns the user a time limit is about to expire and provides a way to extend it"
  },
  {
    "id": "G150",
    "title": "Providing text based alternatives for live audio-only content"
  },
  {
    "id": "FLASH14",
    "title": "Using redundant keyboard and mouse event handlers in Flash"
  },
  {
    "id": "FLASH30",
    "title": "Specifying accessible names for image buttons"
  },
  {
    "id": "G193",
    "title": "Providing help by an assistant in the Web page"
  },
  {
    "id": "G84",
    "title": "Providing a text description when the user provides information that is not in the list of allowed values"
  },
  {
    "id": "FLASH28",
    "title": "Providing text alternatives for ASCII art emoticons and leetspeak in Flash"
  },
  {
    "id": "FLASH11",
    "title": "Providing a longer text description of an object"
  },
  {
    "id": "G57",
    "title": "Ordering the content in a meaningful sequence"
  },
  {
    "id": "G125",
    "title": "Providing links to navigate to related Web pages"
  },
  {
    "id": "G96",
    "title": "Providing textual identification of items that otherwise rely only on sensory information to be understood"
  },
  {
    "id": "G216",
    "title": "Providing single point activation for a control slider"
  },
  {
    "id": "G207",
    "title": "Ensuring that a contrast ratio of 3:1 is provided for icons"
  },
  {
    "id": "SM13",
    "title": "Providing sign language interpretation through synchronized video streams in SMIL 1.0"
  },
  {
    "id": "SVR2",
    "title": "Using .htaccess to ensure that the only way to access non-conforming content is from conforming content"
  },
  {
    "id": "G61",
    "title": "Presenting repeated components in the same relative order each time they appear"
  },
  {
    "id": "G130",
    "title": "Providing descriptive headings"
  },
  {
    "id": "G131",
    "title": "Providing descriptive labels"
  },
  {
    "id": "G135",
    "title": "Using the accessibility API features of a technology to expose names and roles to allow user-settable properties to be directly set and to provide notification of changes"
  },
  {
    "id": "G99",
    "title": "Providing the ability to recover deleted information"
  },
  {
    "id": "G81",
    "title": "Providing a synchronized video of the sign language interpreter that can be displayed in a different viewport or overlaid on the image by the player"
  },
  {
    "id": "G141",
    "title": "Organizing a page using headings"
  },
  {
    "id": "G76",
    "title": "Providing a mechanism to request an update of the content instead of updating automatically"
  },
  {
    "id": "G82",
    "title": "Providing a text alternative that identifies the purpose of the non-text content"
  },
  {
    "id": "H58",
    "title": "Using language attributes to identify changes in the human language"
  },
  {
    "id": "SL25",
    "title": "Using Controls and Programmatic Focus to Bypass Blocks of Content in Silverlight"
  },
  {
    "id": "G169",
    "title": "Aligning text on only one side"
  },
  {
    "id": "G151",
    "title": "Providing a link to a text transcript of a prepared statement or script if the script is followed"
  },
  {
    "id": "G203",
    "title": "Using a static text alternative to describe a talking head video"
  },
  {
    "id": "G156",
    "title": "Using a technology that has commonly-available user agents that can change the foreground and background of blocks of text"
  },
  {
    "id": "SM7",
    "title": "Providing audio description in SMIL 2.0"
  },
  {
    "id": "T1",
    "title": "Using standard text formatting conventions for paragraphs"
  },
  {
    "id": "SM14",
    "title": "Providing sign language interpretation through synchronized video streams in SMIL 2.0"
  },
  {
    "id": "G191",
    "title": "Providing a link button or other mechanism that reloads the page without any blinking content"
  },
  {
    "id": "G161",
    "title": "Providing a search function to help users find content"
  },
  {
    "id": "H40",
    "title": "Using description lists"
  },
  {
    "id": "G212",
    "title": "Using native controls to ensure functionality is triggered on the up-event."
  },
  {
    "id": "G144",
    "title": "Ensuring that the Web Page contains another CAPTCHA serving the same purpose using a different modality"
  },
  {
    "id": "FLASH1",
    "title": "Setting the name property for a non-text object"
  },
  {
    "id": "G210",
    "title": "Ensuring that drag-and-drop actions can be cancelled"
  },
  {
    "id": "SM11",
    "title": "Providing captions through synchronized text streams in SMIL 1.0"
  },
  {
    "id": "H81",
    "title": "Identifying the purpose of a link in a nested list using link text combined with the parent list item under which the list is nested"
  },
  {
    "id": "H86",
    "title": "Providing text alternatives for ASCII art emoticons and leetspeak"
  },
  {
    "id": "H65",
    "title": "Using the title attribute to identify form controls when the label element cannot be used"
  },
  {
    "id": "SL11",
    "title": "Pausing or Stopping A Decorative Silverlight Animation"
  },
  {
    "id": "PDF5",
    "title": "Indicating required form controls in PDF forms"
  },
  {
    "id": "G123",
    "title": "Adding a link at the beginning of a block of repeated content to go to the end of the block"
  },
  {
    "id": "PDF7",
    "title": "Performing OCR on a scanned PDF document to provide actual text"
  },
  {
    "id": "H90",
    "title": "Indicating required form controls using label or legend"
  },
  {
    "id": "SM1",
    "title": "Adding extended audio description in SMIL 1.0"
  },
  {
    "id": "SL21",
    "title": "Replacing A Silverlight Timed Animation With a Nonanimated Element"
  },
  {
    "id": "G174",
    "title": "Providing a control with a sufficient contrast ratio that allows users to switch to a presentation that uses sufficient contrast"
  },
  {
    "id": "G160",
    "title": "Providing sign language versions of information ideas and processes that must be understood in order to use the content"
  },
  {
    "id": "SL14",
    "title": "Providing Custom Control Key Handling for Keyboard Functionality in Silverlight"
  },
  {
    "id": "PDF10",
    "title": "Providing labels for interactive form controls in PDF documents"
  },
  {
    "id": "SM6",
    "title": "Providing audio description in SMIL 1.0"
  },
  {
    "id": "SL1",
    "title": "Accessing Alternate Audio Tracks in Silverlight Media"
  },
  {
    "id": "PDF12",
    "title": "Providing name role value information for form fields in PDF documents"
  },
  {
    "id": "SL35",
    "title": "Using the Validation and ValidationSummary APIs to Implement Client Side Forms Validation in Silverlight"
  },
  {
    "id": "SL29",
    "title": "Using Silverlight \"List\" Controls to Define Blocks that can be Bypassed"
  },
  {
    "id": "T2",
    "title": "Using standard text formatting conventions for lists"
  },
  {
    "id": "SL10",
    "title": "Implementing a Submit-Form Pattern in Silverlight"
  },
  {
    "id": "SL24",
    "title": "Using AutoPlay to Keep Silverlight Media from Playing Automatically"
  },
  {
    "id": "SL32",
    "title": "Using Silverlight Text Elements for Appropriate Accessibility Role"
  },
  {
    "id": "T3",
    "title": "Using standard text formatting conventions for headings"
  }
] }
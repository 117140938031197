import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

const Error = ({ location }) => {
  console.log(location)

  return (
    <Layout>
      <SEO title="Error" />

      <section className="page">
        <div className="inner">
          <h2>
            There was an error trying to scan that page{" "}
            <span role="img" aria-label="crying">
              😢
            </span>
            .
          </h2>
          <p>
            If you'd like, <Link to="/">try another URL</Link>.
          </p>
          <p className="mono xsmall">
            ID: {location.state?.scanId} / URLs:{" "}
            {location.state?.urls?.join(", ")}
          </p>
          <p className="mono xsmall">
            <pre>
              {JSON.stringify(
                location.state?.messages?.filter(m => m.status === 3),
                null,
                2
              )}
            </pre>
          </p>
        </div>
      </section>
    </Layout>
  )
}

export default Error